import {TranslationService,} from '../api/core';
import {ModalData} from '../models/modal.model';
import {EModalType} from '../util/enum';
import {ContentPreviewComponent, ContentPreviewParams,} from '../shared/content-preview/content-preview.component';
import {ModalService} from './modal.service';
import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ContentAction} from "../campaign/views/campaign-overview/campaign-actions-list-utils";

export interface SalutationTranslations {
  [key: string]: string;
}

@Injectable({ providedIn: 'root' })
export class CustomPreviewService {
  private salutationTranslations: SalutationTranslations = {};

  constructor(
    private readonly translationService: TranslationService,
    private readonly modalService: ModalService,
    private readonly toastr: ToastrService
  ) {
    translationService
      .getTranslations('salutation')
      .subscribe(
        (translations) => (this.salutationTranslations = translations)
      );
  }

  previewCustomContent(
    data: ContentAction,
    isIntermediary: boolean,
    hideChannelType: boolean = true
  ) {
    const contentPreviewParams = {
      campaignId: data.campaignId,
      actionId: data.id,
      channelType: data.channel?.type?.ident,
      isIntermediary,
      hideChannelType,
    } as ContentPreviewParams;
    const modalData: ModalData = {
      type: EModalType.previewContent,
      title: 'contentView',
      data: { contentPreviewParams },
      submitBtn: null,
      component: ContentPreviewComponent,
    };
    this.modalService.openDefaultDialog(modalData, 'custom-content-dialog');
  }
}
