<div class="grid-container">
  <div class="grid-actions">
    <app-config-icon
      *ngIf="
      availableLanguages?.length &&
      availableLanguages.length > 0 &&
      allowAddOrEditContent
    "
      iconIdentifier="add"
      [tooltip]="'addContent' | translate"
      [matMenuTriggerFor]="addContentMenu"
    >
    </app-config-icon>
    <mat-menu #addContentMenu="matMenu" xPosition="before">
      <button
        mat-menu-item
        *ngFor="let language of availableLanguages"
        (click)="addContent(language)"
      >
        {{ language.name }}
      </button>
    </mat-menu>
    <app-config-icon
      iconIdentifier="restore"
      [tooltip]="'restoreContentFromStory' | translate"
      (click)="restoreCampaignContent()"
      *ngIf="campaign?.story && allowAddOrEditContent"
    ></app-config-icon>
    <mat-slide-toggle
      color="primary"
      [disabled]="!autoSyncEnabled"
      [checked]="autoSyncContent"
      (change)="handleToggleAutoSyncContent($event)"
      [matTooltip]="autoSyncContentMsg"
      *ngIf="autoSyncFeature"
    >
    </mat-slide-toggle>
  </div>
  <app-grid
    *ngIf="contents"
    tableId="content-card"
    [columnDefs]="contentColumns"
    [gridOptions]="contentGridOptions"
    [rowData]="contents"
    [pagination]="false"
  >
  </app-grid>
</div>
