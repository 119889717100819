import {PermissionService} from "../services/permission.service";
import {TranslateService} from "@ngx-translate/core";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class LabelBuilder {
  constructor(
    protected translateService: TranslateService,
    protected permissionService: PermissionService,
  ) {
  }

  selectedCurrencyLabel() {
    return this.permissionService.userRoleData.selectedCurrency.code;
  }

  selectedExchange() {
    return this.permissionService.userRoleData.selectedExchange;
  }

  labelWithCurrency(label: string) {
    return `${this.translateService.instant(label)} (${this.selectedCurrencyLabel()})`;
  }
}
