import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DistributorTotalReceived, EmployeeService,} from 'src/app/api/core';
import {GlobalService} from 'src/app/services/global.service';
import {GridDataProvider} from 'src/app/shared/grid/data-source';
import {ColDef, GridOptions} from 'ag-grid-enterprise';
import {TranslateService} from '@ngx-translate/core';
import {GridReadyEvent, RowDoubleClickedEvent,} from 'ag-grid-community';
import {EViewRoutes} from 'src/app/util/enum';
import {genNumberColumn, genTextColumn,} from 'src/app/util/grid/grid-renderer.util';
import {Observable, Subscription} from 'rxjs';
import {I18n} from '../../../services/i18n.service';
import {genLinkColumn} from "../../../shared/grid/cell-renderers/link.renderer";
import {PermissionService} from "../../../services/permission.service";
import {GridComponent} from "../../../shared/grid/grid.component";

@Component({
  selector: 'app-distributor-employees',
  templateUrl: './distributor-employees.component.html',
})
export class DistributorEmployeesComponent implements OnInit, OnDestroy {
  @ViewChild("employee_grid")
  grid: GridComponent;

  @Input() refreshEvent: Observable<void>;
  /**
   * Data Provider for grid
   */
  data: GridDataProvider;

  columnDefs: ColDef[] = [
    genLinkColumn({
      field: 'name',
      headerName: I18n.getColName('name'),
      link: (data: any) => `${EViewRoutes.distributorEmployeeOverview}${data.id}`,
    }),
    genTextColumn('externalKey', I18n.getColName('key')),
    genNumberColumn(
      'received',
      I18n.getColName('received'),
      this.globalService,
      (d) => this.globalService.getFormattedValue(d.value, 0)
    ),
  ];

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.navigateTo(`${EViewRoutes.distributorEmployeeOverview}${event.data.id}`),
    onGridReady: (event: GridReadyEvent) => {
      this.gridReady(event);
    },
  };

  subscriptions: Subscription[] = [];
  summary: DistributorTotalReceived;

  constructor(
    protected employeeService: EmployeeService,
    protected globalService: GlobalService,
    protected translateService: TranslateService,
    protected permissionService: PermissionService,
  ) {
    this.data = employeeService.getDistributorEmployees.bind(this.employeeService);
    this.subscriptions.push(this.permissionService.user$.subscribe(() => {
      this.updateSummary();
    }));
  }

  ngOnInit(): void {
    if (this.refreshEvent) {
      this.subscriptions.push(this.refreshEvent.subscribe(this.updateAll.bind(this)));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private updateAll() {
    this.grid?.refresh();
  }

  updateSummary() {
    this.employeeService.getDistributorTotalReceived()
      .subscribe((data) => {
        this.summary = data;
        this.grid?.reload();
      });
  }

  navigateTo(route: string): void {
    this.globalService.navigate(route);
  }

  private gridReady(event: GridReadyEvent): void {
    this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
  }

  protected readonly EViewRoutes = EViewRoutes;
}
