import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {GridApi, GridOptions, GridReadyEvent, ModelUpdatedEvent, RowSelectedEvent,} from 'ag-grid-community';
import {ColDef} from 'ag-grid-enterprise';
import {finalize, first} from 'rxjs/operators';
import {Campaign, CampaignService, Product, ProductService, Story, StoryService,} from 'src/app/api/core';
import {ModalSubComponent} from 'src/app/models/modal.model';
import {CodeTableService} from 'src/app/services/code-table.service';
import {DataService} from 'src/app/services/data.service';
import {ModalComponent} from 'src/app/shared/modal/modal.component';
import {ECodeTables, EModalType, StoryProductType} from 'src/app/util/enum';
import {genCodeTableColumn, genTextColumn, genTranslatedTextColumn,} from 'src/app/util/grid/grid-renderer.util';
import {GridDataProvider} from '../grid/data-source';

/**
 * Component to add buy products to the Story. Represents the Products Step in the creation / update process.
 */
@Component({
  selector: 'app-edit-products',
  templateUrl: './edit-products.component.html',
})
export class EditProductsComponent implements OnInit, ModalSubComponent {
  /**
   * List of all assets in the selected assets grid table
   */
  selectedProducts: Product[];
  /**
   * Column definition for the "all product" grid
   */
  columnDefs: ColDef[] = [
    {
      checkboxSelection: true,
      floatingFilter: false,
      sortable: false,
      lockPosition: 'left',
      suppressMenu: true,
      lockVisible: true,
      suppressColumnsToolPanel: true,
    },
    genTextColumn('isin', this.translateService.instant('isin')),
    genTextColumn('name', this.translateService.instant('productName')),
    genTranslatedTextColumn(
      'country',
      this.translateService.instant('country')
    ),
    genTextColumn(
      'currency.code',
      this.translateService.instant('referenceCurrency')
    ),
    genCodeTableColumn({
      field: 'assetClass',
      headerName: this.translateService.instant('assetClass'),
      observable: this.codeTableService.getCodeTable(ECodeTables.assetClass),
    }),
    genCodeTableColumn({
      field: 'type',
      headerName: this.translateService.instant('assetType'),
      observable: this.codeTableService.getCodeTable(ECodeTables.assetType),
    }),
    genCodeTableColumn({
      field: 'rating',
      headerName: this.translateService.instant('rating'),
      observable: this.codeTableService.getCodeTable(ECodeTables.rating),
    }),
  ];
  /**
   * Grid options
   */
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    suppressRowClickSelection: true,
    rowMultiSelectWithClick: true,
    paginationAutoPageSize: true,
    rowSelection: 'multiple',
    getRowId: (params) => params.data.id,
    onGridReady: (event: GridReadyEvent) => this.gridReady(event),
    onRowSelected: (event: RowSelectedEvent) => this.rowSelected(event),
    onModelUpdated: (event: ModelUpdatedEvent) => this.modelUpdated(event),
  };
  /**
   * Grid API
   */
  gridApi: GridApi;
  /**
   * Data provider for grid "Available products"
   */
  gridData: GridDataProvider = this.productService.getProductsForSelection.bind(
    this.productService
  );
  init = false;

  /**
   * Story or Campaign
   */
  private entity: Story | Campaign;
  /**
   * Product Type - Either BUY or SELL
   */
  private readonly type: StoryProductType;

  constructor(
    protected readonly productService: ProductService,
    protected readonly storyService: StoryService,
    protected readonly campaignService: CampaignService,
    protected readonly translateService: TranslateService,
    protected readonly codeTableService: CodeTableService,
    protected readonly dataService: DataService,
    protected dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: { entity: Story | Campaign; type: StoryProductType } }
  ) {
    this.entity = data.data.entity;
    this.type = data.data.type;
    switch (this.type) {
      case StoryProductType.BUY:
        this.selectedProducts = [...this.entity.buyProducts];
        break;
      case StoryProductType.SELL:
        this.selectedProducts = [...this.entity.sellProducts];
        break;
      default:
        console.error('Unknown type given: ' + this.type);
    }
  }

  ngOnInit(): void {}

  /**
   * Triggered by parent component story-modal.component
   */
  modalAction(modalType: EModalType): void {
    switch (modalType) {
      case EModalType.editStoryProducts:
        this.dataService.updateLoading(true);
        this.storyService
          .updateStoryProducts(this.entity.id, this.type, this.selectedProducts)
          .pipe(
            first(),
            finalize(() => {
              this.dataService.updateLoading(false);
              this.dialogRef.componentInstance.resetToolbarActionButtons();
            })
          )
          .subscribe({
            next: (storyData) => {
              this.dataService.updateStory(storyData);
              this.dialogRef.close();
            },
          });
        break;
      case EModalType.editCampaignProducts:
        this.dataService.updateLoading(true);
        this.campaignService
          .updateCampaignProducts(this.entity.id, {
            type: this.type,
            products: this.selectedProducts,
          })
          .pipe(
            first(),
            finalize(() => {
              this.dataService.updateLoading(false);
              this.dialogRef.componentInstance.resetToolbarActionButtons();
            })
          )
          .subscribe((campaignData) => {
            this.dataService.updateCampaign(campaignData);
            this.dialogRef.close();
          });
        break;
      default:
        break;
    }
  }

  /**
   * Check if row should be selected in grid.
   * @param productId ID of product that should get selected
   * @returns boolean
   */
  isSelectedProduct(productId: number): boolean {
    return (
      this.selectedProducts.findIndex((product) => product.id === productId) !==
      -1
    );
  }

  /**
   * Grid ready function. Gets triggered when grid is finished loading.
   * @param event GridReadyEvent
   */
  gridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
  }

  rowSelected(event: RowSelectedEvent): void {
    const selectedProductIndex = this.selectedProducts.findIndex(
      (asset) => asset.id === event.data.id
    );
    if (event.node.isSelected()) {
      if (selectedProductIndex === -1) {
        this.selectedProducts.push({ ...event.data });
      }
    } else {
      if (selectedProductIndex > -1) {
        this.selectedProducts.splice(selectedProductIndex, 1);
      }
    }
  }

  modelUpdated(event: ModelUpdatedEvent): void {
    event.api.forEachNode((node) => {
      if (node.data && node.data.id && this.isSelectedProduct(node.data.id)) {
        node.setSelected(true);
      }
    });
  }
}
