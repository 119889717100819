<div class="product-details__top">
  <div class="top-header">
    <span class="h3">
      {{ product.name }}</span
    >
    <div class="product-badge">
      <app-config-icon iconIdentifier="{{ getTypeIcon() }}"></app-config-icon>
    </div>
  </div>
  <span class="product-details__isin h4"
    >{{ 'isin' | translate }}: {{ product.isin }}</span
  >
  <div class="content-wrapper">
    <span
      *ngIf="product.customDescription"
      class="product-details__description"
    >
      {{ product.customDescription }}
    </span>
    <span class="product-details__description" title="{{product.description || ''}}">
      <ng-container *ngIf="product.description">{{
        product.description
      }}</ng-container>
      <ng-container *ngIf="!product.description">{{
        'noProductDescriptionAvailable' | translate
      }}</ng-container>
    </span>
    <div
      *ngIf="product.factsheet != null && product.factsheet.length > 0"
      class="pdf-download-container"
    >
      <span class="h4">{{ 'pdfDownloads' | translate }}</span>
      <a
        *ngFor="let factsheet of product.factsheets"
        class="pdf-download-container__link"
        href="{{ factsheet.url }}"
        target="_blank"
      >
        <app-config-icon iconIdentifier="picture_as_pdf"></app-config-icon>
        <span class="body">{{ factsheet.label }}</span>
      </a>
    </div>
  </div>
</div>
<div class="product-chart-container">
  <div *ngIf="pie.prodAssetClassPieData.length > 0">
    <span class="h4">{{ 'assetClass' | translate }}</span>
    <app-chart
      [chartType]="chartTypes.prodAssetClassPie"
      [data]="pie"
    ></app-chart>
  </div>
  <div *ngIf="pie.prodRegionPieData.length > 0">
    <span class="h4">{{ 'region' | translate }}</span>
    <app-chart [chartType]="chartTypes.prodRegionPie" [data]="pie"></app-chart>
  </div>
  <div *ngIf="pie.prodSectorPieData.length > 0">
    <span class="h4">{{ 'sector' | translate }}</span>
    <app-chart [chartType]="chartTypes.prodSectorPie" [data]="pie"></app-chart>
  </div>
</div>
<div class="product__bottom">
  <span class="product__country">
    <app-config-icon iconIdentifier="place"></app-config-icon>
    <i>{{ product.country.name | uppercase }}</i>
  </span>
  <div class="performance-container" *ngIf="product.performance">
    <span
      class="h5 performance-metric"
      [ngClass]="product.performance.ytd1 | percentageColorClass"
      >1ytd:
      {{ product.performance.ytd1 | formatPercentage : true : true }}</span
    >
    <span
      class="h5 performance-metric"
      [ngClass]="product.performance.ytd3 | percentageColorClass"
      >3ytd:
      {{ product.performance.ytd3 | formatPercentage : true : true }}</span
    >
    <span
      class="h5 performance-metric"
      [ngClass]="product.performance.ytd5 | percentageColorClass"
      >5ytd:
      {{ product.performance.ytd5 | formatPercentage : true : true }}</span
    >
    <span
      class="h5 performance-metric"
      [ngClass]="product.performance.all | percentageColorClass"
      >{{ 'all' | translate }}:
      {{ product.performance.all | formatPercentage : true : true }}</span
    >
  </div>
  <span class="product__currency h5">{{ product.currency.name }}</span>
</div>
<div class="category-tags">
  <span *ngFor="let tag of product.tags">{{ tag.name }}&nbsp;</span>
</div>
