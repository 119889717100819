<div class="filter-details-position-container">
  <span class="h3">{{ 'positionDetails' | translate }}</span>
  <ng-container *ngIf="basicsVisible">
    <span class="h4">{{ 'basics' | translate }}</span>
    <div class="filter-details-row">
      <app-filter
        *ngIf="positionFilters.value | isFilterPositionFilterActive"
        [metaData]="getFilterMetaData(fields.value)"
        [(value)]="positionForm.value"
        [readOnly]="readOnly"
      ></app-filter>
      <app-filter
        *ngIf="
          (positionFilters.assetType | isFilterPositionFilterActive) &&
          assetTypes.length
        "
        [metaData]="getFilterMetaData(fields.assetType)"
        [(value)]="positionForm.assetType"
        [readOnly]="readOnly"
      ></app-filter>
      <app-filter
        *ngIf="
          (positionFilters.assetClass | isFilterPositionFilterActive) &&
          assetClasses.length
        "
        [metaData]="getFilterMetaData(fields.assetClass)"
        [(value)]="positionForm.assetClass"
        [readOnly]="readOnly"
      ></app-filter>
      <app-filter
        *ngIf="
          (positionFilters.assetSubClass | isFilterPositionFilterActive) &&
          assetSubClasses.length
        "
        [metaData]="getFilterMetaData(fields.assetSubClass)"
        [(value)]="positionForm.assetSubClass"
        [readOnly]="readOnly"
      ></app-filter>
      <app-filter
        *ngIf="
          (positionFilters.ratingMoody | isFilterPositionFilterActive) &&
          moodyRatings.length
        "
        [metaData]="getFilterMetaData(fields.ratingMoody)"
        [(value)]="positionForm.ratingMoody"
        [readOnly]="readOnly"
      ></app-filter>
      <app-filter
        *ngIf="
          (positionFilters.ratingSP | isFilterPositionFilterActive) &&
          spRatings.length
        "
        [metaData]="getFilterMetaData(fields.ratingSP)"
        [(value)]="positionForm.ratingSP"
        [readOnly]="readOnly"
      ></app-filter>
      <app-filter
        *ngIf="
          (positionFilters.sustainabilityRating
            | isFilterPositionFilterActive) && sustainabilityRatings.length
        "
        [metaData]="getFilterMetaData(fields.sustainabilityRating)"
        [(value)]="positionForm.sustainabilityRating"
        [readOnly]="readOnly"
      ></app-filter>
      <app-filter
        *ngIf="
          (positionFilters.productRatingApac
            | isFilterPositionFilterActive) && productRatingsApac.length
        "
        [metaData]="getFilterMetaData(fields.productRatingApac)"
        [(value)]="positionForm.productRatingApac"
        [readOnly]="readOnly"
      ></app-filter>
      <app-filter
        *ngIf="
          (positionFilters.productRatingMe
            | isFilterPositionFilterActive) && productRatingsMe.length
        "
        [metaData]="getFilterMetaData(fields.productRatingMe)"
        [(value)]="positionForm.productRatingMe"
        [readOnly]="readOnly"
      ></app-filter>
      <app-filter
        *ngIf="
          (positionFilters.productInvestmentHorizon
            | isFilterPositionFilterActive) && productInvestmentHorizons.length
        "
        [metaData]="getFilterMetaData(fields.productInvestmentHorizon)"
        [(value)]="positionForm.productInvestmentHorizon"
        [readOnly]="readOnly"
      ></app-filter>
      <app-filter
        *ngIf="positionFilters.issuerName | isFilterPositionFilterActive"
        [metaData]="getFilterMetaData(fields.issuerName)"
        [(value)]="positionForm.issuerName"
        [readOnly]="readOnly"
      ></app-filter>
      <!-- -->
      <app-filter
        *ngIf="
          (positionFilters.ranking | isFilterPositionFilterActive) &&
          rankings.length
        "
        [metaData]="getFilterMetaData(fields.ranking)"
        [(value)]="positionForm.ranking"
        [readOnly]="readOnly"
      ></app-filter>
      <app-filter
        *ngIf="
          (positionFilters.ratingSourceLGT | isFilterPositionFilterActive) &&
          ratingSources.length
        "
        [metaData]="getFilterMetaData(fields.ratingSourceLGT)"
        [(value)]="positionForm.ratingSourceLGT"
        [readOnly]="readOnly"
      ></app-filter>
      <app-filter
        *ngIf="positionFilters.nextCallDate | isFilterPositionFilterActive"
        [metaData]="getFilterMetaData(fields.nextCallDate)"
        (hasErrors)="formHasErrors($event)"
        [(value)]="positionForm.nextCallDate"
        [readOnly]="readOnly"
      ></app-filter>
      <app-filter
        *ngIf="positionFilters.maturityDate | isFilterPositionFilterActive"
        [metaData]="getFilterMetaData(fields.maturityDate)"
        (hasErrors)="formHasErrors($event)"
        [(value)]="positionForm.maturityDate"
        [readOnly]="readOnly"
      ></app-filter>
      <app-filter
        *ngIf="
        (positionFilters.ids | isFilterPositionFilterActive) &&
        positionForm.ids?.length
        "
        class="filter-chips"
        [metaData]="getFilterMetaData(fields.ids)"
        [(value)]="positionForm.ids"
        [readOnly]="readOnly"
      ></app-filter>
      <app-filter
        *ngIf="positionFilters.excludeIds | isFilterPositionFilterActive"
        class="filter-chips"
        [metaData]="getFilterMetaData(fields.excludeIds)"
        [(value)]="positionForm.excludedIds"
        [readOnly]="readOnly"
      ></app-filter>
    </div>
  </ng-container>
  <ng-container *ngIf="keyFiguresVisible">
    <span class="h4">{{ 'keyFigures' | translate }}</span>
    <div class="filter-details-row">
      <app-filter
        *ngIf="positionFilters.bestPerformance | isFilterPositionFilterActive"
        [metaData]="getFilterMetaData(fields.bestPerformance)"
        [(value)]="positionForm.bestPerformance"
        [readOnly]="readOnly"
      ></app-filter>
      <app-filter
        *ngIf="positionFilters.worstPerformance | isFilterPositionFilterActive"
        [metaData]="getFilterMetaData(fields.worstPerformance)"
        [(value)]="positionForm.worstPerformance"
        [readOnly]="readOnly"
      ></app-filter>
    </div>
  </ng-container>
</div>
