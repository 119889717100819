<div *ngIf="portfolio" class="page-content-container">
  <app-sub-header
    [header]="portfolio.number"
    [breadcrumbs]="['objects', 'portfolioOverview']"
  >
  </app-sub-header>
  <app-tabs-panel
    [tabs]="tabs"
    [defaultTab]="defaultTab"
    (tabChanged)="onTabChanged($event)"
  ></app-tabs-panel>

  <div
    *ngIf="activeTab === tabs[0].text"
    class="portfolio-details-container aspark-container-padding"
  >
    <app-card class="details">
      <div card-header>
        <span>{{ 'details' | translate }}</span>
      </div>

      <div class="h4" card-body>
        <div class="detail-items-wrapper">
          <div class="start-col">
            <app-overview-detail-item
              labelRef="number"
              [value]="portfolio.number"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="portfolioType"
              [value]="portfolio.portfolioType?.name"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="advisoryType"
              [value]="portfolio.advisoryType?.name"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="strategy"
              [value]="portfolio.strategy?.name"
            ></app-overview-detail-item>
          </div>
          <div class="inside-col">
            <app-overview-detail-item
              labelRef="currency"
              [value]="portfolio.referenceCurrency.code"
            ></app-overview-detail-item>
            <app-overview-detail-item
              [label]="labelBuilder.labelWithCurrency('portfolioValue')"
              [value]="portfolio.portfolioValue | toDecimal : 2"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="risk"
              [value]="portfolio.risk | formatPercentage : true"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="riskSpreadMin"
              [value]="portfolio.riskSpreadMin | formatPercentage : true"
            ></app-overview-detail-item>
          </div>
          <div class="inside-col">
            <app-overview-detail-item
              labelRef="riskSpreadMax"
              [value]="portfolio.riskSpreadMax | formatPercentage : true"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="bpName"
              [value]="portfolio.bpName"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="bpNumber"
              [value]="portfolio.bpNumber"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="preferredLanguage"
              [value]="portfolio.preferredLanguage.name"
            ></app-overview-detail-item>
          </div>
          <div class="end-col">
            <app-overview-detail-item
              labelRef="relationshipManager"
              [value]="usernameLabel(portfolio.relationshipManager)"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="advisor"
              [value]="usernameLabel(portfolio.advisor)"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="opt-out"
              [onToggleLoading]="loadingOptOut$ | async"
              [editToggle]="true"
              [toogleDisabled]="
                !(protectedActions.editPortfolio | protectedAction)
              "
              (toggled)="onEditOptOut($event)"
              [value]="portfolio.optOut | toBoolean"
              [checked]="portfolio.optOut"
            ></app-overview-detail-item>
          </div>
        </div>
      </div>
    </app-card>
    <div class="positions">
      <app-grid
        [rowData]="dataPositions"
        [columnDefs]="portfolioPositionDefs"
        [autoGroupColumnDef]="autoGroupColumnDef"
        [gridOptions]="PositionGridOptions"
        [pagination]="false"
        tableId="tcp"
      ></app-grid>
    </div>
  </div>
  <div *ngIf="activeTab === tabs[1].text" class="portfolio-overview-container">
    <app-portfolio-overview-campaigns [portfolio]="portfolio">
    </app-portfolio-overview-campaigns>
  </div>
  <div *ngIf="activeTab === tabs[2].text && !portfolio.intermediary" class="portfolio-overview-container">
    <app-grid
      [rowData]="dataClients"
      [columnDefs]="portfolioClientsDefs"
      [gridOptions]="GridOptions"
      tableId="tcl"
    ></app-grid>
  </div>
  <div *ngIf="activeTab === tabs[2].text && portfolio.intermediary" class="portfolio-overview-container">
    <app-grid
      [rowData]="dataIntermediaries"
      [columnDefs]="portfolioIntermediariesDefs"
      [gridOptions]="GridOptions"
      tableId="tin"
    ></app-grid>
  </div>
</div>
