<div *ngIf="portfolio" class="page-content-container">
  <app-sub-header
    [header]="portfolio.number"
    [breadcrumbs]="['distributor', 'portfolioOverview']"
  >
  </app-sub-header>

  <div class="distributor-details-container aspark-container-padding">
    <app-card class="details">
      <div card-header>
        <span>{{ 'details' | translate }}</span>
      </div>

      <div class="h4" card-body>
        <div class="detail-items-wrapper">
          <app-overview-detail-item
            labelRef="number"
            [value]="portfolio.number"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="portfolioType"
            [value]="portfolio.portfolioType?.name"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="advisoryType"
            [value]="portfolio.advisoryType?.name"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="strategy"
            [value]="portfolio.strategy?.name"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="currency"
            [value]="portfolio.referenceCurrency.code"
          ></app-overview-detail-item>
          <app-overview-detail-item
            [label]="labelBuilder.labelWithCurrency('portfolioValue')"
            [value]="portfolio.portfolioValue | toDecimal : 2"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="risk"
            [value]="portfolio.risk | formatPercentage : true"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="riskSpreadMin"
            [value]="portfolio.riskSpreadMin | formatPercentage : true"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="riskSpreadMax"
            [value]="portfolio.riskSpreadMax | formatPercentage : true"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="bpName"
            [value]="portfolio.bpName"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="bpNumber"
            [value]="portfolio.bpNumber"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="opt-out"
            [onToggleLoading]="loadingOptOut$ | async"
            [editToggle]="true"
            [toogleDisabled]="
                !(protectedActions.editPortfolio | protectedAction)
              "
            (toggled)="onEditOptOut($event)"
            [value]="portfolio.optOut | toBoolean"
            [checked]="portfolio.optOut"
          ></app-overview-detail-item>
        </div>
      </div>
    </app-card>
    <app-card class="positions">
      <app-grid
        card-body
        [rowData]="dataPositions"
        [columnDefs]="portfolioPositionDefs"
        [autoGroupColumnDef]="autoGroupColumnDef"
        [gridOptions]="positionGridOptions"
        [pagination]="false"
        tableId="distributor-portfolio-positions"
      ></app-grid>
    </app-card>
    <app-card class="clients">
      <div card-header>
        <span>{{ 'clientsLinked' | translate }}</span>
      </div>
      <app-grid
        card-body
        [rowData]="rows"
        [columnDefs]="clientsColumnDefs"
        [gridOptions]="clientsGridOptions"
        tableId="distributor-portfolio-clients"
      ></app-grid>
    </app-card>
  </div>
</div>
