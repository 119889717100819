import { Component, Input, OnInit } from '@angular/core';
import { EChartTypes, EApiAssetType } from 'src/app/util/enum';
import { GlobalService } from 'src/app/services/global.service';
import { Product } from 'src/app/api/core';

/**
 * Product Detail Component.
 * Component to show detailed information of a specific product
 */
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
})
export class ProductDetailsComponent implements OnInit {
  /**
   * Component input to set product
   */
  @Input() product: Product;
  /**
   * Initiliazing pie chart object
   */
  pie = {
    prodAssetClassPieData: [],
    prodRegionPieData: [],
    prodSectorPieData: [],
  };

  constructor(protected globalService: GlobalService) {}

  ngOnInit(): void {
    this.pie.prodAssetClassPieData = this.product?.classAllocations;
    this.pie.prodRegionPieData = this.product?.regionWeights;
    this.pie.prodSectorPieData = this.product?.gicsWeights;
  }

  /**
   * Return true if input value is 0 or greater
   */
  isPositive(n: number): boolean {
    return n >= 0;
  }

  /**
   * Return all chart types found in enum.ts
   */
  get chartTypes() {
    return EChartTypes;
  }

  /**
   * Return Icon string for HTML depening on the type of the product.
   */
  getTypeIcon(): string {
    switch (this.product.type.ident) {
      case EApiAssetType.Bond:
        return 'format_bold';
      case EApiAssetType.Cash:
        return 'wallet';
      case EApiAssetType.Etf:
        return 'pie_chart';
      case EApiAssetType.Fund:
        return 'receipt_long';
      case EApiAssetType.Stock:
        return 'request_quote';
      default:
        return '';
    }
  }
}
