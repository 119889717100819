<ng-container *ngIf="!isCustomContent">
  <div class="aspark-container-padding">
    <app-content-form-fields
      #formFields
      *ngIf="guiConfig"
      [guiConfig]="guiConfig"
      [parentId]="parentId"
      [language]="language"
      [isCustomContent]="isCustomContent"
      (formValidityUpdate)="formValidityUpdate($event)"
      [content]="content"
      [attachments]="attachments"
      [isEditable]="isEditable"
    ></app-content-form-fields>
  </div>
</ng-container>
<ng-container *ngIf="isCustomContent">
  <mat-tab-group [(selectedIndex)]="activeTab">
    <mat-tab>
      <div class="custom-content-container">
        <ng-container *ngIf="!enableContentVideo">
          <div class="form-container">
            <app-content-form-fields
              #formFields
              *ngIf="guiConfig"
              [guiConfig]="guiConfig"
              [parentId]="parentId"
              [language]="language"
              [isCustomContent]="isCustomContent"
              (formValidityUpdate)="formValidityUpdate($event)"
              [content]="content"
              [attachments]="attachments"
              [isEditable]="isEditable"
              class="aspark-container-padding"
            ></app-content-form-fields>
          </div>
        </ng-container>
        <ng-container *ngIf="enableContentVideo">
          <div class="form-container" [class.min-height]="enableContentVideo">
            <mat-tab-group (selectedTabChange)="tabChanged($event)">
              <mat-tab label="{{ 'content' | translate }}">
                <app-content-form-fields
                  #formFields
                  *ngIf="guiConfig"
                  [guiConfig]="guiConfig"
                  [parentId]="parentId"
                  [language]="language"
                  [isCustomContent]="isCustomContent"
                  (formValidityUpdate)="formValidityUpdate($event)"
                  [content]="content"
                  [attachments]="attachments"
                  [isEditable]="isEditable"
                  class="aspark-container-padding"
                ></app-content-form-fields>
              </mat-tab>
              <mat-tab label="{{ 'video' | translate }}">
                <app-content-form-video
                  #formVideo
                  *ngIf="guiConfig"
                  [documentId]="content.video"
                  [guiConfig]="guiConfig"
                >
                </app-content-form-video>
              </mat-tab>
            </mat-tab-group>
          </div>
        </ng-container>
        <div class="modal-workflow-btn-container">
          <button
            mat-stroked-button
            mat-dialog-close
          >
            {{ 'cancelBackToCampaignOverview' | translate }}
          </button>
          <button
            mat-stroked-button
            (click)="editPreviewChanged('preview')"
          >
            {{ 'nextPreviewContent' | translate }}
          </button>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <div class="custom-content-container">
        <div class="form-container" [class.ebanking-padding]="this.isEbanking">
          <app-content-preview></app-content-preview>
        </div>
        <div class="modal-workflow-btn-container">
          <button
            mat-stroked-button
            (click)="editPreviewChanged('edit')"
          >
            {{ 'updateCustomContent' | translate }}
          </button>
          <button
            mat-flat-button
            color="primary"
            (click)="this.modalAction(this.dialogRef.componentInstance.type)"
          >
            {{ 'updateContent' | translate }}
          </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</ng-container>
