/**
 * aSpark core API
 * The REST API of aSpark\'s core service 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductListItem } from './productListItem';
import { Content } from './content';
import { StoryKey } from './storyKey';
import { UseCase } from './useCase';
import { CampaignProcessingError } from './campaignProcessingError';
import { CodeTableEntry } from './codeTableEntry';
import { ExternalLink } from './externalLink';


export interface Campaign { 
    id?: number;
    ident: string;
    name: string;
    story?: StoryKey;
    createdAt?: string;
    validFrom?: string;
    validTo: string;
    status: Campaign.StatusEnum;
    launchedAt?: string;
    frozenAt?: string;
    closedOrTerminatedAt?: string;
    filter: number;
    buyProducts?: Array<ProductListItem>;
    sellProducts?: Array<ProductListItem>;
    /**
     * A list of contents
     */
    contents?: Array<Content>;
    useCase?: UseCase;
    processing: boolean;
    decentralized: boolean;
    info?: string;
    error?: CampaignProcessingError;
    autoSyncContent: boolean;
    autoSyncBuyProducts: boolean;
    autoSyncSellProducts: boolean;
    autoSyncAllowedUsers: boolean;
    externalLinks: Array<ExternalLink>;
    publicationType: CodeTableEntry;
    hub: CodeTableEntry;
    coverImageUrl?: string;
}
export namespace Campaign {
    export type StatusEnum = 'DRAFT' | 'LAUNCHED' | 'FROZEN' | 'CLOSED' | 'TERMINATED';
    export const StatusEnum = {
        DRAFT: 'DRAFT' as StatusEnum,
        LAUNCHED: 'LAUNCHED' as StatusEnum,
        FROZEN: 'FROZEN' as StatusEnum,
        CLOSED: 'CLOSED' as StatusEnum,
        TERMINATED: 'TERMINATED' as StatusEnum
    };
}


