import {Component, Input, OnInit} from '@angular/core';
import {Params} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ColDef, RowDoubleClickedEvent,} from 'ag-grid-community';
import {GridOptions} from 'ag-grid-enterprise';
import {
  CampaignActionExtended,
  CampaignActionService,
  Client,
  ClientService,
  GridFilterOptionsParams,
  PortfolioService,
  UserInfo,
} from 'src/app/api/core';
import {CodeTableService} from 'src/app/services/code-table.service';
import {GlobalService} from 'src/app/services/global.service';
import {FilterLink, TablePresetFilterService,} from 'src/app/services/table-preset-filter.service';
import {GridDataProvider} from 'src/app/shared/grid/data-source';
import {ECodeTables, EViewRoutes} from 'src/app/util/enum';
import {
  genCodeTableColumn,
  genEnumColumn,
  genTextColumnWithAutoCompleteFilter,
  genUserEnumColumn,
  usernameValueLabel,
} from 'src/app/util/grid/grid-renderer.util';
import {genLinkColumn} from "../../../../shared/grid/cell-renderers/link.renderer";
import {genActionStatus} from "../../../../shared/grid/cell-renderers/action-status.renderer";
import {ActionType, ContentAction} from "../../../../campaign/views/campaign-overview/campaign-actions-list-utils";
import {genIconButtonColumn} from "../../../../shared/grid/cell-renderers/icon-button.renderer";
import {EProtectedActions} from "../../../../util/protected-actions";
import {PermissionService} from "../../../../services/permission.service";
import {CustomPreviewService} from "../../../../services/custom-preview.service";

/**
 * Component for client overview actions
 */
@Component({
  selector: 'app-client-overview-actions',
  templateUrl: './client-overview-actions.component.html',
})
export class ClientOverviewActionsComponent implements OnInit {
  @Input() client: Client;

  assignees: UserInfo[];

  dataActions: GridDataProvider;

  clientCampaignActionsDefs: ColDef[] = [

    genLinkColumn({
      field: 'campaign.name',
      headerName: this.translateService.instant('campaign'),
      link: (data: any) => `${EViewRoutes.campaignOverview}${data.campaign.id}`,
      getQueryParams: this.getQueryParams.bind(this),
      filterParamsInfo: {
        customPath: 'campaignPortfolio.campaign.name',
      }
    }),
    genEnumColumn({
      field: 'campaign.status',
      values: ['DRAFT', 'LAUNCHED', 'FROZEN', 'CLOSED', 'TERMINATED'],
      headerName: this.translateService.instant('campaignStatus'),
      filterParamsInfo: {customPath: 'campaignPortfolio.campaign.status'}
    }),
    genTextColumnWithAutoCompleteFilter({
      // sven: https://github.com/confinale/aspark/issues/8436
      field: 'portfolio.number',
      headerName: this.translateService.instant('portfolio'),
      autoCompleteParams: {
        apiMethod: (data: GridFilterOptionsParams) => this.portfolioService.getGridFilterOptions(data),
        autoCompleteField: 'number',
      },
      filterParamsInfo: { customPath: 'campaignPortfolio.portfolio.number' }
    }),
    genActionStatus(
      this.translateService,
      'status',
      this.translateService.instant('actionStatus'),
      true,
    ),
    genCodeTableColumn({
      field: 'language',
      headerName: this.translateService.instant('language'),
      observable: this.codeTableService.getCodeTable(ECodeTables.language),
    }),
    genCodeTableColumn({
      field: 'channel.type',
      headerName: this.translateService.instant('channel'),
      observable: this.codeTableService.getCodeTable(ECodeTables.channelType),
    }),
    {
      ...genIconButtonColumn({
        hidden: (data: CampaignActionExtended) =>
          !data.content ||
          !this.permissionService.hasAnyPermission(EProtectedActions.editCampaignCustomContent),
        callback: (data: CampaignActionExtended) => {
          this.showPreview(data)
        },
        tooltip: this.translateService.instant('viewContent'),
        icon: 'preview',
      }),
      sortable: false,
      floatingFilter: false,
    },
    {
      ...genUserEnumColumn(
        'assignee.username',
        this.translateService.instant('assignee'),
        this.fetchAssignees.bind(this),
        () => this.assignees
      ),
      valueFormatter: (r) => usernameValueLabel(r.data.assignee),
    },
  ];

  actionsGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.globalService.navigate(
        `${EViewRoutes.campaignOverview}${event.data.campaign.id}`,
        this.getQueryParams(event.data)
      ),
  };

  constructor(
    private translateService: TranslateService,
    private codeTableService: CodeTableService,
    private clientService: ClientService,
    private tablePresetFilterService: TablePresetFilterService,
    private globalService: GlobalService,
    private campaignActionsService: CampaignActionService,
    private permissionService: PermissionService,
    private customPreviewService: CustomPreviewService,
    private portfolioService: PortfolioService,
  ) {}

  ngOnInit(): void {
    this.dataActions =
      this.campaignActionsService.getClientCampaignActions.bind(
        this.campaignActionsService,
        this.client.id
      );
  }

  private showPreview(data: CampaignActionExtended) {
    const contentAction: ContentAction = {
      id: data.id,
      type: ActionType.CampaignAction,
      campaignId: data.campaign.id,
      language: data.language,
      channel: data.channel,
      content: data.content,
    }
    this.customPreviewService.previewCustomContent(
      contentAction,
      false,
      true);
  }

  private getQueryParams(data: any): Params {
    const filter: FilterLink = {
      name: 'portfolio.number',
      value: data.portfolio.number,
      filterType: 'TEXT',
      type: 'equals',
    };
    const presetFilter = this.tablePresetFilterService.genFilter(filter);
    this.tablePresetFilterService.saveTargetState(
      presetFilter.filterModel,
      null,
      presetFilter.stateId
    );
    return { 'campaign-portfolio-listState': presetFilter.stateId };
  }

  private fetchAssignees(params: any) {
    this.clientService
      .getPortfolioAssignees(this.client.id)
      .subscribe((data) => {
        this.assignees = data;
        params.success(data.map((d) => d.username));
      });
  }
}
