import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

export interface ButtonAction {
  text: string;
  click: () => void;
  show?: () => boolean;
  className?: string;
  icon?: string;
  tooltip?: () => string;
  disabled?: () => boolean;
}

@Component({
  selector: 'app-dropdown-selection-bar',
  templateUrl: './dropdown-selection-bar.component.html',
})
export class DropdownSelectionBarComponent {
  @ViewChild(MatMenuTrigger)
  trigger: MatMenuTrigger;

  @Input()
  useDropdownSelect = false;

  @Input()
  title: string;

  @Input()
  actions: ButtonAction[];

  @Input()
  hiddenActions: ButtonAction[];

  @Output()
  clearSelection: EventEmitter<void> = new EventEmitter<void>();

  _selectedAction: ButtonAction;

  get allActions(): ButtonAction[] {
    return [...this.actions, ...this.hiddenActions];
  }

  get isVisible(): boolean {
    return this.allActions.some((action) => !action.show || action.show());
  }

  get visibleActions(): ButtonAction[] {
    return this.actions.filter((action) => !action.show || action.show());
  }

  get visibleHiddenActions(): ButtonAction[] {
    return this.hiddenActions.filter((action) => !action.show || action.show());
  }

  get selectedAction(): ButtonAction {
    return this._selectedAction;
  }

  set selectedAction(action: ButtonAction) {
    this._selectedAction = action;
    if (this._selectedAction) {
      this._selectedAction.click();
    } else {
      this.clearSelection.emit();
    }
  }
  clear() {
    this._selectedAction = null;
  }
}
