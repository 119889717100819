import {Component, Input, OnInit} from '@angular/core';
import {Campaign, CampaignWithActionStatus, Client, ClientService} from '../../../../api/core';
import {TranslateService} from '@ngx-translate/core';
import {GlobalService} from '../../../../services/global.service';
import {ColDef, RowDoubleClickedEvent} from 'ag-grid-community';
import {GridOptions, ValueFormatterParams} from 'ag-grid-enterprise';
import {EViewRoutes} from '../../../../util/enum';
import {genBooleanColumn, genDateColumn, genEnumColumn, genTextColumn} from '../../../../util/grid/grid-renderer.util';
import {genLinkColumn} from "../../../../shared/grid/cell-renderers/link.renderer";
import {genActionStatus} from "../../../../shared/grid/cell-renderers/action-status.renderer";

@Component({
  selector: 'app-client-overview-campaigns',
  templateUrl: './client-overview-campaigns.component.html',
})
export class ClientOverviewCampaignsComponent implements OnInit {
  @Input() client: Client;

  campaigns: CampaignWithActionStatus[] = [];
  colDefs: ColDef[] = [
    genLinkColumn({
      field: 'name',
      headerName: this.translateService.instant('campaignName'),
      link: (data: any) => `${EViewRoutes.campaignOverview}${data.id}`,
    }),
    genDateColumn({
      field: 'createdAt',
      headerName: this.translateService.instant('creationDate'),
      dateFormatter: (params: ValueFormatterParams) =>
        this.globalService.dateToFrChLocale(params.data.createdAt),
    }),
    genDateColumn({
      field: 'validFrom',
      headerName: this.translateService.instant('validFrom'),
      dateFormatter: (params: ValueFormatterParams) =>
        this.globalService.dateToFrChLocale(params.data.validFrom),
    }),
    genDateColumn({
      field: 'validTo',
      headerName: this.translateService.instant('validTo'),
      dateFormatter: (params: ValueFormatterParams) =>
        this.globalService.dateToFrChLocale(params.data.validTo),
    }),
    genTextColumn('storyName', this.translateService.instant('storyName')),
    genEnumColumn({
      field: 'status',
      values: [
        Campaign.StatusEnum.LAUNCHED,
        Campaign.StatusEnum.FROZEN,
        Campaign.StatusEnum.CLOSED,
        Campaign.StatusEnum.DRAFT,
        Campaign.StatusEnum.TERMINATED,
      ],
      headerName: this.translateService.instant('campaignStatus')
    }),
    genActionStatus(
      this.translateService,
      'actionStatus',
      this.translateService.instant('clientStatus'),
      true,
    ),
    {
      ...genBooleanColumn(
        'processing',
        this.translateService.instant('processing'),
        this.translateService
      ), // needed because cellType inferred before transformation to text
      cellDataType: 'text',
    },
  ];
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.globalService.navigate(
        `${EViewRoutes.campaignOverview}${event.data.id}`
      ),
  };

  constructor(
    private translateService: TranslateService,
    private globalService: GlobalService,
    private clientService: ClientService
  ) {}

  ngOnInit(): void {
    this.clientService.getCampaigns(this.client.id).subscribe({
      next: (rows: CampaignWithActionStatus[]) => {
        this.campaigns = rows;
      },
    });
  }
}
