import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Intermediary, IntermediaryService, IntermediaryType, UserInfo,} from 'src/app/api/core';
import {GlobalService} from 'src/app/services/global.service';
import {GridDataProvider} from 'src/app/shared/grid/data-source';
import {ColDef, GridOptions} from 'ag-grid-enterprise';
import {TranslateService} from '@ngx-translate/core';
import {GridApi, GridReadyEvent, RowDoubleClickedEvent,} from 'ag-grid-community';
import {ECodeTables, EModalType, EViewRoutes} from 'src/app/util/enum';
import {CodeTableService} from 'src/app/services/code-table.service';
import {
  genBooleanColumn,
  genCodeTableColumn,
  genEnumColumn,
  genTextColumn,
  genUserEnumColumn,
  usernameValueLabel,
} from 'src/app/util/grid/grid-renderer.util';
import {GridComponent, GridResetEvent,} from 'src/app/shared/grid/grid.component';
import {DialogHeight, DialogWidth, ModalService} from '../../../services/modal.service';
import {Subscription} from 'rxjs';
import {I18n} from '../../../services/i18n.service';
import {genLinkColumn} from "../../../shared/grid/cell-renderers/link.renderer";
import {PermissionService} from "../../../services/permission.service";
import {genIconButtonColumn} from "../../../shared/grid/cell-renderers/icon-button.renderer";
import {convertFilterConfigToFilterBody} from "../../../services/filter.config-body";
import {FilterConfig} from "../../../models/filter.model";
import {ModalData} from "../../../models/modal.model";
import {
  IntermediaryPortfoliosPopupComponent
} from "../../../shared/intermediary-portfolios-popup/intermediary-portfolios-popup.component";
import {EProtectedActions} from "../../../util/protected-actions";

@Component({
  selector: 'app-intermediary-list',
  templateUrl: './intermediary-list.component.html',
})
export class IntermediaryListComponent implements OnInit, OnDestroy {
  @ViewChild('gridComponent', { static: true })
  gridComponent: GridComponent;
  @Input()
  currentFilter: FilterConfig;
  @Input()
  showHub: boolean = false;
  @Input()
  showClosed: boolean = false;

  /**
   * Data Provider for grid
   */
  @Input()
  data: GridDataProvider = this.intermediaryService.getIntermediaries.bind(
    this.intermediaryService
  );

  columnDefs: ColDef[];

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.navigateTo(`${EViewRoutes.intermediaryOverview}${event.data.id}`),
    onGridReady: (event: GridReadyEvent) => {
      this.gridReady(event);
      this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
    },
  };

  subscriptions: Subscription[] = [];
  relationshipManagers: UserInfo[];

  constructor(
    protected intermediaryService: IntermediaryService,
    protected globalService: GlobalService,
    protected translateService: TranslateService,
    protected codeTableService: CodeTableService,
    protected modalService: ModalService,
    protected permissionService: PermissionService,
  ) {
  }

  ngOnInit(): void {
    const isCidFilterAllowed = this.permissionService.hasAnyPermission(EProtectedActions.sortAndFilterCid);
    this.columnDefs = this.createColumnDefs(isCidFilterAllowed);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  navigateTo(route: string): void {
    this.globalService.navigate(route);
  }

  refresh() {
    this.gridComponent.refresh();
  }

  private gridReady(event: GridReadyEvent): void {
    this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
    this.gridFilterReset({ api: event.api });
  }

  private createColumnDefs(isCidFilterAllowed: boolean): ColDef[] {
    return [
      {
        ...genLinkColumn({
          field: 'name',
          headerName: I18n.getColName('name'),
          link: (data: any) => `${EViewRoutes.intermediaryOverview}${data.id}`,
        }),
        sortable: isCidFilterAllowed,
        floatingFilter: isCidFilterAllowed,
      },
      genTextColumn('externalKey', I18n.getColName('key')),
      genEnumColumn({
        field: 'type',
        values: ['EWA', 'EAM'],
        headerName: I18n.getColName('type'),
      }),
      this.showHub ? genCodeTableColumn({
        field: 'hub',
        headerName: I18n.getColName('hub'),
        observable: this.codeTableService.getCodeTable(ECodeTables.hub),
      }): null,
      {
        ...genUserEnumColumn(
          'leadRelationshipManager.username',
          I18n.getColName('leadRelationshipManager'),
          this.fetchRelationshipManagers.bind(this),
          () => this.relationshipManagers
        ),
        valueFormatter: (r) => usernameValueLabel(r.data.leadRelationshipManager),
      },
      this.showClosed ?
        genBooleanColumn(
          'closed',
          I18n.getColName('CLOSED'),
          this.translateService
        ) : null,
      {
        ...genIconButtonColumn({
          headerName: this.translateService.instant('portfolios'),
          icon: 'view',
          tooltip: this.translateService.instant('showPortfolios'),
          callback: (intermediary: Intermediary) => this.showPortfolios(intermediary),
          hidden: (intermediary: Intermediary) => intermediary.type !== IntermediaryType.EWA,
          suppressMenu: true,
        }),
        minWidth: 150,
        maxWidth: 150,
        sortable: false,
      }
    ].filter(Boolean);
  }

  gridFilterReset(event: GridResetEvent) {
    this.applyDefaultFilters(event.api);
  }

  private applyDefaultFilters(api: GridApi) {
    const closedFilter = api.getFilterInstance('closed');
    if (closedFilter && !closedFilter.getModel()) {
      closedFilter.setModel({ values: ['false'] });
      api.onFilterChanged();
    }
  }

  private fetchRelationshipManagers(params: any) {
    this.intermediaryService.getLeadRelationshipManagers().subscribe((data) => {
        this.relationshipManagers = data;
        params.success(data.map((d) => d.username));
      });
  }

  private showPortfolios(intermediary: Intermediary) {
    if (intermediary.type != "EWA") return;
    const filterBody = convertFilterConfigToFilterBody(
      this.currentFilter || {},
      this.permissionService.userRoleData)
    const data = {
      intermediaryId: intermediary.id,
      portfoliosGetter: this.intermediaryService.getPreviewIntermediaryPortfolios.bind(this.intermediaryService, intermediary.id, filterBody),
    };
    const modalData: ModalData = {
      type: EModalType.previewIntermediaryPortfolios,
      title: this.translateService.instant('intermediaryPortfolios'),
      data: data,
      component: IntermediaryPortfoliosPopupComponent,
    }
    this.modalService.openDefaultDialog(
      modalData,
      undefined,
      false,
      false,
      DialogWidth.AUTO,
      DialogHeight.AUTO,
    )
  }
}
