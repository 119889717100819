import {
  GridApi,
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from 'ag-grid-community';
import { ListParams, Paginated } from 'src/app/api/core';
import { Observable, throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { DatasourceFilter } from './datasource-filter';
import { SortModelItem } from 'ag-grid-community/dist/lib/sortController';
import { TableStateUtil } from 'src/app/util/grid/table-state.util';
import {GlobalService} from "../../services/global.service";

export class DataSource implements IServerSideDatasource {
  private gridApi: GridApi = null;

  constructor(
    private tableStateUtil: TableStateUtil,
    private tableId: string,
    private provider: GridDataProvider,
    private readonly globalService: GlobalService
  ) {}

  public initDatasource(api: GridApi) {
    this.gridApi = api;
  }

  public getRows(params: IServerSideGetRowsParams): void {
    const pageSize = params.request.endRow - params.request.startRow;
    const firstResult = params.request.startRow;
    const rowGroupCols = params.request.rowGroupCols;
    const groupKeys = params.request.groupKeys;
    const filter = new DatasourceFilter(
      this.gridApi,
      this.globalService,
      params.request
    ).toString();
    let sortString = this.sortFromModelString(
      this.gridApi,
      params.request.sortModel
    );
    sortString = sortString ? encodeURIComponent(sortString) : undefined;
    /**
     * this was a subscription before. removed subscription, because it cancelled the first request on refresh. we removed
     * it to fix the issue, but it could have further impact
     **/
    const obs: Observable<Paginated> = this.provider({
      firstResult,
      pageSize,
      filter,
      sortString,
      rowGroupCols,
      groupKeys,
    });
    obs
      .pipe(
        first(),
        catchError((err) => (params.fail(), throwError(() => err)))
      )
      .subscribe((p) => {
        params.success({
          rowData: p.pageItems,
          rowCount: p.count,
        });
        this.mergeModelsToQueryParams(
          params.request.filterModel,
          params.request.sortModel,
          -1
        );
        this.tableStateUtil.adjustPageIfNeeded(this.gridApi);
      });
  }

  public updateCurrentPage(): void {
    if (this.gridApi && this.tableId) {
      this.tableStateUtil.saveTableStateFromApi(this.gridApi);
    }
  }

  public mergeModelsToQueryParams(
    filterModel,
    sortModel,
    currentPage: number = -1
  ): void {
    if (this.tableId) {
      const strFilterModel = Object.keys(filterModel).length
        ? filterModel || null
        : null;
      const strSortModel =
        Array.isArray(sortModel) && sortModel.length ? sortModel || null : null;

      this.tableStateUtil.saveTableState(
        strFilterModel,
        strSortModel,
        currentPage
      );
    }
  }

  protected sortFromModelString(
    agGridApi: GridApi,
    sortModel: SortModelItem[]
  ): string {
    return sortModel
      .map((v) => {
        const columnDef = agGridApi.getColumnDef(v.colId);
        const columnPath =
          columnDef.filterParams?.sortPath ||
          columnDef.filterParams?.customPath ||
          v.colId;
        return `${columnPath}=${v.sort}`;
      })
      .join('&');
  }
}

export type GridDataProvider = (list: ListParams) => Observable<Paginated>;

export type GridSelectedItemsProvider = (filter?: string) => Observable<any[]>;

export type GridSelectedItemsProviderByCollection = (collectionId: number, filter?: string) => Observable<any[]>;
