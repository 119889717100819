<div class="page-content-container">
  <app-tabs-panel
    [tabs]="tabs"
    [defaultTab]="defaultTab"
    (tabChanged)="setActiveTab($event)"
  ></app-tabs-panel>

  <div *ngIf="activeTab === tabs[0].text" class="settings-users">
    <table class="roles-table">
      <thead>
        <tr>
          <th>{{ 'role' | translate }}</th>
          <th *ngFor="let role of statEntries">{{ role.label }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ 'users' | translate }}</td>
          <td *ngFor="let role of statEntries">{{ role.value }}</td>
        </tr>
      </tbody>
    </table>
    <app-grid
      tableId="users-grid"
      [columnDefs]="usersColumnDefs"
      [rowData]="users"
      [gridOptions]="usersGridOptions"
      (gridFiltersReset)="gridFilterReset($event)"
    ></app-grid>
  </div>
  <div *ngIf="activeTab === tabs[1].text" class="tab-container">
    <app-salutations></app-salutations>
  </div>
  <div *ngIf="activeTab === tabs[2].text" class="tab-container">
    <app-template-defaults></app-template-defaults>
  </div>
  <div *ngIf="activeTab === tabs[3].text" class="tab-container">
    <app-bucket-details></app-bucket-details>
  </div>
  <div *ngIf="activeTab === tabs[4].text" class="tab-container">
    <app-filter-configs></app-filter-configs>
  </div>
  <div *ngIf="activeTab === tabs[5].text" class="tab-container">
    <app-cc-framework></app-cc-framework>
  </div>
</div>
