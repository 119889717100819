import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {EMPTY, map, Observable, of, tap} from 'rxjs';
import {
  AdvisorsService,
  AssetSubClassService,
  BusinessService,
  ClientSegmentationService,
  ClientService,
  CodeTableEntry,
  CodeTablesService,
  ContinentService,
  CountryService,
  CurrencyService,
  GenderService,
  GicsService,
  HobbyService,
  LanguageService,
  PortfolioStrategyService,
  RankingService,
  RatingService,
  RegionService,
  RelationshipManagerService,
  RiskStateService,
  ServiceCenterService,
  SustainabilityService,
  UseCaseService,
  ViewedStatusService,
} from 'src/app/api/core';
import {ECodeTables} from 'src/app/util/enum';

interface CodeTableLocalStorageEntry {
  data: any[];
  expiryDate: string;
  language: string;
}

/**
 * Service for handling all code tables requests
 * Code tables are always cached until the end of the day for each language
 */
@Injectable({
  providedIn: 'root',
})
export class CodeTableService {
  constructor(
    protected codeTablesService: CodeTablesService,
    protected advisorService: AdvisorsService,
    protected assetSubClassService: AssetSubClassService,
    protected businessService: BusinessService,
    protected clientSegmentationService: ClientSegmentationService,
    protected clientService: ClientService,
    protected continentService: ContinentService,
    protected countryService: CountryService,
    protected currencyService: CurrencyService,
    protected genderService: GenderService,
    protected gicsService: GicsService,
    protected hobbyService: HobbyService,
    protected languageService: LanguageService,
    protected portfolioStrategyService: PortfolioStrategyService,
    protected rankingService: RankingService,
    protected ratingService: RatingService,
    protected regionService: RegionService,
    protected relationshipManagerService: RelationshipManagerService,
    protected riskStateService: RiskStateService,
    protected serviceCenterService: ServiceCenterService,
    protected sustainabilityService: SustainabilityService,
    protected translateService: TranslateService,
    protected useCaseService: UseCaseService,
    protected viewedStatusService: ViewedStatusService,
  ) {}

  getCodeTable(codeTable: ECodeTables): Observable<any[]> {
    const localStorageEntry = localStorage.getItem(codeTable);
    // check local storage
    if (localStorageEntry) {
      const item: CodeTableLocalStorageEntry = JSON.parse(localStorageEntry);
      const now = new Date();
      const expiryDate = new Date(item.expiryDate);
      if (
        item.language === this.translateService.currentLang &&
        now < expiryDate
      ) {
        // return correct language and not expired
        return of(item.data);
      } else {
        // remove expired or wrong language
        localStorage.removeItem(codeTable);
      }
    }
    // not found in local storage or not expired
    switch (codeTable) {
      case ECodeTables.advisoryType:
        return this.codeTablesService
          .getCodeTableEntries("ADVISORY_TYPE")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.assetClass:
        return this.codeTablesService
          .getCodeTableEntries("ASSET_CLASS")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.assetSubClass:
        return this.assetSubClassService
          .getAssetSubClasses()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.assetType:
        return this.codeTablesService
          .getCodeTableEntries("ASSET_TYPE")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.businessDivision:
        return this.codeTablesService
          .getCodeTableEntries("BUSINESS_DIVISION")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.businessUnit:
        return this.businessService
          .getUnits()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.channelType:
        return this.codeTablesService
          .getCodeTableEntries("CHANNEL_TYPE")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.clientType:
        return this.codeTablesService
          .getCodeTableEntries("CLIENT_TYPE")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.clientRole:
        return this.clientService
          .getClientRoles()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.continent:
        return this.continentService
          .getContinents()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.country:
        return this.countryService
          .getCountries()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.currency:
        return this.currencyService
          .getCurrencies()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.fidlegClientSegmentation:
        return this.clientSegmentationService
          .getFidlegList()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.gender:
        return this.genderService
          .getGenders()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.gics:
        return this.gicsService
          .getGics()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.hobby:
        return this.hobbyService
          .getHobbies()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.hub:
        return this.codeTablesService
          .getCodeTableEntries("HUB")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.language:
        return this.languageService
          .getLanguages()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.mifidClientSegmentation:
        return this.clientSegmentationService
          .getMifidList()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.portfolioStrategy:
        return this.portfolioStrategyService
          .getPortfolioStrategies()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.portfolioType:
        return this.codeTablesService
          .getCodeTableEntries("PORTFOLIO_TYPE")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.productInvestmentHorizon:
        return this.codeTablesService
          .getCodeTableEntries("PRODUCT_INVESTMENT_HORIZON")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.productRatingApac:
        return this.codeTablesService
          .getCodeTableEntries("PRODUCT_RATING_APAC")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.productRatingMe:
        return this.codeTablesService
          .getCodeTableEntries("PRODUCT_RATING_ME")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.ranking:
        return this.rankingService
          .getRankings()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.rating:
        return this.ratingService
          .getRatings()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.ratingMoody:
        return this.ratingService
          .getMoodyRatings()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.ratingSource:
        return this.ratingService
          .getRatingSources()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.ratingSp:
        return this.ratingService
          .getSpRatings()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.ratingSustainability:
        return this.ratingService
          .getSustainabilityRatings()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.region:
        return this.regionService
          .getRegions()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.riskState:
        return this.riskStateService
          .getRiskStates()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.rmMarket:
        return this.relationshipManagerService
          .getMarkets()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.serviceCenter:
        return this.serviceCenterService
          .getCenters()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.sustainabilityProfile:
        return this.sustainabilityService
          .getProfiles()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.useCase:
        return this.useCaseService
          .getUseCases()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.viewedStatus:
        return this.viewedStatusService
          .getViewedStatus()
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.publicationType:
        return this.codeTablesService
          .getCodeTableEntries("PUBLICATION_TYPE")
          .pipe(
            tap((data) => this.writeCodeTableToLocalStorage(codeTable, data))
          );
      case ECodeTables.relManager:
        // MODIFY DATA TO CODE TABLE DATA STRUCTURE
        return this.relationshipManagerService.getRelationshipManagers().pipe(
          tap((data) =>
            this.writeCodeTableToLocalStorage(
              codeTable,
              data.map((x) => ({
                id: x.id,
                ident: x.username,
                name: x.fullname,
              }))
            )
          ),
          map((data) => data.map((x) => ({
              id: x.id,
              ident: x.username,
              name: x.fullname,
            })))
        );
      case ECodeTables.relAdvisor:
        // MODIFY DATA TO CODE TABLE DATA STRUCTURE
        return this.advisorService.getAdvisors().pipe(
          tap((data) =>
            this.writeCodeTableToLocalStorage(
              codeTable,
              data.map((x) => ({
                id: x.id,
                ident: x.username,
                name: x.fullname,
              }))
            )
          ),
          map((data) => data.map((x) => ({
              id: x.id,
              ident: x.username,
              name: x.fullname,
            })))
        );
      default:
        return EMPTY;
    }
  }

  private writeCodeTableToLocalStorage(
    codeTable: ECodeTables,
    data: any[]
  ): void {
    const expiryDate = new Date();
    expiryDate.setHours(24, 0, 0, 0);
    const item = {
      data,
      expiryDate,
      language: this.translateService.currentLang,
    };
    localStorage.setItem(codeTable, JSON.stringify(item));
  }
}

export function clearCodeTablesFromLocalStorage() {
  const codeTables = Object.keys(ECodeTables);
  Object.keys(localStorage).forEach((key) => {
    if (codeTables.includes(key)) {
      localStorage.removeItem(key);
    }
  });
}
/**
 * Returns a list of code table entries with the given entry added if it is not already in the list.
 * @param entries
 * @param entry
 * @private
 */
export function codeTableEntries(entries: CodeTableEntry[], entry?: CodeTableEntry | CodeTableEntry[]): CodeTableEntry[] {
  let result = entries.filter(e => !e.closed);
  if (!entry) return result;
  // check if entry is an array
  const sort = (a, b) => a.name.localeCompare(b.name);
  if (Array.isArray(entry)) {
    const entries = entry as CodeTableEntry[];
      entries.forEach(e => {
        if (!result.find(it => it.id === e.id)) {
          result.push(e)
        }
      });
    result.sort(sort);
  } else {
    if (!result.find(e => e.id === entry.id)) {
      result.push(entry);
      result.sort(sort);
    }
  }
  return result;
}
