import {Component, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ColDef} from "ag-grid-enterprise";
import {genLinkColumn} from "../../../shared/grid/cell-renderers/link.renderer";
import {I18n} from "../../../services/i18n.service";
import {EViewRoutes} from "../../../util/enum";
import {
  genNumberColumn,
  genTextColumn,
  genTextColumnWithAutoCompleteFilter
} from "../../../util/grid/grid-renderer.util";
import {GlobalService} from "../../../services/global.service";
import {TranslateService} from "@ngx-translate/core";
import {GridDataProvider} from "../../../shared/grid/data-source";
import {ClientService, DistributorPortfolioSummary, GridFilterOptionsParams, PortfolioService} from "../../../api/core";
import {GridApi, GridOptions} from "ag-grid-community";
import {Observable, Subscription} from "rxjs";
import {GridComponent} from "../../../shared/grid/grid.component";
import {PermissionService} from "../../../services/permission.service";
import {LabelBuilder} from "../../../util/label-builder";

const updateThrottle: number = 2000;
let lastUpdate: number = 0;

@Component({
  selector: 'app-distributor-portfolios',
  templateUrl: './distributor-portfolios.component.html'
})
export class DistributorPortfoliosComponent implements OnInit, OnDestroy {
  @ViewChild("distributor_portfolios")
  grid: GridComponent;

  @Input() refreshEvent: Observable<void>;

  colDefs: ColDef[] = [
    genLinkColumn({
      field: "portfolioNumber",
      headerName: I18n.getColName('portfolioNumber'),
      link: (data: any) => `${EViewRoutes.distributorPortfolioOverview}/${data.id}`,
      filterParamsInfo: {
        // Currently not used - if that changes, update according to this issue https://github.com/confinale/aspark/issues/8436
        autoCompleteParams: {
          apiMethod: (data: GridFilterOptionsParams) => this.portfolioService.getGridFilterOptions(data),
          autoCompleteField: 'number',
        },
      },
    }),
    genTextColumnWithAutoCompleteFilter({
      // Currently not used - if that changes, update according to this issue https://github.com/confinale/aspark/issues/8436
      field: 'bpNumber',
      headerName: I18n.getColName('bpNumber'),
      autoCompleteParams: {
        apiMethod: (data: GridFilterOptionsParams) => this.portfolioService.getGridFilterOptions(data),
        autoCompleteField: 'bpNumber',
      },
    }),
    genTextColumn(
      "bpName",
      I18n.getColName('bpName'),
      null,
    ),
    {
      ...genNumberColumn(
        "executions",
        I18n.getColName('executions'),
        this.globalService,
        data => this.globalService.getFormattedValue(data.value, 0),
      ),
    },
    {
      ...genNumberColumn(
        "turnover",
        this.labelBuilder.labelWithCurrency('turnOver'),
        this.globalService,
        data => this.globalService.getFormattedValue(data.value, 2)
      ),
    },
  ];
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event) => {
      this.gridApi = event.api;
      this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
    },
  };

  data: GridDataProvider;
  summary: DistributorPortfolioSummary;
  gridApi: GridApi;
  subscriptions: Subscription[] = [];

  constructor(
    private readonly globalService: GlobalService,
    private readonly translateService: TranslateService,
    private readonly clientService: ClientService,
    private readonly portfolioService: PortfolioService,
    private readonly permissionService: PermissionService,
    private readonly labelBuilder: LabelBuilder,
  ) {
    this.data = this.portfolioService.getDistributorPortfolios.bind(this.clientService);
    this.subscriptions.push(permissionService.user$.subscribe(() => {
      this.updateSummaryAndGrid();
    }));
  }

  ngOnInit() {
    if (this.refreshEvent) {
      this.subscriptions.push(this.refreshEvent.subscribe(this.updateAll.bind(this)));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(cb => cb.unsubscribe())
  }

  private updateSummaryAndGrid() {
    this.portfolioService.getDistributorSummary()
      .subscribe(data => {
        this.summary = data;
        this.grid?.reload();
      });
  }

  updateAll() {
    this.clientService.updateDistributorClientPortfolios()
      .subscribe(() => {
        this.updateSummaryAndGrid()
      });
  }

  protected readonly EViewRoutes = EViewRoutes;
}
