import {Component, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ColDef} from "ag-grid-enterprise";
import {genLinkColumn} from "../../../shared/grid/cell-renderers/link.renderer";
import {I18n} from "../../../services/i18n.service";
import {EViewRoutes} from "../../../util/enum";
import {genBooleanColumn, genNumberColumn, genTextColumn} from "../../../util/grid/grid-renderer.util";
import {GlobalService} from "../../../services/global.service";
import {TranslateService} from "@ngx-translate/core";
import {GridDataProvider} from "../../../shared/grid/data-source";
import {ClientService, DistributorClientSummary} from "../../../api/core";
import {GridApi, GridOptions, GridReadyEvent, RowDoubleClickedEvent} from "ag-grid-community";
import {Observable, Subscription} from "rxjs";
import {GridComponent, GridResetEvent} from "../../../shared/grid/grid.component";
import {PermissionService} from "../../../services/permission.service";
import {LabelBuilder} from "../../../util/label-builder";

@Component({
  selector: 'app-distributor-clients',
  templateUrl: './distributor-clients.component.html'
})
export class DistributorClientsComponent implements OnInit, OnDestroy {
  @ViewChild("distributor_clients")
  grid: GridComponent;

  @Input() refreshEvent: Observable<void>;

  colDefs: ColDef[] = [
    genLinkColumn({
      field: "personNumber",
      headerName: I18n.getColName('personNumber'),
      link: (data: any) => `${EViewRoutes.distributorClientOverview}/${data.id}`,
    }),
    genTextColumn(
      "fullName",
      I18n.getColName('name'),
      null,
      {customPath: 'clientName'}
    ),
    {
      ...genNumberColumn(
        "received",
        I18n.getColName('received'),
        this.globalService,
        data => this.globalService.getFormattedValue(data.value, 0)
      ),
    },
    {
      ...genNumberColumn(
        "viewed",
        I18n.getColName('viewed'),
        this.globalService,
        data => this.globalService.getFormattedValue(data.value, 0)
      ),
    },
    {
      ...genNumberColumn(
        "executions",
        I18n.getColName('executions'),
        this.globalService,
        data => this.globalService.getFormattedValue(data.value, 0),
      ),
    },
    {
      ...genNumberColumn(
        "turnover",
        this.labelBuilder.labelWithCurrency('turnOver'),
        this.globalService,
        data => this.globalService.getFormattedValue(data.value, 2)
      ),
    },
    genBooleanColumn(
      'closed',
      I18n.getColName('CLOSED'),
      this.translateService
    ),
  ];
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.navigateTo(`${EViewRoutes.distributorClientOverview}${event.data.id}`),
    onGridReady: (event) => {
      this.gridApi = event.api;
      this.gridReady(event);
    },
  };

  data: GridDataProvider;
  summary: DistributorClientSummary;
  gridApi: GridApi;
  subscriptions: Subscription[] = [];

  constructor(
    private readonly globalService: GlobalService,
    private readonly translateService: TranslateService,
    private readonly clientService: ClientService,
    private readonly permissionService: PermissionService,
    private readonly labelBuilder: LabelBuilder,
  ) {
    this.data = this.clientService.getDistributorClients.bind(this.clientService);
    this.subscriptions.push(permissionService.user$.subscribe(() => {
      this.clientService.updateDistributorClientPortfolios().subscribe(() => this.updateSummaryAndGrid());
    }));
  }

  ngOnInit() {
    if (this.refreshEvent) {
      this.subscriptions.push(this.refreshEvent.subscribe(this.updateAll.bind(this)));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(cb => cb.unsubscribe())
  }

  private updateSummaryAndGrid() {
    this.clientService.getDistributorSummary()
      .subscribe(data => {
        this.summary = data;
        this.grid?.reload();
      });
  }

  updateAll() {
    this.clientService.updateDistributorClientPortfolios()
      .subscribe(() => {
        this.updateSummaryAndGrid()
      });
  }
  navigateTo(route: string): void {
    this.globalService.navigate(route);
  }

  private gridReady(event: GridReadyEvent): void {
    this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
    this.gridFilterReset({ api: event.api });
  }

  gridFilterReset(event: GridResetEvent) {
    this.applyDefaultFilters(event.api);
  }

  private applyDefaultFilters(api: GridApi) {
    const closedFilter = api.getFilterInstance('closed');
    if (closedFilter && !closedFilter.getModel()) {
      closedFilter.setModel({ values: ['false'] });
      api.onFilterChanged();
    }
  }

  protected readonly EViewRoutes = EViewRoutes;
}
