<div class="col aspark-container-padding" [class.bulk-edit-col]="bulkEdit">
  <div class="summary">
    <ng-container *ngIf="actionType === ActionType.CampaignAction">
      <div class="row">
        <div class="h4">{{ 'portfolios' | translate }}:</div>
        <div class="value">{{ selectedCounts.portfolios }}</div>
      </div>
      <div class="row">
        <div class="h4">{{ 'clients' | translate }}:</div>
        <div class="value">{{ selectedCounts.clients }}</div>
      </div>
    </ng-container>
    <ng-container *ngIf="actionType === ActionType.IntermediateAction">
      <div class="row">
        <div class="h4">{{ 'intermediaries' | translate }}:</div>
        <div class="value">{{ selectedCounts.intermediaries }}</div>
      </div>
      <div class="row">
        <div class="h4">{{ 'employees' | translate }}:</div>
        <div class="value">{{ selectedCounts.employees }}</div>
      </div>
    </ng-container>
    <div class="row">
      <div class="h4">{{ 'actions' | translate }}:</div>
      <div class="value">{{ selectedCampaignActions.length }}</div>
    </div>
  </div>
  <div class="hint" *ngIf="bulkEdit">
    {{ 'bulkEditHint' | translate }}
  </div>
  <div class="hint" *ngIf="!bulkEdit && modalType === EModalType.executeActionsDialog">
    {{ 'executeHint' | translate }}
  </div>
  <div class="bulk-edit" *ngIf="bulkEdit">
    <mat-form-field>
      <mat-label>{{ 'languageActual' | translate }}</mat-label>
      <mat-select [(value)]="language">
        <ng-container *ngIf="languages.length === 0">
          <mat-option value="">{{
            'noAvailableOptions' | translate
          }}</mat-option>
        </ng-container>
        <ng-container *ngIf="languages.length > 0">
          <mat-option value="">{{ 'noChange' | translate }}</mat-option>
          <mat-option *ngFor="let lang of languages" [value]="lang.code">{{
            lang.name
          }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'actionChannel' | translate }}</mat-label>
      <mat-select [(value)]="channelType">
        <ng-container *ngIf="channelTypes.length === 0">
          <mat-option value="">{{
            'noAvailableOptions' | translate
          }}</mat-option>
        </ng-container>
        <ng-container *ngIf="channelTypes.length > 0">
          <mat-option value="">{{ 'noChange' | translate }}</mat-option>
          <mat-option *ngFor="let ct of channelTypes" [value]="ct.ident">{{
            ct.name
          }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'sender' | translate }}</mat-label>
      <mat-select [(value)]="sender">
        <ng-container *ngIf="senders.length === 0">
          <mat-option value="">{{
            'noAvailableOptions' | translate
          }}</mat-option>
        </ng-container>
        <ng-container *ngIf="senders.length > 0">
          <mat-option value="">{{ 'noChange' | translate }}</mat-option>
          <mat-option *ngFor="let send of senders" [value]="send.username">{{
            send.fullname
          }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>
  <app-grid
    tableId="campaign-action-processing-grid"
    [rowData]="selectedRows"
    [columnDefs]="columnDefs"
    [gridOptions]="gridOptions"
    [pagination]="false"
  ></app-grid>
  <div *ngIf="showProgress">
    <span>{{
      (progressBarMode === 'determinate' && progressValue + '%') || ' '
    }}</span>
    <mat-progress-bar
      [mode]="progressBarMode"
      [value]="progressValue"
    ></mat-progress-bar>
  </div>
</div>
<div class="card-body-charts" *ngIf="!bulkEdit">
  <app-chart
    [chartType]="chartTypes.actionChannelPie"
    [data]="pieChannel"
  ></app-chart>
  <app-chart
    [chartType]="chartTypes.actionLanguagePie"
    [data]="pieLanguage"
  ></app-chart>
</div>
