import {Content, Field} from "../api/core";

/**
 * Verifies if the content has a title field related to it.
 * @param content
 */
export function hasTitle(content: Content): boolean {
  if (!content || !content.fields) {
    return false;
  }
  const value = fieldValue(content, Field.TypeEnum.TITLE);
  return value !== undefined && value !== null && value !== '';
}

/**
 * Returns the field value of a content, if such field is not present, it returns undefined.
 * @param content
 * @param fieldType
 */
export function fieldValue(content: Content, fieldType: Field.TypeEnum): string {
  const field = content.fields.find((f) => f.type === fieldType);
  return field ? field.value : undefined;
}
