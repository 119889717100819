import {Component, OnDestroy} from "@angular/core";
import {genLinkColumn} from "../../../../shared/grid/cell-renderers/link.renderer";
import {Subscription} from "rxjs";
import {I18n} from "../../../../services/i18n.service";
import {ColDef, GridApi} from "ag-grid-community";
import {GridOptions, ValueFormatterParams} from "ag-grid-enterprise";
import {EViewRoutes} from "../../../../util/enum";
import {genDateColumn, genTextColumn} from "../../../../util/grid/grid-renderer.util";
import {GlobalService} from "../../../../services/global.service";
import {TranslateService} from "@ngx-translate/core";
import {CampaignActionBestNext, CampaignActionService} from "../../../../api/core";
import {PermissionService} from "../../../../services/permission.service";

/**
 * Next best actions grid
 */
@Component({
  selector: 'app-home-best-actions',
  templateUrl: './home-best-actions.component.html',
})
export class HomeBestActionsComponent implements OnDestroy {

  colDefs: ColDef[] = [
    {
      ...genTextColumn(
        'client.personNumber',
        I18n.getColName('number'),
      ),
      floatingFilter: false,
    },
    {
      ...genLinkColumn({
        field: 'client.fullName',
        headerName: I18n.getColName('clientName'),
        link: (data: any) => `${EViewRoutes.distributorClientOverview}${data.client.id}`,
      }),
      floatingFilter: false,
    },

    {
      ...genTextColumn(
        'campaign.name',
        I18n.getColName('campaignName'),
      ),
      floatingFilter: false,
    },
    {
      ...genDateColumn({
        field: 'viewedAt',
        headerName: I18n.getColName('viewedAt'),
        dateFormatter: (params: ValueFormatterParams) =>
          this.globalService.dateToFrChLocale(params.data.viewedAt),
      }),
      floatingFilter: false,
      sort: 'desc',
    },
  ];

  gridApi: GridApi;
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event) => {
      this.gridApi = event.api;
      this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
    },
  };

  subscriptions: Subscription[] = [];

  actions: CampaignActionBestNext[] = [];

  constructor(
    protected globalService: GlobalService,
    protected translateService: TranslateService,
    protected campaignActionService: CampaignActionService,
    protected permissionService: PermissionService,
  ) {
    this.subscriptions.push(this.permissionService.user$.subscribe(_ => {
      this.refreshGrid();
    }))
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  refreshGrid() {
    this.campaignActionService.getBestNextCampaignActions()
      .subscribe((actions) => {
        this.actions = actions;
      });
  }

}
