<ng-container [ngSwitch]="metaData.type">
  <div class="filter-header" *ngIf="metaData.label">
    <div class="filter-label body">
      <ng-container>{{ metaData.label | translate }}:</ng-container>
    </div>
  </div>
  <div class="filter-content">
    <ng-container *ngSwitchCase="filterTypes.date">
      <mat-form-field>
        <input
          (dateChange)="onFilterDateInputChange()"
          [(ngModel)]="inputFilter"
          [matDatepicker]="picker"
          [disabled]="readOnly"
          matInput
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="filterTypes.dateRange">
      <mat-form-field class="min-max-form-field">
        <input
          (dateChange)="onFilterDateRangeMinChange()"
          [(ngModel)]="rangeMin"
          [matDatepicker]="fromPicker"
          [disabled]="readOnly"
          [min]=minDate
          [max]=maxDateFromRange
          matInput
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="fromPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #fromPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="min-max-form-field">
        <input
          (dateChange)="onFilterDateRangeMaxChange()"
          [(ngModel)]="rangeMax"
          [matDatepicker]="toPicker"
          [disabled]="readOnly"
          [min]=minDateFromRange
          [max]=maxDate
          matInput
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="toPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #toPicker></mat-datepicker>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="filterTypes.percentage">
      <mat-form-field>
        <input
          matInput
          type="number"
          [(ngModel)]="inputFilter"
          min="0"
          max="100"
          step="1"
          (change)="onFilterInputChange()"
          [disabled]="readOnly"
        />
        <span matSuffix>%</span>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="filterTypes.text">
      <mat-form-field>
        <input
          matInput
          type="text"
          [(ngModel)]="inputFilter"
          (change)="onFilterInputChange()"
          [disabled]="readOnly"
        />
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="filterTypes.amount">
      <mat-form-field>
        <input
          matInput
          [(ngModel)]="inputFilter"
          type="number"
          min="0"
          (change)="onFilterInputChange()"
          [disabled]="readOnly"
        />
        <span matPrefix>{{this.labelBuilder.selectedCurrencyLabel()}}</span>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="filterTypes.range">
      <mat-form-field class="min-max-form-field">
        <input
          matInput
          [(ngModel)]="rangeMin"
          type="number"
          (change)="onRangeMinChange()"
          placeholder="Minimum"
          [disabled]="readOnly"
        />
        <span matPrefix *ngIf="isFromTo || isOnlyMinMax">{{this.labelBuilder.selectedCurrencyLabel()}}</span>
        <span matSuffix *ngIf="isMinMaxPercentage">%</span>
      </mat-form-field>
      <mat-form-field class="min-max-form-field">
        <input
          matInput
          [(ngModel)]="rangeMax"
          type="number"
          (change)="onRangeMaxChange()"
          placeholder="Maximum"
          [disabled]="readOnly"
        />
        <span matPrefix *ngIf="isFromTo || isOnlyMinMax">{{this.labelBuilder.selectedCurrencyLabel()}}</span>
        <span matSuffix *ngIf="isMinMaxPercentage">%</span>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="filterTypes.rangeSlider">
      <ngx-slider
        class="primary"
        [(value)]="rangeSlider.minValue"
        [(highValue)]="rangeSlider.maxValue"
        [(options)]="rangeSlider.options"
        [manualRefresh]="refreshEmitter"
        (userChangeEnd)="onRangeSliderChange($event)"
      >
      </ngx-slider>
    </ng-container>
    <ng-container *ngSwitchCase="filterTypes.select">
      <mat-form-field>
        <mat-select
          (selectionChange)="onSelectChange()"
          [(ngModel)]="selectFilter"
          [disabled]="readOnly"
        >
          <mat-option
            *ngFor="let option of selectOptions; trackBy: trackByFn"
            [value]="option.key"
            >{{ option.value }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="filterTypes.multiSelectDropdown">
      <mat-form-field [matTooltip]="tooltip" [matTooltipDisabled]="!readOnly" matTooltipClass="read-only-tooltip">
        <mat-select multiple [(ngModel)]="selectFilter" [disabled]="readOnly">
          <mat-form-field
            floatLabel="never"
            class="select-search-field"
            *ngIf="selectOptions.length > 5"
          >
            <input
              matInput
              type="text"
              (keyup)="selectSearchOnKey($event.target.value)"
              [disabled]="readOnly"
            /><mat-placeholder class="placeholder">{{
              'typeToSearch' | translate
            }}</mat-placeholder></mat-form-field
          >
          <mat-option
            *ngFor="let multi of filterdOptions; trackBy: trackByFn"
            [value]="multi.key"
            (click)="onMultiSelectClick(multi.key)"
            >{{ multi.value }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="filterTypes.countryMultiSelect">
      <mat-form-field [matTooltip]="tooltip" [matTooltipDisabled]="!readOnly" matTooltipClass="read-only-tooltip">
        <mat-select multiple [(ngModel)]="selectFilter" [disabled]="readOnly">
          <mat-optgroup
            *ngFor="let continent of nestedSelectOptions"
            [label]="continent.label"
          >
            <mat-checkbox
              color="primary"
              (change)="onContinentCheckChange(continent.key, $event)"
              [checked]="
                continentSelect[continent.key] === filterSelectStatus.all
              "
              [indeterminate]="
                continentSelect[continent.key] === filterSelectStatus.some
              "
              [disabled]="readOnly"
            >
            </mat-checkbox>
            <mat-option
              *ngFor="let country of continent.children; trackBy: trackByFn"
              [value]="country.key"
              (click)="onMultiSelectClick(country.key)"
              >{{ country.label }}</mat-option
            >
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="filterTypes.toggle">
      <mat-slide-toggle
        color="primary"
        [checked]="value"
        (toggleChange)="toggleToggleFilter($event)"
        [disabled]="readOnly"
      >
      </mat-slide-toggle>
    </ng-container>
    <ng-container *ngSwitchCase="filterTypes.chips">
      <mat-form-field>
        <mat-chip-grid #chipList aria-label="getLabel()" [disabled]="readOnly">
          <mat-chip-row
            *ngFor="
              let chip of selectedChips;
              let i = index;
              trackBy: trackByFn
            "
            [removable]="true"
            (removed)="removeChip(i)"
          >
            {{ chip.value }}
            <app-config-icon
              matChipRemove
              iconIdentifier="cancel"
            ></app-config-icon>
          </mat-chip-row>
          <input
            #chipInput
            [formControl]="chipControl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
          />
        </mat-chip-grid>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="selectChip($event)"
          [disableRipple]="readOnly"
        >
          <mat-option
            *ngFor="let filteredChip of filteredChips; trackBy: trackByFn"
            [value]="filteredChip"
          >
            {{ filteredChip.value }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="filterTypes.autocomplete">
      <mat-form-field>
        <app-config-icon
          matPrefix
          *ngIf="filterOptions.icon"
          [iconIdentifier]="filterOptions.icon"
        ></app-config-icon>
        <input
          #autoComplete
          matInput
          [formControl]="autoControl"
          [matAutocomplete]="auto"
          [placeholder]="placeholder | translate"
          [readonly]="readOnly"
        />
        <app-config-icon
          matSuffix
          *ngIf="filterOptions.iconSuffix"
          [iconIdentifier]="filterOptions.iconSuffix"
        ></app-config-icon>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="selectAuto($event)"
          [disableRipple]="readOnly"
        >
          <mat-option
            *ngFor="let autoItem of autoList; trackBy: trackByFn"
            [value]="autoItem"
          >
            {{ autoItem.value }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
  </div>
  <div class="error-content">
    <mat-error *ngIf="minMaxRangeError">{{
      'minMustNotBeGreaterThanMaxError' | translate
    }}</mat-error>
  </div>
</ng-container>
