import {Component, OnDestroy} from "@angular/core";
import {Subscription} from "rxjs";
import {GlobalService} from "../../../../services/global.service";
import {TranslateService} from "@ngx-translate/core";
import {CampaignActionService, HomeChartData} from "../../../../api/core";
import {LabelBuilder} from "../../../../util/label-builder";
import {EChartTypes, EViewRoutes} from "../../../../util/enum";
import {PermissionService} from "../../../../services/permission.service";
import {EProtectedActions} from "../../../../util/protected-actions";

/**
 * Home screen chart and numbers component
 */
@Component({
  selector: 'app-home-chart',
  templateUrl: './home-chart.component.html',
})
export class HomeChartComponent implements OnDestroy {

  subscriptions: Subscription[] = [];

  chartData: HomeChartData = {
    participationCount: 0, participationPercentage: 0, viewed: 0, executions: 0, turnover: 0, buy: 0, sell: 0
  };

  pieParticipated: Object;

  constructor(
    protected globalService: GlobalService,
    protected translateService: TranslateService,
    protected campaignActionService: CampaignActionService,
    protected labelBuilder: LabelBuilder,
    protected permissionService: PermissionService,
  ) {
    this.subscriptions.push(this.permissionService.user$.subscribe(_ => {
      this.refreshData();
    }))
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  refreshData() {
    this.campaignActionService.getHomeChartData().subscribe((data) => {
      this.chartData = data;
      this.pieParticipated = this.getPieData();
    });
  }

  goToAllClients() {
    this.globalService.navigate(EViewRoutes.distributorClients);
  }

  get chartTypes() {
    return EChartTypes;
  }

  get protectedActions() {
    return EProtectedActions;
  }

  private getPieData(): Object {
    const res: Record<string, number> = {};
    const rate = this.chartData.participationPercentage
    res['participated'] = rate;
    res['notParticipated'] = 1 - rate;
    return {
      data: Object.keys(res).map(key => {
        return {name: key, value: res[key]};
      }),
      title: 'totalCampaignsParticipated',
      subtitle: this.chartData.participationCount
    }
  }
}
