<mat-menu #menu>
  <mat-form-field
    floatLabel="never"
    class="select-search-field"
    (click)="$event.stopPropagation()"
    *ngIf="items.length > 5"
  >
    <input
      matInput
      type="text"
      [(ngModel)]="searchFieldValue"
      #searchField
      [disabled]="readOnly"
    /><mat-placeholder class="placeholder">{{
      'typeToSearch' | translate
    }}</mat-placeholder></mat-form-field
  >
  <ng-container
    *ngFor="let item of items | nestedMenuArrayFilter: searchFieldValue"
  >
    <button
      *ngIf="!(item | hasChildren); else branch"
      (click)="onItemClick(item)"
      [disabled]="readOnly"
      mat-menu-item
    >
      {{ item.label }}
    </button>
    <ng-template #branch>
      <button
        (click)="onItemClick(item)"
        mat-menu-item
        [matMenuTriggerFor]="innerPanel.menu"
        [disabled]="readOnly"
      >
        {{ item.label }}
      </button>
      <app-dynamic-nested-menu
        #innerPanel
        [items]="item.children"
        (itemClick)="onItemClick($event)"
        [readOnly]="readOnly"
      ></app-dynamic-nested-menu>
    </ng-template>
  </ng-container>
</mat-menu>
