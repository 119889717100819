import {Component, Input} from '@angular/core';
import {OrgPosition, OrgPositionService} from '../../../api/core';
import {Bu, getOrgPositionText} from '../../org-position-users/org-position-users.component';
import {ColDef, GridReadyEvent, RowSelectedEvent, ValueGetterParams} from 'ag-grid-community';
import {genTextColumn} from '../../../util/grid/grid-renderer.util';
import {I18n} from '../../../services/i18n.service';
import {GridOptions, ITooltipParams} from 'ag-grid-enterprise';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Subscription} from 'rxjs';
import {genIconButtonColumn} from '../../grid/cell-renderers/icon-button.renderer';
import {ECodeTables} from '../../../util/enum';
import {CodeTableService} from '../../../services/code-table.service';
import {FilterOrgPosition} from '../../../models/filter.model';

@Component({
  selector: 'app-filter-details-org-positions',
  templateUrl: './filter-details-org-positions.component.html'
})
export class FilterDetailsOrgPositionsComponent {
  businessUnits: Bu[] = [];
  orgPositions: OrgPosition[] = [];
  selected: OrgPosition[] = [];
  canAdd = false;
  subscriptions: Subscription[] = [];
  _positionsForm: FilterOrgPosition = {positions: []};
  positions: OrgPosition[] = [];

  @Input()
  get positionsForm(): FilterOrgPosition {
    return this._positionsForm;
  }
  set positionsForm(value: FilterOrgPosition) {
    this._positionsForm = value || {positions: []};
    this.updatePositions();
  }

  @Input()
  readOnly = false;

  autoGroupColumnDef: ColDef = {
    headerName: this.translateService.instant('org_position_title'),
    minWidth: 200,
    filter: true,
    floatingFilter: true,
    suppressMenu: true,
    sortable: true,
    autoHeight: true,
    cellRendererParams: {
      suppressCount: true,
    },
    valueGetter: (params: ValueGetterParams<OrgPosition>) => params.data.description
  };
  defaultColDef: ColDef = {
    resizable: true
  };
  columnDefsTree: ColDef[] = [
    {
      ...genTextColumn('users', I18n.getColName('org_members')),
      sortable: true,
      cellStyle: {'white-space': 'break-spaces'},
      autoHeight: true,
      tooltipValueGetter: (params: ITooltipParams<OrgPosition, any>) =>
        params.data.users.map(u => `${u.fullname} (${u.username})`).join('\r')
      ,
      valueGetter: params =>
        params.data.users.map(u => `${u.fullname} (${u.username})`).join(', ')
    },
  ];
  gridOptionsTree: GridOptions<OrgPosition> = {
    treeData: true,
    animateRows: true,
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    groupDefaultExpanded: 1,
    suppressAggFuncInHeader: true,
    groupSelectsFiltered: false,
    rowSelection: 'single',
    enableBrowserTooltips: true,
    autoGroupColumnDef: this.autoGroupColumnDef,
    defaultColDef: this.defaultColDef,
    isRowSelectable: () => true,
    onRowSelected: (event) => this.onRowSelected(event),
    getDataPath: (data) => this.getDataPath(data),
    onGridReady: (params) => this.onGridReady(params),
  };

  positionColumnDefs: ColDef[] = [
    {
      ...genIconButtonColumn({
        icon: 'delete',
        iconOutlined: false,
        suppressMenu: true,
        callback: (position: OrgPosition) => this.removePosition(position),
      }),
      sortable: false,
    },
    {
      ...genTextColumn(
        'description',
        I18n.getColName('orgPosition'),
        (data) => (data.data) ? getOrgPositionText(data.data, this.orgPositions) : '',
      ),
      sortable: true,
      filter: true,
      floatingFilter: true,
      lockVisible: true,
    },
  ];

  gridOptionsPosition: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    groupDefaultExpanded: -1,
    suppressAggFuncInHeader: true,
    groupSelectsFiltered: false,
    floatingFiltersHeight: 0,
    onGridReady: (event) => this.onGridReady(event),
  };

  constructor(
    readonly translateService: TranslateService,
    readonly orgPositionService: OrgPositionService,
    readonly codeTableService: CodeTableService,
  ) {
    forkJoin([
      orgPositionService.getAll(),
      codeTableService.getCodeTable(ECodeTables.businessUnit),
    ])
      .subscribe(results => {
        this.orgPositions = results[0];
        this.businessUnits = results[1];
        this.updatePositions();
      });
  }

  onRowSelected(event: RowSelectedEvent<OrgPosition>) {
    this.selected = event.api.getSelectedRows() || [];
    this.canAdd = !!this.selected.length;
  }

  getDataPath(data: OrgPosition) {
    return data.path.split('/').filter(Boolean);
  }

  onGridReady(params: GridReadyEvent) {
    this.subscriptions.push(I18n.getColumns(this.translateService, params.api));
  }

  removePosition(position: OrgPosition) {
    this._positionsForm.positions = this.positions.filter(p => p.ident !== position.ident).map(p => p.ident);
    this.updatePositions();
  }

  getPositions(pos: OrgPosition): OrgPosition[] {
    return this.getChildren(pos).reduce((prev, curr) => [
      ...prev,
      ...this.getPositions(curr)], [pos]);
  }

  getDistinctPositions(positions: OrgPosition[]): OrgPosition[] {
    const res: Record<string, OrgPosition> = {};
    positions.forEach(p => res[p.ident] = p);
    return Object.values(res);
  }

  addOnly() {
    this.addPositions(...this.selected);
  }

  addWithChildren() {
    const positions = this.selected
      .map(p => this.getPositions(p))
      .reduce((prev, curr) => [...prev, ...curr], []);
    this.addPositions(...positions);
  }

  getChildren(pos: OrgPosition): OrgPosition[] {
    return this.orgPositions.filter(p => p.parentOrgPosition && p.parentOrgPosition === pos.ident);
  }

  addPositions(...positions: OrgPosition[]) {
    const addList = positions.filter(p => !this.positions.find(pp => pp.ident === p.ident));
    if (addList.length === 0) {
      return;
    }
    const result: OrgPosition[] = this.getDistinctPositions([...this.positions, ...positions]);
    result.sort((a: OrgPosition, b: OrgPosition) => a.ident.localeCompare(b.ident));
    this._positionsForm.positions = result.map(p => p.ident);
    this.updatePositions();
  }

  private updatePositions(){
    this.positions = this._positionsForm.positions
      .map(ident => this.orgPositions.find(p => p.ident === ident));
  }
}
