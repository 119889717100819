import {Component, Inject, Input, LOCALE_ID, OnInit, ViewChild,} from '@angular/core';
import {AssetRange, CompositeList} from 'src/app/models/filter.model';
import {INestedMenuItem} from 'src/app/models/menu.model';
import {GlobalService} from 'src/app/services/global.service';
import {NotificationService} from 'src/app/services/notification.service';
import {EFilterSubType, EFilterType} from 'src/app/util/enum';
import {FilterMetaData, IFilterMetaData,} from 'src/app/models/filter-meta-data.model';
import {IKeyValue} from 'src/app/models/key-value.model';
import {FilterComponent} from '../../filter/filter.component';

/**
 * Asset Class Filter Details Component.
 * Component for filter details for asset class category.
 */
@Component({
  selector: 'app-filter-details-asset',
  templateUrl: './filter-details-asset.component.html',
})
export class FilterDetailsAssetComponent implements OnInit {
  @ViewChild('autoCompleteFilter') autoCompleteFilter: FilterComponent;
  /**
   * Component input that references asset class range filter in filter config
   */
  @Input() filterForm: CompositeList<AssetRange>;

  // DEMO: used for trigger story
  @Input() isTriggerStory = false;

  @Input() readOnly = false;
  /**
   * All possible asset classes
   */
  assetClasses: INestedMenuItem[] = [];
  /**
   * Currently available assets classes
   */
  _searchMetadata: IFilterMetaData;
  _search: IKeyValue = null;

  get search(): IKeyValue {
    return this._search;
  }

  set search(value: IKeyValue) {
    this._search = value;
  }

  constructor(
    protected globalService: GlobalService,
    protected notificationService: NotificationService,
    @Inject(LOCALE_ID) protected locale: string
  ) {}

  ngOnInit() {}

  /**
   * Deletes filter with provided key
   * @param key Key of asset class filter
   */
  deleteFilter(key: string): void {
    const index = this.filterForm.children.findIndex((filter) => filter.key === key);
    this.filterForm.children.splice(index, 1);
  }

  get searchMetadata(): IFilterMetaData {
    if (!this._searchMetadata) {
      this._searchMetadata = new FilterMetaData(
        EFilterType.autocomplete,
        '',
        EFilterSubType.autoProducts,
        undefined,
        undefined,
        "chooseAnAsset"
      );
      this._searchMetadata.options = [
        {
          key: 'icon',
          value: 'search',
        },
      ];
    }
    return this._searchMetadata;
  }

  handleAdd() {
    const keyValuePair: IKeyValue = this._search;
    if (!keyValuePair) {return;}
    this._search = null;

    const index = this.filterForm.children.findIndex(
      (filter) => filter.key === keyValuePair.key
    );
    // if filter not yet added, add it to filterForm
    if (index === -1) {
      this.filterForm.children.push({
        key: keyValuePair.key,
        name: keyValuePair.value.split(' (')[0],
        range: {
          min: 0,
          max: 100,
        },
        isValue: false,
      });
    }
    this.autoCompleteFilter.clearCurrentValue();
  }

  changeOperator($event: string) {
    $event === 'and' ? this.filterForm.operator = 'and' : this.filterForm.operator = 'mor';
  }
}
