import {AfterViewInit, Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  GridFilterConfig,
  GridFilterItemComponent,
  GridFilterModel,
  GridFilterModelItem
} from 'src/app/models/grid.model';
import {KeyValue} from 'src/app/models/key-value.model';
import {ModalComponent} from 'src/app/shared/modal/modal.component';
import {EGridFilterType} from 'src/app/util/enum';
import {Subject, takeUntil} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {identifyActiveFilters} from "../grid-filter/grid-filter.component";
import {DialogHeight, DialogWidth} from "../../../../services/modal.service";

@Component({
  selector: 'app-grid-filter-form',
  templateUrl: './grid-filter-form.component.html',
})
export class GridFilterFormComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  activeFilters: KeyValue[] = [];
  activeFilterModel: GridFilterModel;
  originalFilterConfig: GridFilterConfig[];
  filterConfig: GridFilterConfig[];
  hasMoreFilters = false;
  hasHiddenFilters = false;
  @ViewChildren('cmp') filters: QueryList<GridFilterItemComponent>;

  observedFilters: String[] = [];

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      data: {
        config: GridFilterConfig[];
        model: GridFilterModel;
      };
    }
  ) {
    this.originalFilterConfig = [...data.data.config];
    this.activeFilterModel = structuredClone(data.data.model);
    this.filterConfig = this.defaultFilters(this.originalFilterConfig, this.activeFilterModel);
    this.hasMoreFilters = this.originalFilterConfig.length > this.filterConfig.length;
  }

  ngOnInit(): void {
    this.setActiveFilters();
    this.resizeDialog();
  }

  ngAfterViewInit(): void {
    this.setupFilterChangeSubscription();
    this.filters.changes
      .pipe(
        takeUntil(this.ngUnsubscribe)) // takeUntil should (almost always) be last
      .subscribe(() => {
        this.setupFilterChangeSubscription();
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Sets up the subscription for the filter changes
   * This is needed to update the filter tags
   */
  setupFilterChangeSubscription() {
    this.filters.forEach((filter) => {
      const entry = filter.getModel();
      if (entry?.form && !this.observedFilters.includes(entry.config.key)) {
        this.observedFilters.push(entry.config.key);
        entry.form.valueChanges
          .pipe(
            debounceTime(200),
            takeUntil(this.ngUnsubscribe)) // takeUntil should (almost always) be last
          .subscribe((value) => {
            if (!value.filter && !value.filterTo &&
              !Object.values(value)?.some((v) => v === true)) {
              this.activeFilters = this.activeFilters.filter((filter) =>
                filter.key !== entry.config.key);
            } else if (!this.activeFilters.some((filter) => filter.key === entry.config.key)) {
                this.activeFilters.push(new KeyValue(entry.config.key, entry.config.name));
            } // else: filter is already in activeFilters -> do nothing
          })
      }
    });
  }

  get gridFilterTypes() {
    return EGridFilterType;
  }

  modalAction(): void {
    this.filters.forEach((filter) => {
      const entry = filter.getModel();
      if (entry) {
        this.onFilterChanged(entry.model, entry.config)
      }
    });
    this.dialogRef.close(this.activeFilterModel);
  }

  onFilterChanged(filterItem: GridFilterModelItem, config: GridFilterConfig): void {
    if (filterItem) {
      const itemModel = this.activeFilterModel[config.key];
      if (itemModel) {
        this.activeFilterModel[config.key] = {
          ...this.activeFilterModel[config.key],
          ...filterItem,
        };
      } else {
        this.activeFilterModel[config.key] = filterItem;
      }
    } else {
      delete this.activeFilterModel[config.key];
    }
  }

  private setActiveFilters(): void {
    this.activeFilters = identifyActiveFilters(this.activeFilterModel, this.filterConfig);
  }

  loadMoreFilters() {
    this.hasHiddenFilters = this.filterConfig.length < this.originalFilterConfig.length;
    this.filterConfig = [...this.originalFilterConfig];
    this.resizeDialog();
    this.hasMoreFilters = false;
  }

  showLessFilters() {
    this.hasHiddenFilters = false;
    this.filterConfig = this.defaultFilters(this.originalFilterConfig, this.activeFilterModel);
    this.resizeDialog();
    this.hasMoreFilters = true;
  }

  clearAllFilters(): void {
    this.activeFilters.forEach((filter) => {
      this.clearFilter(filter);
    });
    this.activeFilters = [];
  }

  /**
   * Removes a specific filter from the filter form and resets all its values
   * For sets to false, and all others to empty string
   */
  clearFilter(filter: KeyValue): void {
    const filterModel = this.filters.find((f) =>
      f.getModel()?.config.key === filter.key)?.getModel()

    if (filterModel?.model?.filterType === 'set') {
      Object.keys(filterModel.form.controls).forEach((key) => {
        filterModel.form.controls[key].setValue(false);
      });
    } else {
      filterModel.form.patchValue({
        filter: '',
        filterTo: '',
      });
    }
  }

  /**
   * Returns the default filters to be shown in the filter dialog. These are the filters that are shown w/o
   * clicking on "More filters".
   * @param originalFilterConfig
   * @param activeFilterModel
   */
  private defaultFilters(
    originalFilterConfig: GridFilterConfig[],
    activeFilterModel: GridFilterModel
  ): GridFilterConfig[] {
    return originalFilterConfig.filter(c => {
      return (c.hide === undefined || c.hide === false)
        || c.important === true
        || activeFilterModel[c.key] !== undefined
    });
  }

  /**
   * Resizes the dialog initially and after 'More filters' or 'Show less' is clicked
   */
  resizeDialog(): void {
    const nrFilters = this.filterConfig.length;
    if (nrFilters == 1) {
      this.dialogRef.updateSize(DialogWidth.AUTO, DialogHeight.AUTO);
    } else if (nrFilters < 5) {
      this.dialogRef.updateSize('30vw', DialogHeight.AUTO);
    } else if (nrFilters < 7) {
      this.dialogRef.updateSize('45vw', DialogHeight.AUTO);
    } else if (!this.hasHiddenFilters && nrFilters >= 7) {
      this.dialogRef.updateSize('45vw', DialogHeight.DEFAULT);
    } else if (nrFilters < 9) {
      this.dialogRef.updateSize('60vw', DialogHeight.AUTO);
    } else if (nrFilters < 11) {
        this.dialogRef.updateSize('75vw', '75vh');
    } else {
      this.dialogRef.updateSize(DialogWidth.DEFAULT, DialogHeight.DEFAULT);
    }

    if (!this.hasHiddenFilters || nrFilters < 11) {
      this.dialogRef.updatePosition({top: undefined, left: undefined});
    } else {
      this.dialogRef.updatePosition({top: undefined, left: '96px'});
    }
  }
}
