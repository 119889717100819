<div class="page-content-container">
  <div class="salutation-container">
    <app-sub-header [header]="'salutationIntros'">
      <button mat-stroked-button (click)="onAdd('INTRO')">
        <app-config-icon iconIdentifier="open_in_new"></app-config-icon>
        {{ 'addSalutationIntro' | translate }}
      </button>
    </app-sub-header>
    <app-grid
      tableId="salutations-intro-catalogue"
      [rowData]="intros"
      [columnDefs]="introColumns"
      [gridOptions]="introsGridOptions"
      card-body
    ></app-grid>
  </div>
  <div class="salutation-container">
    <app-sub-header [header]="'salutationGreetings'">
      <button mat-stroked-button (click)="onAdd('OUTRO')">
        <app-config-icon iconIdentifier="open_in_new"></app-config-icon>
        {{ 'addSalutationGreeting' | translate }}
      </button>
    </app-sub-header>
    <app-grid
      tableId="salutations-greeting-catalogue"
      [rowData]="greetings"
      [columnDefs]="greetingColumns"
      [gridOptions]="greetingsGridOptions"
      card-body
    ></app-grid>
  </div>
</div>
