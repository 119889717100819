<div class="grid-filter-item">
  <div class="grid-filter-item-title h4">{{ filterConfig.name }}</div>
  <div class="grid-filter-item-content">
    <form [formGroup]="dateFilterForm">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'value' | translate }}</mat-label>
        <input
          matInput
          formControlName="filter"
        />
      </mat-form-field>
      <mat-form-field
        *ngIf="dateFilterForm.value.type === 'inRange'"
        appearance="outline"
      >
        <mat-label>{{ 'valueTo' | translate }}</mat-label>
        <input
          matInput
          formControlName="filterTo"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'type' | translate }}</mat-label>
        <mat-select formControlName="type">
          <mat-option
            *ngFor="let option of typeOptions"
            [value]="option.value"
          >{{ option.key }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </form>
  </div>
</div>
