import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Allowed, OrgPosition, OrgPositionService, User, UserService,} from '../../api/core';
import {GridOptions, ITooltipParams} from 'ag-grid-enterprise';
import {ColDef, GridReadyEvent, RowSelectedEvent, ValueGetterParams,} from 'ag-grid-community';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {I18n} from '../../services/i18n.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CodeTableService} from '../../services/code-table.service';
import {ModalService} from '../../services/modal.service';
import {ECodeTables, EModalType} from '../../util/enum';
import {ModalData} from '../../models/modal.model';
import {genTextColumn} from '../../util/grid/grid-renderer.util';
import {genIconButtonColumn} from '../grid/cell-renderers/icon-button.renderer';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {debounceTime, distinctUntilChanged, filter, switchMap,} from 'rxjs/operators';

export interface Bu {
  ident: string;
  name: string;
}

@Component({
  selector: 'app-org-position-users',
  templateUrl: './org-position-users.component.html',
})
export class OrgPositionUsersComponent implements OnInit, OnDestroy {
  businessUnits: Bu[];
  orgPositions: OrgPosition[];
  positions: OrgPosition[] = [];
  users: User[] = [];
  selected: OrgPosition[] = [];
  canAdd = false;
  justSelectPosition = false;

  autoGroupColumnDef: ColDef = {
    headerName: this.translateService.instant('org_position_title'),
    minWidth: 200,
    filter: true,
    floatingFilter: true,
    suppressMenu: true,
    sortable: true,
    autoHeight: true,
    cellRendererParams: {
      suppressCount: true,
    },
    valueGetter: (params: ValueGetterParams<OrgPosition>) =>
      params.data.description,
    resizable: true,
  };
  columnDefsTree: ColDef[] = [
    {
      ...genTextColumn('users', I18n.getColName('org_members')),
      sortable: true,
      cellStyle: { 'white-space': 'break-spaces' },
      autoHeight: true,
      tooltipValueGetter: (params: ITooltipParams<OrgPosition>) =>
        params.data.users
          .map((u) => `${u.fullname} (${u.username})`)
          .join('\r'),
      valueGetter: (params) =>
        params.data.users
          .map((u) => `${u.fullname} (${u.username})`)
          .join(', '),
    },
  ].filter((d) => d);
  gridOptionsTree: GridOptions<OrgPosition> = {
    treeData: true,
    animateRows: true,
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    groupDefaultExpanded: 1,
    suppressAggFuncInHeader: true,
    groupSelectsFiltered: false,
    rowSelection: 'single',
    enableBrowserTooltips: true,
    autoGroupColumnDef: this.autoGroupColumnDef,
    isRowSelectable: () => true,
    onRowSelected: this.onRowSelected.bind(this),
    getDataPath: this.getDataPath.bind(this),
    onGridReady: this.onGridReady.bind(this),
  };

  columnDefsList: ColDef[] = [
    {
      ...genIconButtonColumn({
        icon: 'delete',
        iconOutlined: false,
        suppressMenu: true,
        callback: this.removeUser.bind(this),
      }),
      sortable: false,
    },
    {
      ...genTextColumn('fullname', I18n.getColName('org_users')),
      valueGetter: (params) =>
        `${params.data.fullname} (${params.data.username})`,
      lockVisible: true,
    },
  ];
  gridOptionsList: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    groupDefaultExpanded: -1,
    suppressAggFuncInHeader: true,
    groupSelectsFiltered: false,
    floatingFiltersHeight: 0,
    onGridReady: (event) => this.onGridReady(event),
  };

  positionColumnDefs: ColDef[] = [
    {
      ...genIconButtonColumn({
        icon: 'delete',
        iconOutlined: false,
        suppressMenu: true,
        callback: (position: OrgPosition) => this.removePosition(position),
      }),
      sortable: false,
    },
    {
      ...genTextColumn('description', I18n.getColName('orgPosition'), (data) =>
        getOrgPositionText(data.data, this.orgPositions)
      ),
      tooltipValueGetter: (data: ITooltipParams<OrgPosition>) =>
        getOrgPositionTooltip(data.data, this.orgPositions),
      sortable: true,
      floatingFilter: true,
      lockVisible: true,
    },
  ];

  gridOptionsPosition: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    groupDefaultExpanded: -1,
    suppressAggFuncInHeader: true,
    groupSelectsFiltered: false,
    floatingFiltersHeight: 0,
    onGridReady: (event) => this.onGridReady(event),
  };

  userControl = new FormControl('');
  filteredUsers$: Observable<User[]>;
  subscriptions: Subscription[] = [];

  constructor(
    readonly translateService: TranslateService,
    readonly userService: UserService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      data: {
        orgPositions: OrgPosition[];
        businessUnits: Bu[];
        allowed: Allowed;
        justSelectPosition: boolean;
      };
    },
    public dialogRef: MatDialogRef<any>
  ) {
    this.orgPositions = data.data.orgPositions;
    this.businessUnits = data.data.businessUnits;
    this.users = data.data.allowed.users;
    this.positions = data.data.allowed.positions;
    this.justSelectPosition = data.data.justSelectPosition;
    this.autoGroupColumnDef.checkboxSelection = this.justSelectPosition;
  }

  ngOnInit() {
    this.filteredUsers$ = this.userControl.valueChanges.pipe(
      filter((val: string) => val && val.length > 0),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((val) => this.userService.searchUsers(val))
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  getChildren(pos: OrgPosition): OrgPosition[] {
    return this.orgPositions.filter(
      (p) => p.parentOrgPosition && p.parentOrgPosition === pos.ident
    );
  }

  getDataPath(data: OrgPosition) {
    return data.path.split('/').filter(Boolean);
  }

  getPositions(pos: OrgPosition): OrgPosition[] {
    return this.getChildren(pos).reduce(
      (prev, curr) => [...prev, ...this.getPositions(curr)],
      [pos]
    );
  }

  getDistinctUsers(users: User[]): User[] {
    const res: Record<number, User> = {};
    users.forEach((u) => (res[u.id] = u));
    return Object.values(res);
  }

  getDistinctPositions(positions: OrgPosition[]): OrgPosition[] {
    const res: Record<string, OrgPosition> = {};
    positions.forEach((p) => (res[p.ident] = p));
    return Object.values(res);
  }

  addOnly() {
    this.addPositions(...this.selected);
  }

  addWithChildren() {
    const positions = this.selected
      .map((p) => this.getPositions(p))
      .reduce((prev, curr) => [...prev, ...curr], []);
    this.addPositions(...positions);
  }

  addUsers(...users: User[]) {
    const addList = users.filter(
      (u) => !this.users.find((uu) => uu.id === u.id)
    );
    if (addList.length === 0) {
      return;
    }
    const result: User[] = this.getDistinctUsers([
      ...this.users,
      ...users,
    ]);
    result.sort((a: User, b: User) => a.fullname.localeCompare(b.fullname));
    this.users = result;
  }

  addPositions(...positions: OrgPosition[]) {
    const addList = positions.filter(
      (p) => !this.positions.find((pp) => pp.ident === p.ident)
    );
    if (addList.length === 0) {
      return;
    }
    const result: OrgPosition[] = this.getDistinctPositions([
      ...this.positions,
      ...positions,
    ]);
    result.sort((a: OrgPosition, b: OrgPosition) =>
      a.ident.localeCompare(b.ident)
    );
    this.positions = result;
  }

  onRowSelected(event: RowSelectedEvent<OrgPosition>) {
    this.selected = event.api.getSelectedRows() || [];
    this.canAdd = !!this.selected.length;
  }

  removeUser(user: User) {
    this.users = this.users.filter((u) => u.id !== user.id);
  }

  removePosition(position: OrgPosition) {
    this.positions = this.positions.filter((p) => p.ident !== position.ident);
  }

  okClicked() {
    if (this.justSelectPosition) {
      this.dialogRef.close({
        users: [],
        positions: this.selected || [],
      });
    } else {
      this.dialogRef.close({
        users: this.users || [],
        positions: this.positions || [],
      });
    }
  }

  removeAll() {
    this.users = [];
    this.positions = [];
  }

  onUserSelect(event: MatAutocompleteSelectedEvent): void {
    const user = event.option.value;
    this.addUsers(user);
    this.userControl.setValue('', {
      onlySelf: true,
      emitEvent: false,
    });
  }

  onGridReady(params: GridReadyEvent) {
    this.subscriptions.push(I18n.getColumns(this.translateService, params.api));
    if (this.justSelectPosition) {
      this.selected = this.positions
        .map((p) => this.orgPositions.find((op) => op.ident === p.ident))
        .filter(Boolean);
      params.api.forEachNode((node) => {
        node.setSelected(
          !!this.positions.find((p) => p.ident === node.data.ident)
        );
      });
    }
  }
}
function getOrgPositionParent(
  position: OrgPosition,
  orgPositions: OrgPosition[]
): OrgPosition {
  const parentOrgPosition = position.ident.split('.');
  const parentIdent = parentOrgPosition.slice(0, -1).join('.');
  return orgPositions.find((p) => p.ident === parentIdent);
}

const ORG_POSITION_SEPARATOR = ' > ';

export function getOrgPositionText(
  data: OrgPosition,
  orgPositions: OrgPosition[],
  levels: number = 2
): string {
  const result: string[] = [data.description];
  let parent = getOrgPositionParent(data, orgPositions);
  while (parent && levels--) {
    result.push(parent.description);
    parent = getOrgPositionParent(parent, orgPositions);
  }
  return result.reverse().join(ORG_POSITION_SEPARATOR);
}

export function getOrgPositionTooltip(
  data: OrgPosition,
  orgPositions: OrgPosition[]
): string {
  const result: string[] = [data.description];
  let parent = getOrgPositionParent(data, orgPositions);
  while (parent) {
    result.push(parent.description);
    parent = getOrgPositionParent(parent, orgPositions);
  }
  return result.reverse().join(ORG_POSITION_SEPARATOR);
}

export function pickOrgPositionUsersDialog(
  orgPositionService: OrgPositionService,
  codeTableService: CodeTableService,
  translateService: TranslateService,
  modalService: ModalService,
  allowed: Allowed,
  justSelectPosition: boolean = false
): Promise<Allowed | undefined> {
  return new Promise<Allowed>((resolve) => {
    forkJoin([
      orgPositionService.getAll(),
      codeTableService.getCodeTable(ECodeTables.businessUnit),
    ]).subscribe((results) => {
      const orgPositions: OrgPosition[] = results[0];
      const businessUnits: any[] = results[1];
      const modalData = new ModalData(
        EModalType.orgPositions,
        {
          orgPositions,
          businessUnits,
          allowed,
          justSelectPosition,
        },
        translateService.instant('org_positions_pick_user'),
        OrgPositionUsersComponent
      );
      const dialogRef = modalService.openDefaultDialog(
        modalData,
        undefined,
        false
      );
      dialogRef.afterClosed().subscribe((result) => {
        resolve(result || null);
      });
    });
  });
}
