<div class="filter-form-container">
  <div class="grid-active-filter-container">
    <div *ngIf="activeFilters.length === 0" class="placeholder body-2">
      <div>{{ 'noActiveFilters' | translate }}</div>
    </div>
    <div *ngIf="activeFilters.length > 0" class="active-filters">
      <mat-chip-set>
        <mat-chip *ngFor="let filter of activeFilters" (removed)="clearFilter(filter)">
          <div class="filter-text color-white">{{ filter.value }}</div>
          <button matChipRemove>
            <app-config-icon
              iconIdentifier="close"
              class="h2 color-white"
              [matTooltip]="'clearFilter' | translate"
            />
          </button>
        </mat-chip>
        <mat-divider [vertical]="true"></mat-divider>
        <mat-chip class="clear-all-filters" (click)="clearAllFilters()">
          <div class="filter-text color-primary">{{ 'clearAll' | translate }}</div>
        </mat-chip>
      </mat-chip-set>
    </div>
  </div>
  <div class="grid-filter-container">
    <ng-container *ngFor="let config of filterConfig" [ngSwitch]="config.type">
      <app-grid-filter-item-date #cmp
        *ngSwitchCase="gridFilterTypes.date"
        [filterConfig]="config"
        [filterModel]="activeFilterModel[config.key]"
      ></app-grid-filter-item-date>
      <app-grid-filter-item-numeric #cmp
        *ngSwitchCase="gridFilterTypes.numeric"
        [filterConfig]="config"
        [filterModel]="activeFilterModel[config.key]"
      ></app-grid-filter-item-numeric>
      <app-grid-filter-item-set #cmp
        *ngSwitchCase="gridFilterTypes.set"
        [filterConfig]="config"
        [filterModel]="activeFilterModel[config.key]"
      ></app-grid-filter-item-set>
      <app-grid-filter-item-text #cmp
        *ngSwitchCase="gridFilterTypes.text"
        [filterConfig]="config"
        [filterModel]="activeFilterModel[config.key]"
      ></app-grid-filter-item-text>
    </ng-container>
  </div>
  <div class="load-more" mat-ripple *ngIf="hasMoreFilters">
    <a class="color-primary" (click)="loadMoreFilters()">
      <span>{{ 'moreFilters' | translate }}</span>
      <app-config-icon iconIdentifier="expand_more"></app-config-icon>
    </a>
  </div>
  <div class="load-more" mat-ripple *ngIf="hasHiddenFilters">
    <a class="color-primary" (click)="showLessFilters()">
      <span>{{ 'showLess' | translate }}</span>
      <app-config-icon iconIdentifier="expand_less"></app-config-icon>
    </a>
  </div>
</div>
