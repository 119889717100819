import {Component, OnInit} from "@angular/core";
import {
  CampaignActionService,
  CampaignDistributorAction,
  Client,
  ClientService,
  DistributorClientSummary
} from "../../../../api/core";
import {ActivatedRoute, Params} from "@angular/router";
import {GlobalService} from "../../../../services/global.service";
import {ColDef, RowDoubleClickedEvent, ValueFormatterParams} from "ag-grid-community";
import {genLinkColumn} from "../../../../shared/grid/cell-renderers/link.renderer";
import {EViewRoutes} from "../../../../util/enum";
import {
  genBooleanColumn,
  genCodeTableColumn,
  genDateColumn,
  genNumberColumn
} from "../../../../util/grid/grid-renderer.util";
import {GridOptions} from "ag-grid-enterprise";
import {ActionType, ContentAction} from "../../../../campaign/views/campaign-overview/campaign-actions-list-utils";
import {FilterLink, TablePresetFilterService} from "../../../../services/table-preset-filter.service";
import {TranslateService} from "@ngx-translate/core";
import {CodeTableService} from "../../../../services/code-table.service";
import {genCustomContentColumn} from "../../../../shared/grid/cell-renderers/custom-content.renderer";
import {EProtectedActions} from "../../../../util/protected-actions";
import {PermissionService} from "../../../../services/permission.service";
import {CustomPreviewService} from "../../../../services/custom-preview.service";
import {LabelBuilder} from "../../../../util/label-builder";

@Component({
  selector: 'app-distributor-client-overview',
  templateUrl: './distributor-client-overview.component.html',
})
export class DistributorClientOverviewComponent implements OnInit {
  client: Client;

  summary: DistributorClientSummary;

  actions: CampaignDistributorAction[] = [];
  colDefs: ColDef[] = [
    genLinkColumn({
      field: 'campaign.name',
      headerName: this.translateService.instant('campaignsSent'),
      link: (data: any) => `${EViewRoutes.campaignOverview}${data.campaign.id}`,
      getQueryParams: this.getQueryParams.bind(this),
    }),
    genDateColumn({
      field: 'sentDate',
      headerName: this.translateService.instant('sent'),
      dateFormatter: (params: ValueFormatterParams) =>
        this.globalService.dateToFrChLocale(params.data.sentDate),
    }),
    {
      ...genCustomContentColumn({
        field: 'content.id',
        headerName: '',
        hidden: (data: any) =>
          !this.permissionService.hasAnyPermission(EProtectedActions.editCampaignCustomContent) ||
          !data.hasCidPermission,
        callback: (data: CampaignDistributorAction) => {
          const contentAction: ContentAction = {
            id: data.id,
            type: ActionType.CampaignAction,
            campaignId: data.campaign.id,
            language: data.language,
            channel: data.channel,
            content: data.content,
          }
          this.customPreviewService.previewCustomContent(contentAction, false);
        },
      }),
      floatingFilter: false,
      sortable: true,
      suppressMenu: true,
      suppressColumnsToolPanel: true,
    },
    {
      ...genBooleanColumn(
        'viewed.ident',
        this.translateService.instant('viewed'),
        this.translateService,
      ), // needed because cellType inferred before transformation to text
      cellDataType: 'text',
      valueGetter: (params) => {
        return params.data.viewed.ident == 'yes';
      }
    },
    {
      ...genBooleanColumn(
        'executed',
        this.translateService.instant('executed'),
        this.translateService
      ), // needed because cellType inferred before transformation to text
      cellDataType: 'text',
    },
    {
      ...genCodeTableColumn({
        field: 'channel.type',
        headerName: this.translateService.instant('channel'),
      }),
    },
    genNumberColumn(
      "turnover",
      this.labelBuilder.labelWithCurrency('tradingVolume'),
      this.globalService,
      data => this.globalService.getFormattedValue(data.value, 2)
    ),
  ];

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.globalService.navigate(
        `${EViewRoutes.campaignOverview}${event.data.campaign.id}`,
        this.getQueryParams(event.data)
      ),
  };

  constructor(
    protected translateService: TranslateService,
    protected codeTableService: CodeTableService,
    protected activatedRoute: ActivatedRoute,
    protected globalService: GlobalService,
    protected clientService: ClientService,
    protected tablePresetFilterService: TablePresetFilterService,
    protected campaignActionsService: CampaignActionService,
    protected permissionService: PermissionService,
    protected customPreviewService: CustomPreviewService,
    protected labelBuilder: LabelBuilder,
  ) {
    activatedRoute.data.subscribe((data) => {
      this.client = data.client;
    });
  }

  ngOnInit(): void {
    this.clientService.getDistributorSummaryForClient(this.client.id)
      .subscribe(data => {
        this.summary = data;
      });
    this.campaignActionsService.getDistributorClientCampaignActions(this.client.id)
      .subscribe((data) => {
        this.actions = data;
      });
  }

  private getQueryParams(data: any): Params {
    const filter: FilterLink = {
      name: 'portfolio.number',
      value: data.portfolioNumber,
      filterType: 'TEXT',
      type: 'equals',
    };
    const presetFilter = this.tablePresetFilterService.genFilter(filter);
    this.tablePresetFilterService.saveTargetState(
      presetFilter.filterModel,
      null,
      presetFilter.stateId
    );
    return { 'campaign-portfolio-listState': presetFilter.stateId };
  }

    protected readonly EViewRoutes = EViewRoutes;
}
