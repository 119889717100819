import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DistributorTotalReceived, IntermediaryService,} from 'src/app/api/core';
import {GlobalService} from 'src/app/services/global.service';
import {GridDataProvider} from 'src/app/shared/grid/data-source';
import {ColDef, GridOptions} from 'ag-grid-enterprise';
import {TranslateService} from '@ngx-translate/core';
import {GridApi, GridReadyEvent, RowDoubleClickedEvent,} from 'ag-grid-community';
import {EViewRoutes} from 'src/app/util/enum';
import {genBooleanColumn, genNumberColumn, genTextColumn,} from 'src/app/util/grid/grid-renderer.util';
import {Observable, Subscription} from 'rxjs';
import {I18n} from '../../../services/i18n.service';
import {genLinkColumn} from "../../../shared/grid/cell-renderers/link.renderer";
import {GridComponent, GridResetEvent} from "../../../shared/grid/grid.component";
import {PermissionService} from "../../../services/permission.service";

@Component({
  selector: 'app-distributor-intermediaries',
  templateUrl: './distributor-intermediaries.component.html',
})
export class DistributorIntermediariesComponent implements OnInit, OnDestroy {
  @ViewChild("intermediary_grid")
  grid: GridComponent;
  /**
   * Data Provider for grid
   */
  data: GridDataProvider;

  @Input() refreshEvent: Observable<void>;

  columnDefs: ColDef[] = [
    genLinkColumn({
      field: 'name',
      headerName: I18n.getColName('name'),
      link: (data: any) => `${EViewRoutes.distributorIntermediaryOverview}${data.id}`,
    }),
    genTextColumn('externalKey', I18n.getColName('key')),
    genNumberColumn(
      'received',
      I18n.getColName('received'),
      this.globalService,
      (d) => this.globalService.getFormattedValue(d.value, 0)
    ),
    genBooleanColumn(
      'closed',
      I18n.getColName('CLOSED'),
      this.translateService
    ),
  ];

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.navigateTo(`${EViewRoutes.distributorIntermediaryOverview}${event.data.id}`),
    onGridReady: (event: GridReadyEvent) => {
      this.gridReady(event);
    },
  };

  subscriptions: Subscription[] = [];
  summary: DistributorTotalReceived;

  constructor(
    protected intermediaryService: IntermediaryService,
    protected globalService: GlobalService,
    protected translateService: TranslateService,
    private readonly permissionService: PermissionService,
  ) {
    this.data = intermediaryService.getDistributorIntermediaries.bind(this.intermediaryService);
    this.subscriptions.push(permissionService.user$.subscribe(() => {
      this.updateSummaryAndGrid();
    }));
  }

  ngOnInit(): void {
    if (this.refreshEvent) {
      this.subscriptions.push(this.refreshEvent.subscribe(this.updateAll.bind(this)));
    }
  }

  private updateAll() {
    this.updateSummaryAndGrid();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  navigateTo(route: string): void {
    this.globalService.navigate(route);
  }

  private gridReady(event: GridReadyEvent): void {
    this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
    this.gridFilterReset({ api: event.api });
  }

  gridFilterReset(event: GridResetEvent) {
    this.applyDefaultFilters(event.api);
  }

  private applyDefaultFilters(api: GridApi) {
    const closedFilter = api.getFilterInstance('closed');
    if (closedFilter && !closedFilter.getModel()) {
      closedFilter.setModel({ values: ['false'] });
      api.onFilterChanged();
    }
  }

  private updateSummaryAndGrid() {
    this.intermediaryService.getDistributorTotalReceived().subscribe((data) => {
      this.summary = data;
      this.grid?.reload();
    });
  }

  protected readonly EViewRoutes = EViewRoutes;
}
