import { Component, Input, OnInit } from '@angular/core';
import { NestedMenuItem, INestedMenuItem } from 'src/app/models/menu.model';
import { NotificationService } from 'src/app/services/notification.service';
import { CodeTableService } from 'src/app/services/code-table.service';
import { ECodeTables, EFilterCategories } from 'src/app/util/enum';
import {CompositeList, WeightRange} from 'src/app/models/filter.model';

/**
 * Currency Filter Details Component.
 * Component for filter details for currency category.
 */
@Component({
  selector: 'app-filter-details-currency',
  templateUrl: './filter-details-currency.component.html',
})
export class FilterDetailsCurrencyComponent implements OnInit {
  /**
   * Component input that references currency range filter in filter config
   */
  @Input() filterForm: CompositeList<WeightRange>;

  @Input() readOnly = false;

  /**
   * All available currencies
   */
  currencies: INestedMenuItem[] = [];
  /**
   * Currently available currencies
   */
  availableCurrencies: INestedMenuItem[] = [];

  get filterCategories() {
    return EFilterCategories;
  }

  constructor(
    protected codeTableService: CodeTableService,
    protected notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.codeTableService
      .getCodeTable(ECodeTables.currency)
      .subscribe((data) => {
        this.currencies = data.map(
          (currency) => new NestedMenuItem(currency.code, currency.name)
        );
        this.availableCurrencies = this.currencies.filter(
          (currency) =>
            !this.filterForm.children.find((filter) => filter.key === currency.key)
        ).sort((a,b) => a.label.localeCompare(b.label));
      });
  }

  /**
   * Adds filter with provided key
   * @param currency Key of currency filter
   */
  addFilter(currency: INestedMenuItem): void {
    this.filterForm.children.push({
      key: currency.key,
      range: {
        max: null,
        min: null,
      },
      weight: null,
    });
    const index = this.availableCurrencies.findIndex(
      (ac) => ac.key === currency.key
    );
    this.availableCurrencies.splice(index, 1);
  }

  /**
   * Deletes filter with provided key
   * @param currency Key of currency filter
   */
  deleteFilter(currency: string): void {
    const index = this.filterForm.children.findIndex(
      (filter) => filter.key === currency
    );
    this.filterForm.children.splice(index, 1);
    this.availableCurrencies = this.currencies.filter(
      (c) => !this.filterForm.children.find((filter) => filter.key === c.key)
    );
  }

  /**
   * Get label based on key
   * @param key Filter key
   */
  getLabel(key: string): string {
    return this.currencies.find((c) => c.key === key).label;
  }

  changeOperator($event: string) {
    $event === 'and' ? this.filterForm.operator = 'and' : this.filterForm.operator = 'mor';
  }
}
