<div class="grid-filter-item">
  <div class="grid-filter-item-title h4">{{ filterConfig.name }}</div>
  <div class="grid-filter-item-content">
    <form [formGroup]="textFilterForm">
      <mat-form-field appearance="outline" *ngIf="typeOptions.length > 1">
        <mat-label>{{ 'type' | translate }}</mat-label>
        <mat-select formControlName="type">
          <mat-option
            *ngFor="let option of typeOptions"
            [value]="option.value"
            >{{ option.key }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'value' | translate }}</mat-label>
        <input matInput formControlName="filter" [matAutocomplete]="auto"/>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let option of autoOptions"
            [value]="option"
            >{{ option }}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
  </div>
</div>
