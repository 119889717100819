import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {finalize, first} from 'rxjs/operators';
import {
  CampaignActionService,
  CampaignIntermediaryActionService,
  CampaignService,
  Content,
  ContentAttachment,
  Gui,
  GuiService,
  Language,
  StoryService,
} from 'src/app/api/core';
import {ContentData} from 'src/app/models/content.model';
import {ModalSubComponent} from 'src/app/models/modal.model';
import {ModalComponent} from 'src/app/shared/modal/modal.component';
import {EModalType} from 'src/app/util/enum';
import {FeatureFlagsService} from '../../services/feature-flags.service';
import {ContentFormFieldsComponent} from '../content-form-fields/content-form-fields.component';
import {ContentFormVideoComponent} from '../content-form-video/content-form-video.component';
import {Observable} from "rxjs";
import {ActionType} from "../../campaign/views/campaign-overview/campaign-actions-list-utils";
import {hasTitle} from "../../util/content.util";
import {ContentPreviewComponent} from "../content-preview/content-preview.component";

/**
 * Component to content form
 */
@Component({
  selector: 'app-content-form',
  templateUrl: './content-form.component.html',
})
export class ContentFormComponent implements OnInit, ModalSubComponent {
  isEditable: boolean;
  content: Content;
  @ViewChild('formFields') formFields: ContentFormFieldsComponent;
  @ViewChild('formVideo') formVideo: ContentFormVideoComponent;
  parentId: number;
  language: Language;
  isCustomContent: boolean;
  enableContentVideo: boolean;
  guiConfig: Gui;
  attachments: ContentAttachment[] = [];
  isEbanking: boolean;

  // View switch and current field value communication
  tabs = ['edit', 'preview'];
  activeTab = 0;
  @ViewChild(ContentPreviewComponent, { static: false }) private preview: ContentPreviewComponent;

  constructor(
    private storyService: StoryService,
    private campaignService: CampaignService,
    private campaignActionService: CampaignActionService,
    private campaignIntermediaryActionService: CampaignIntermediaryActionService,
    private guiService: GuiService,
    private featureFlagsService: FeatureFlagsService,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { data: ContentData }
  ) {
    this.guiService.getConfig().subscribe((config) => {
      this.guiConfig = config;
    });
    this.parentId = data.data.parentId;
    this.language = data.data.language;

    this.isEditable = data.data.editable;
    this.content = data.data.content;
    this.attachments = [...(this.content?.attachments||[])];
    this.isEbanking = data.data.channel?.type.ident == 'ebanking';

    if (!this.isEditable) {
      setTimeout(() => {
        this.dialogRef.componentInstance.toolbarActionData.btnDisabled = true;
      });
    } else {
      // initial check if form is valid
      this.dialogRef.componentInstance.toolbarActionData.btnDisabled = !hasTitle(this.content);
    }

    this.isCustomContent =
      this.dialogRef.componentInstance.type ===
        EModalType.createCustomContent ||
      this.dialogRef.componentInstance.type === EModalType.updateCustomContent;
    this.featureFlagsService.getFeatureFlags().subscribe((f) => {
      this.enableContentVideo = f.contentVideo && this.isCustomContent;
    });
  }

  ngOnInit(): void {}

  /**
   * Triggered by parent component story-modal.component
   */
  modalAction(modalType: EModalType): void {
    const content: Content = {
      ...this.formFields.withUpdatedContentValues(this.content, this.formFields.contentForm.getRawValue()),
      language: this.language,
      video: this.formVideo?.documentId,
      attachments: [...this.attachments],
    };

    switch (modalType) {
      case EModalType.createStoryContent:
        this.storyService
          .createStoryContent(this.parentId, content)
          .pipe(
            first(),
            finalize(() =>
              this.dialogRef.componentInstance.resetToolbarActionButtons()
            )
          )
          .subscribe({
            next: () => this.dialogRef.close(true),
          });
        break;
      case EModalType.updateStoryContent:
        this.storyService
          .updateStoryContent(this.parentId, content)
          .pipe(
            first(),
            finalize(() =>
              this.dialogRef.componentInstance.resetToolbarActionButtons()
            )
          )
          .subscribe({
            next: () => this.dialogRef.close(true),
          });
        break;
      case EModalType.createCampaignContent:
        this.campaignService
          .createCampaignContent(this.parentId, content)
          .pipe(
            first(),
            finalize(() =>
              this.dialogRef.componentInstance.resetToolbarActionButtons()
            )
          )
          .subscribe({
            next: () => this.dialogRef.close(true),
          });
        break;
      case EModalType.updateCampaignContent:
        this.campaignService
          .updateCampaignContent(this.parentId, content)
          .pipe(
            first(),
            finalize(() =>
              this.dialogRef.componentInstance.resetToolbarActionButtons()
            )
          )
          .subscribe({
            next: () => this.dialogRef.close(true),
          });
        break;
      case EModalType.createCustomContent:
      case EModalType.updateCustomContent:
        this.updateCustomContent(content);
        break;
      default:
        break;
    }
  }

  private updateCustomContent(content: Content) {
    let serviceCall: Observable<any>;
    if (this.data.data.type === ActionType.CampaignAction) {
      serviceCall = this.campaignActionService
          .editCampaignActionContent(this.parentId, content);
    } else {
      serviceCall = this.campaignIntermediaryActionService.editContent(this.parentId, content)
    }
    serviceCall.pipe(
        first(),
        finalize(() =>
            this.dialogRef.componentInstance.resetToolbarActionButtons()
        )
    )
    .subscribe({
      next: () => this.dialogRef.close(true),
    });
  }

  formValidityUpdate(isValid: boolean) {
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = !isValid;
  }

  tabChanged(evt: MatTabChangeEvent) {
    if (evt.index === 1) {
      this.formVideo.ngAfterViewInit();
    }
  }

  editPreviewChanged(tab: string) {
    let contentUserInput = {
      ...this.formFields.withUpdatedContentValues(this.content, this.formFields.contentForm.getRawValue()),
      video: this.formVideo?.documentId,
    };
    this.preview.updateContentPreview(contentUserInput);
    this.activeTab = this.tabs.indexOf(tab);
  }
}
