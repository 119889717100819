import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RowClickedEvent } from 'ag-grid-community';
import { ColDef, GridOptions } from 'ag-grid-enterprise';
import { filter, Observable, Subscription } from 'rxjs';
import {Campaign, Content, Field, Language, Story} from 'src/app/api/core';
import { ContentData } from 'src/app/models/content.model';
import { genIconButtonColumn } from 'src/app/shared/grid/cell-renderers/icon-button.renderer';
import { CodeTableService } from 'src/app/services/code-table.service';
import { DataService } from 'src/app/services/data.service';
import { ECodeTables, EModalType } from 'src/app/util/enum';
import { ModalData } from '../../models/modal.model';
import {
  ContentPreviewComponent,
  ContentPreviewParams,
} from '../content-preview/content-preview.component';
import { ModalService } from '../../services/modal.service';
import {
  FeatureFlags,
  FeatureFlagsService,
} from '../../services/feature-flags.service';
import { DocumentTransferService } from '../../services/document-transfer.service';
import { PermissionService } from '../../services/permission.service';
import { EProtectedActions } from '../../util/protected-actions';
import {environment} from "../../../environments/environment";
import {ActionType} from "../../campaign/views/campaign-overview/campaign-actions-list-utils";
import {fieldValue} from "../../util/content.util";

/**
 * Component for content card
 */
@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
})
export class ContentCardComponent implements OnInit, OnDestroy {
  @Input() set allowAddOrEditContent(value: boolean) {
    const origValue = this._allowAddOrEditContent;
    this._allowAddOrEditContent = value;
    if (origValue !== value) {
      this.updateColumnDefs();
    }
  }
  get allowAddOrEditContent(): boolean {
    return this._allowAddOrEditContent;
  }
  private _allowAddOrEditContent = false;
  @Output() addOrEditContent: EventEmitter<ContentData> =
    new EventEmitter<ContentData>();
  @Output() deleteContent: EventEmitter<number> = new EventEmitter<number>();
  @Output() restoreContent: EventEmitter<void> = new EventEmitter<void>();

  @Output() toggleAutoSync: EventEmitter<void> = new EventEmitter<void>();

  loading$: Observable<boolean>;

  allLanguages: Language[];
  availableLanguages: Language[];
  campaign: Campaign;
  story: Story;
  contents: Content[];
  featureFlags: FeatureFlags;
  autoSyncEnabled = false;
  autoSyncContent = false;
  autoSyncContentMsg: string;
  autoSyncFeature = false;

  contentColumns: ColDef[];
  contentGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    rowMultiSelectWithClick: true,
    onRowClicked: (event: RowClickedEvent) => this.editContent(event.data),
  };

  private subscriptions: Subscription[] = [];

  constructor(
    protected readonly dataService: DataService,
    protected readonly modalService: ModalService,
    protected readonly codeTableService: CodeTableService,
    protected readonly translateService: TranslateService,
    protected readonly featureFlagsService: FeatureFlagsService,
    private readonly documentTransferService: DocumentTransferService,
    private readonly permissionService: PermissionService,
  ) {
    this.loading$ = this.dataService.loading$;
    this.featureFlagsService.getFeatureFlags().subscribe({
      next: (d: FeatureFlags) => {
        this.featureFlags = d;
        this.updateData();
        this.updateColumnDefs();
      },
    });
  }

  private updateColumnDefs() {
    this.contentColumns = this.generateColDefs();
  }
  private updateData() {
    this.subscriptions.push(
      this.dataService.campaign$
        .pipe(filter((campaign) => !!campaign))
        .subscribe((campaign) => {
          this.campaign = campaign;
          this.contents = [...this.campaign.contents];
          if (this.allLanguages) {
            this.calculateAvailableLanguages();
          }
          this.autoSyncFeature = this.campaign.story !== null;
          const isCampaignClosed =
            this.campaign.status === Campaign.StatusEnum.CLOSED ||
            this.campaign.status === Campaign.StatusEnum.TERMINATED;
          this.autoSyncEnabled =
            !isCampaignClosed &&
            !this.campaign.story?.autoSyncContent &&
            this.permissionService.hasAnyPermission(
              EProtectedActions.autoSyncCampaign
            );
          this.autoSyncContent =
            this.campaign.autoSyncContent ||
            this.campaign.story?.autoSyncContent;
          if (this.autoSyncEnabled) {
            this.autoSyncContentMsg = this.translateService.instant(
              this.autoSyncContent
                ? 'autoSyncToStoryDisable'
                : 'autoSyncToStoryEnable'
            );
          }
        })
    );

    this.subscriptions.push(
      this.dataService.story$
        .pipe(filter((story) => !!story))
        .subscribe((story) => {
          this.story = story;
          this.contents = [...this.story.contents];
          if (this.allLanguages) {
            this.calculateAvailableLanguages();
          }
          this.autoSyncFeature = true;
          this.autoSyncEnabled = this.permissionService.hasAnyPermission(
            EProtectedActions.autoSyncStory
          );
          this.autoSyncContent = this.story.autoSyncContent;
          this.autoSyncContentMsg = this.translateService.instant(
            this.autoSyncContent
              ? 'autoSyncToCampaignsDisable'
              : 'autoSyncToCampaignsEnable'
          );
        })
    );
  }

  ngOnInit(): void {
    this.codeTableService
      .getCodeTable(ECodeTables.language)
      .subscribe((data) => {
        this.allLanguages = data;
        if (this.campaign || this.story) {
          this.calculateAvailableLanguages();
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  get campaignStatus() {
    return Campaign.StatusEnum;
  }

  calculateAvailableLanguages(): void {
    this.availableLanguages = this.allLanguages.filter((lang) => {
      if (this.story) {
        return !this.story.contents.find((c) => c.language.code === lang.code);
      } else {
        return !this.campaign.contents.find(
          (c) => c.language.code === lang.code
        );
      }
    });
  }

  addContent(language: Language): void {
    this.addOrEditContent.emit(
      new ContentData(
        ActionType.CampaignAction,
        language,
        this.campaign ? this.campaign.id : this.story.id,
        null,
        this.allowAddOrEditContent
      )
    );
  }

  editContent(content: Content): void {
    this.addOrEditContent.emit(
      new ContentData(
        ActionType.CampaignAction,
        content.language,
        this.campaign ? this.campaign.id : this.story.id,
        content,
        this.allowAddOrEditContent
      )
    );
  }

  previewContent(content: Content): void {
    const contentPreviewParams = {
      id: content.id,
      storyId: this.story ? this.story.id : undefined,
      campaignId: this.campaign ? this.campaign.id : undefined,
    } as ContentPreviewParams;
    const modalData: ModalData = {
      type: EModalType.previewContent,
      title: 'contentPreview',
      data: { contentPreviewParams },
      submitBtn: null,
      component: ContentPreviewComponent,
    };
    this.modalService.openDefaultDialog(modalData, 'custom-content-dialog');
  }

  restoreCampaignContent(): void {
    this.restoreContent.emit();
  }

  private generateColDefs() {
    const cols = [
      this.allowAddOrEditContent
          ? genIconButtonColumn({
              callback: (contentData: Content) => this.editContent(contentData),
              icon: 'edit_m',
              suppressMenu: true,
              tooltip: this.translateService.instant('editContent'),
          })
          : undefined,
      genIconButtonColumn({
          callback: (contentData: Content) => this.previewContent(contentData),
          icon: 'preview',
          suppressMenu: true,
          tooltip: this.translateService.instant('preview'),
      }),
      this.allowAddOrEditContent
          ? genIconButtonColumn({
              callback: (contentData: Content) =>
                  this.deleteContent.emit(contentData.id),
              icon: 'delete',
              suppressMenu: true,
              tooltip: this.translateService.instant('deleteContent'),
          })
          : undefined,
      {
        field: 'language.name',
        headerName: this.translateService.instant('language'),
        suppressMenu: true,
        resizable: true,
      },
      genIconButtonColumn({
          callback: (contentData: Content) => this.downloadPdf(contentData),
          icon: 'attach_file',
          suppressMenu: true,
          hidden: (contentData: Content) => contentData.attachments.length == 0,
          tooltip: this.translateService.instant('download'),
      }),
      {
        valueGetter: (params) => fieldValue(params.data, Field.TypeEnum.TITLE),
        headerName: this.translateService.instant('title'),
        suppressMenu: true,
        resizable: true,
        cellStyle: { color: environment.PRIMARY_COLOR, cursor: 'pointer' },
      },
      {
        valueGetter: (params) => fieldValue(params.data, Field.TypeEnum.ABSTRACT),
        headerName: this.translateService.instant('storyAbstract'),
        suppressMenu: true,
        resizable: true,
        maxWidth: 600,
      },
      {
        valueGetter: (params) => fieldValue(params.data, Field.TypeEnum.DESCRIPTION),
        headerName: this.translateService.instant('description'),
        suppressMenu: true,
        resizable: true,
        maxWidth: 600,
      },
      {
        valueGetter: (params) => fieldValue(params.data, Field.TypeEnum.INVESTMENTCASE),
        headerName: this.translateService.instant('investmentCase'),
        suppressMenu: true,
        resizable: true,
        maxWidth: 600,
      },
    ].filter((d) => d); // remove null or undefined
    return cols;
  }

  downloadPdf(content: Content) {
    this.documentTransferService.downloadContentAttachment(
      content,
      this.modalService,
      this.documentTransferService
    );
  }

  handleToggleAutoSyncContent(event: any) {
    const target = this.campaign || this.story;
    event.source.checked = target.autoSyncContent;
    this.toggleAutoSync.emit();
  }
}
