import {Component, Input, OnInit} from '@angular/core';
import {Employee, Intermediary, IntermediaryService} from '../../../../api/core';
import {TranslateService} from '@ngx-translate/core';
import {GlobalService} from '../../../../services/global.service';
import {ColDef, GridApi, GridReadyEvent, RowDoubleClickedEvent} from 'ag-grid-community';
import {GridOptions} from 'ag-grid-enterprise';
import {EViewRoutes} from '../../../../util/enum';
import {genBooleanColumn, genCodeTableColumn, genTextColumn} from '../../../../util/grid/grid-renderer.util';
import {genLinkColumn} from "../../../../shared/grid/cell-renderers/link.renderer";
import {GridResetEvent} from "../../../../shared/grid/grid.component";
import {PermissionService} from "../../../../services/permission.service";
import {EProtectedActions} from "../../../../util/protected-actions";

@Component({
  selector: 'app-intermediary-overview-employees',
  templateUrl: './intermediary-overview-employees.component.html',
})
export class IntermediaryOverviewEmployeesComponent implements OnInit {
  @Input() intermediary: Intermediary;

  @Input() fromDistributor = false;

  employees: Employee[] = [];

  colDefs: ColDef[] = [];
  getColDefs(isCidFilterAllowed: boolean): ColDef[] {
    let distributorFields = ['name', 'preferredLanguage', 'preferredChannelType'];
    let cols = [
      {
        ...genLinkColumn({
          field: 'name',
          headerName: this.fromDistributor ?
            this.translateService.instant('employeeName') : this.translateService.instant('name'),
          link: (data: any) => `${this.employeeOverviewRoute}${data.id}`,
        }),
        sortable: isCidFilterAllowed,
        floatingFilter: isCidFilterAllowed,
      },
      {
        ...genTextColumn('firstName', this.translateService.instant('firstName')),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed,
      },
      {
        ...genTextColumn('lastName', this.translateService.instant('lastName')),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed,
      },
      genCodeTableColumn({
        field: 'gender',
        headerName: this.translateService.instant('gender'),
      }),
      genTextColumn('externalKey', this.translateService.instant('key')),
      genCodeTableColumn({
        field: 'preferredLanguage',
        headerName: this.translateService.instant('preferredLanguage'),
      }),
      genCodeTableColumn({
        field: 'preferredChannelType',
        headerName: this.translateService.instant('channel'),
      }),
      {
        ...genBooleanColumn(
          'closed',
          this.translateService.instant('CLOSED'),
          this.translateService
        ), // needed because cellType inferred before transformation to text
        cellDataType: 'text',
      }
    ];
    if (this.fromDistributor) {
      cols = cols.filter((col) => distributorFields.includes(col.field));
    }
    return cols;
  };

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.globalService.navigate(`${this.employeeOverviewRoute}${event.data.id}`),
    onGridReady: (event: GridReadyEvent) => {
      this.gridReady(event);
    }
  };

  employeeOverviewRoute: EViewRoutes;

  constructor(
    private translateService: TranslateService,
    private globalService: GlobalService,
    private intermediaryService: IntermediaryService,
    private permissionService: PermissionService,
  ) {}

  ngOnInit(): void {
    this.employeeOverviewRoute = this.fromDistributor ?
      EViewRoutes.distributorEmployeeOverview : EViewRoutes.employeeOverview;
    const isCidFilterAllowed = this.permissionService.hasAnyPermission(EProtectedActions.sortAndFilterCid);
    this.colDefs = this.getColDefs(isCidFilterAllowed);
    this.intermediaryService.getIntermediaryEmployees(this.intermediary.id).subscribe({
      next: (rows: Employee[]) => {
        this.employees = rows;
      },
    });
  }

  gridReady(event: GridReadyEvent): void {
    this.gridFilterReset({ api: event.api });
  }

  gridFilterReset(event: GridResetEvent) {
    this.applyDefaultFilters(event.api);
  }

  private applyDefaultFilters(api: GridApi) {
    const closedFilter = api.getFilterInstance('closed');
    if (closedFilter != undefined && !closedFilter.getModel()) {
      closedFilter.setModel({ values: ['false'] });
      api.onFilterChanged();
    }
  }
}
