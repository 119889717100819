/**
 * aSpark core API
 * The REST API of aSpark\'s core service 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Language } from './language';
import { CodeTableEntry } from './codeTableEntry';


export interface Salutation { 
    id?: number;
    salutationType?: Salutation.SalutationTypeEnum;
    language: Language;
    channelType?: CodeTableEntry;
    value: string;
    isDefault: boolean;
}
export namespace Salutation {
    export type SalutationTypeEnum = 'INTRO' | 'OUTRO';
    export const SalutationTypeEnum = {
        INTRO: 'INTRO' as SalutationTypeEnum,
        OUTRO: 'OUTRO' as SalutationTypeEnum
    };
}


