/**
 * aSpark core API
 * The REST API of aSpark\'s core service 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CampaignIntermediaryActionOperation { 
    operation: CampaignIntermediaryActionOperation.OperationEnum;
    items: Array<number>;
}
export namespace CampaignIntermediaryActionOperation {
    export type OperationEnum = 'EXECUTE' | 'REVERT' | 'NO_ACTION' | 'DELETE';
    export const OperationEnum = {
        EXECUTE: 'EXECUTE' as OperationEnum,
        REVERT: 'REVERT' as OperationEnum,
        NOACTION: 'NO_ACTION' as OperationEnum,
        DELETE: 'DELETE' as OperationEnum
    };
}


