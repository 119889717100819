import { Component, HostBinding } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ColDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-enterprise';
import { SuitabilityState } from 'src/app/api/core';
import { TranslateService } from '@ngx-translate/core';
import {
  commonFilterParams,
  commonParams,
} from 'src/app/util/grid/grid-renderer.util';
import {
  GridStateEnum,
  GridStateInfo,
  GridSuitabilityIconAndColorClass,
  GridSuitabilityInfo,
} from 'src/app/models/grid.model';

@Component({
  template: `
    <app-config-icon
      [iconIdentifier]="this.icon.icon"
      [tooltip]="tooltip"
      [ngClass]="this.icon.colorClass"
      style="{{ callback ? 'cursor: pointer;' : ''}}"
      (click)="callback && callback(data)"
    ></app-config-icon>
  `,
})
export class SuitabilityRenderer implements AgRendererComponent {
  @HostBinding('class.grid__icon-button') theme = true;
  data: GridStateInfo;
  icon: GridSuitabilityIconAndColorClass;
  tooltip: string;
  callback: (data: any) => void;

  constructor(protected translateService: TranslateService) {}

  agInit(params: ICellRendererParams): void {
    const info: GridSuitabilityInfo =
      params.colDef.cellRendererParams.actionsParams.suitabilityInfo;
    this.data = info.stateInfo(params.data);

    this.icon = getIconAndColor(this.data.state);

    this.tooltip = this.translateService.instant(this.data.state.toLowerCase());

    if (info) {
      this.callback = info.callback;
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}

@Component({
  template: `
    <div class="filter-item">
      <app-config-icon
        *ngIf="this.icon"
        [iconIdentifier]="this.icon.icon"
        [ngClass]="this.icon.colorClass"
      ></app-config-icon>
      <span>{{ tooltip }}</span>
    </div>
  `,
})
export class SuitabilityFilterCellRenderer implements AgRendererComponent {
  @HostBinding('class.grid__icon-button') theme = true;
  value: GridStateEnum;
  icon: GridSuitabilityIconAndColorClass;
  tooltip: string;

  constructor(protected translateService: TranslateService) {}

  agInit(params: ICellRendererParams): void {
    this.value = params.value;

    this.icon = getIconAndColor(this.value);

    this.tooltip =
      this.icon === null
        ? this.value
        : this.translateService.instant(this.value.toLowerCase());
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}

export function genSuitabilityColumn(info: GridSuitabilityInfo): ColDef {
  return {
    ...commonParams(info.field, info?.headerName),
    cellRenderer: SuitabilityRenderer,
    sortable: true,
    filter: 'agSetColumnFilter',
    filterParams: {
      ...commonFilterParams(info.filterParamsInfo),
      values: [
        SuitabilityState.OK,
        SuitabilityState.CONFIRM,
        SuitabilityState.RESTRICTED,
        SuitabilityState.NOTOK,
        SuitabilityState.ERROR,
        SuitabilityState.UNKNOWN,
        SuitabilityState.PENDING,
      ],
      suppressSorting: true,
      cellRenderer: SuitabilityFilterCellRenderer,
      valueFormatter: (params: ValueFormatterParams) => info.translateService.instant(params.value.toLowerCase()),
    },
    cellRendererParams: {
      actionsParams: {
        suitabilityInfo: info,
      },
    },
    width: 100,
  };
}

export function getIconAndColor(
  state: GridStateEnum
): GridSuitabilityIconAndColorClass {
  let icon = '';
  let colorClass = '';
  switch (state) {
    case SuitabilityState.OK:
      icon = 'check_circle_outline';
      colorClass = 'suitability-green';
      break;
    case SuitabilityState.CONFIRM:
      icon = 'warning';
      colorClass = 'suitability-green';
      break;
    case SuitabilityState.RESTRICTED:
      icon = 'warning';
      colorClass = 'suitability-warn';
      break;
    case SuitabilityState.NOTOK:
      icon = 'error';
      colorClass = 'suitability-danger';
      break;
    case SuitabilityState.ERROR:
      icon = 'gpp_bad';
      colorClass = 'suitability-danger';
      break;
    case SuitabilityState.UNKNOWN:
      icon = 'help_outline';
      colorClass = 'suitability-unknown';
      break;
    case SuitabilityState.PENDING:
      icon = 'hourglass_empty';
      colorClass = 'suitability-pending';
      break;
    default:
      return null;
  }
  return { icon, colorClass };
}
