import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {GridOptions} from 'ag-grid-enterprise';
import {Salutation, SalutationService} from '../../../../api/core';
import {ModalData} from '../../../../models/modal.model';
import {CodeTableService} from '../../../../services/code-table.service';
import {GlobalService} from '../../../../services/global.service';
import {ModalService} from '../../../../services/modal.service';
import {NotificationService} from '../../../../services/notification.service';
import {genIconButtonColumn} from '../../../../shared/grid/cell-renderers/icon-button.renderer';
import {ModalComponent} from '../../../../shared/modal/modal.component';
import {EModalType} from '../../../../util/enum';
import {genCodeTableColumn, genTextColumn} from '../../../../util/grid/grid-renderer.util';
import {SalutationDetailsComponent} from './salutation-details/salutation-details.component';
import {SalutationPreviewComponent} from './salutation-preview/salutation-preview.component';
import SalutationTypeEnum = Salutation.SalutationTypeEnum;

@Component({
  selector: 'app-salutations',
  templateUrl: './salutations.component.html',
})
export class SalutationsComponent {
  intros: Salutation[] = [];
  greetings: Salutation[] = [];

  introColumns = [
    {
      ...genCodeTableColumn({
        field: 'language',
        headerName: this.translateService.instant('language')
      }),
    },
    {
      ...genCodeTableColumn({
        field: 'channelType',
        headerName: this.translateService.instant('channel')
      }),
    },
    {
      ...genTextColumn('value', this.translateService.instant('text')),
    },
    genIconButtonColumn({
      callback: (data: Salutation) => this.onUpdate(data),
      icon: 'edit_m',
      suppressMenu: true,
      tooltip: this.translateService.instant('edit'),
    }),
    genIconButtonColumn({
      callback: (data: Salutation) => this.onPreview(data),
      icon: 'preview',
      suppressMenu: true,
      tooltip: this.translateService.instant('preview'),
    }),
    genIconButtonColumn({
      hidden: (data: Salutation) => data.isDefault,
      callback: (data: Salutation) => this.delete(data),
      icon: 'delete',
      suppressMenu: true,
      tooltip: this.translateService.instant('delete'),
    }),
  ];

  greetingColumns = [
    {
      ...genCodeTableColumn({
        field: 'language',
        headerName: this.translateService.instant('language')
      }),
    },
    {
      ...genCodeTableColumn({
        field: 'channelType',
        headerName: this.translateService.instant('channel')
      }),
    },
    {
      ...genTextColumn('value', this.translateService.instant('text')),
    },
    genIconButtonColumn({
      callback: (data: Salutation) => this.onUpdate(data),
      icon: 'edit_m',
      suppressMenu: true,
      tooltip: this.translateService.instant('edit'),
    }),
    genIconButtonColumn({
      callback: (data: Salutation) => this.onPreview(data),
      icon: 'preview',
      suppressMenu: true,
      tooltip: this.translateService.instant('preview'),
    }),
    genIconButtonColumn({
      hidden: (data: Salutation) => data.isDefault,
      callback: (data: Salutation) => this.delete(data),
      icon: 'delete',
      suppressMenu: true,
      tooltip: this.translateService.instant('delete'),
    }),
  ];

  introsGridOptions: GridOptions = {
    rowHeight: 32,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
  };

  greetingsGridOptions: GridOptions = {
    rowHeight: 32,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
  };

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly globalService: GlobalService,
    protected readonly salutationService: SalutationService,
    protected readonly codeTableService: CodeTableService,
    protected readonly notificationService: NotificationService,
    protected readonly modalService: ModalService
  ) {
    this.updateData();
  }

  private updateData() {
    this.salutationService.findAll().subscribe((salutations) => {
      this.intros = salutations.filter((s) => s.salutationType === 'INTRO');
      this.greetings = salutations.filter((s) => s.salutationType === 'OUTRO');
    });
  }

  onAdd(salutationType: SalutationTypeEnum) {
    const type =
      salutationType === SalutationTypeEnum.INTRO
        ? EModalType.createIntro
        : EModalType.createOutro;
    const modalData: ModalData = {
      type,
      title: type,
      submitBtn: {
        label: this.translateService.instant('create'),
      },
      data: {
        salutation: {
          salutationType,
          value: '',
          isDefault: false,
        } as Salutation,
        list: this.intros.concat(this.greetings),
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: SalutationDetailsComponent,
    };
    const ref = this.modalService.openDefaultDialog(modalData);
    ref.afterClosed().subscribe(() => this.updateData());
  }

  onUpdate(salutation: Salutation) {
    const salutationType = salutation.salutationType;
    const type =
      salutationType === SalutationTypeEnum.INTRO
        ? EModalType.editIntro
        : EModalType.editOutro;
    const modalData: ModalData = {
      type,
      title: type,
      submitBtn: {
        label: this.translateService.instant('update'),
      },
      data: { salutation, list: this.intros.concat(this.greetings) },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: SalutationDetailsComponent,
    };
    const ref = this.modalService.openDefaultDialog(modalData);
    ref.afterClosed().subscribe(() => this.updateData());
  }

  private delete(salutation: Salutation) {
    const title = this.translateService.instant(
      salutation.salutationType === 'INTRO' ? 'deleteIntro?' : 'deleteOutro?'
    );
    const message = this.translateService.instant(
      salutation.salutationType === 'INTRO'
        ? 'deleteIntroDialogMessage?'
        : 'deleteOutroDialogMessage?'
    );
    const modalData: ModalData = {
      title,
      type: EModalType.confirmationDialog,
      data: {
        message,
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('delete'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.salutationService.deleteById(salutation.id).subscribe({
            next: () => {
              this.updateData();
              modalRef.close(true);
              this.notificationService.handleSuccess(
                this.translateService.instant('deleteSalutationSuccess')
              );
            },
          });
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    };
    this.modalService.openConfirmationDialog(modalData);
  }

  private onPreview(salutation: Salutation) {
    const modalData: ModalData = {
      title: EModalType.salutationPreview,
      type: EModalType.salutationPreview,
      data: { salutation },
      component: SalutationPreviewComponent,
      submitBtn: {
        label: this.translateService.instant('close'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          modalRef.close(true);
        },
      },
      cancelBtn: null,
    };
    this.modalService.openDefaultDialog(modalData);
  }
}
