<form [formGroup]="contentForm" class="modal-form">
  <!-- Two separate sections for normal and custom content since it's become complex enough-->
  <!-- Normal content -->
  <ng-container *ngIf="!isCustomContent">
    <div class="row row-single" *ngIf="guiConfig.content.title">
      <mat-form-field>
        <mat-label>{{ 'title' | translate }}</mat-label>
        <input matInput formControlName="title" type="text" />
        <mat-error *ngIf="!contentForm.controls.title.valid">{{
          'inputTooLong'
            | translate : { maxLength: formValidators.textFieldMaxLength }
        }}</mat-error>
      </mat-form-field>
    </div>
    <div class="row row-single">
      <mat-form-field *ngIf="guiConfig.content.abstract" class="txt-3">
        <mat-label>{{ 'campaignAbstract' | translate }}</mat-label>
        <textarea
          matInput
          formControlName="contentAbstract"
          type="text"
        ></textarea>
      </mat-form-field>
      <mat-form-field *ngIf="guiConfig.content.description" class="txt-4">
        <mat-label>{{ 'description' | translate }}</mat-label>
        <textarea matInput formControlName="description" type="text"></textarea>
      </mat-form-field>
    </div>
    <div class="row row-single" *ngIf="guiConfig.content.investmentCase">
      <mat-form-field class="txt-5">
        <mat-label>{{ 'investmentCase' | translate }}</mat-label>
        <textarea
          matInput
          formControlName="investmentCase"
          type="text"
        ></textarea>
      </mat-form-field>
    </div>
    <ng-container *ngIf="guiConfig.content.url">
      <div class="row row-double">
        <mat-form-field>
          <mat-label>{{ 'url' | translate }}</mat-label>
          <input matInput formControlName="url" type="text" />
          <mat-error *ngIf="!contentForm.controls.url.valid">{{
            'inputTooLong'
              | translate : { maxLength: formValidators.textFieldMaxLength }
          }}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'urlDescription' | translate }}</mat-label>
          <input matInput formControlName="urlDescription" type="text" />
          <mat-error *ngIf="!contentForm.controls.urlDescription.valid">{{
            'inputTooLong'
              | translate : { maxLength: formValidators.textFieldMaxLength }
          }}</mat-error>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container *ngIf="guiConfig.content.media">
      <div class="row row-double">
        <mat-form-field>
          <mat-label>{{ 'media' | translate }}</mat-label>
          <input matInput formControlName="media" type="text" />
          <mat-error *ngIf="!contentForm.controls.media.valid">{{
            'inputTooLong'
              | translate : { maxLength: formValidators.textFieldMaxLength }
          }}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'mediaDescription' | translate }}</mat-label>
          <input matInput formControlName="mediaDescription" type="text" />
          <mat-error *ngIf="!contentForm.controls.mediaDescription.valid">{{
            'inputTooLong'
              | translate : { maxLength: formValidators.textFieldMaxLength }
          }}</mat-error>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container *ngIf="guiConfig.content.outro">
      <div class="row row-single" *ngIf="!isRichText(Field.TypeEnum.OUTRO)">
        <mat-form-field>
          <textarea matInput formControlName="outro" type="text"></textarea>
        </mat-form-field>
      </div>
      <div class="row row-single rich-text-section" *ngIf="isRichText(Field.TypeEnum.OUTRO)">
        <mat-label>{{ 'outro' | translate }}</mat-label>
        <textarea [froalaEditor]="editorOptions" formControlName="outro"></textarea>
      </div>
    </ng-container>
    <div class="row row-single" *ngIf="guiConfig.content.disclaimer">
      <mat-form-field class="txt-2">
        <mat-label>{{ 'disclaimer' | translate }}</mat-label>
        <textarea
          matInput
          formControlName="disclaimer"
          type="text"
        ></textarea>
      </mat-form-field>
    </div>
    <div class="row row-double" *ngIf="guiConfig.content.attachments">
      <app-content-attachments
        [content]="content"
        [attachments]="attachments"
        [isEditable]="isEditable">
      </app-content-attachments>
    </div>
  </ng-container>
  <!-- Custom content -->
  <ng-container *ngIf="isCustomContent">
    <div class="row row-single" *ngIf="guiConfig.content.customTitle">
      <mat-form-field>
        <mat-label>{{ 'title' | translate }}</mat-label>
        <input matInput formControlName="title" type="text" />
      </mat-form-field>
    </div>
    <ng-container *ngIf="guiConfig.content.customIntro">
      <div class="row row-single" *ngIf="!isRichText(Field.TypeEnum.INTRO)">
        <mat-form-field>
          <textarea matInput formControlName="intro" type="text"></textarea>
        </mat-form-field>
      </div>
      <div class="row row-single rich-text-section" *ngIf="isRichText(Field.TypeEnum.INTRO)">
        <mat-label>{{ 'intro' | translate }}</mat-label>
        <textarea [froalaEditor]="editorOptions" formControlName="intro"></textarea>
      </div>
    </ng-container>
    <div class="row row-single">
      <mat-form-field *ngIf="guiConfig.content.customAbstract" class="txt-2">
        <mat-label>{{ 'campaignAbstract' | translate }}</mat-label>
        <textarea
          matInput
          formControlName="contentAbstract"
          type="text"
        ></textarea>
      </mat-form-field>
      <mat-form-field *ngIf="guiConfig.content.customDescription" class="txt-3">
        <mat-label>{{ 'description' | translate }}</mat-label>
        <textarea matInput formControlName="description" type="text"></textarea>
      </mat-form-field>
    </div>
    <div class="row row-single" *ngIf="guiConfig.content.customInvestmentCase">
      <mat-form-field class="txt-4">
        <mat-label>{{ 'investmentCase' | translate }}</mat-label>
        <textarea
          matInput
          formControlName="investmentCase"
          type="text"
        ></textarea>
      </mat-form-field>
    </div>
    <ng-container *ngIf="guiConfig.content.customUrl">
      <div class="row row-double">
        <mat-form-field>
          <mat-label>{{ 'url' | translate }}</mat-label>
          <input matInput formControlName="url" type="text" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'urlDescription' | translate }}</mat-label>
          <input matInput formControlName="urlDescription" type="text" />
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container *ngIf="guiConfig.content.customMedia">
      <div class="row row-double">
        <mat-form-field>
          <mat-label>{{ 'media' | translate }}</mat-label>
          <input matInput formControlName="media" type="text" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'mediaDescription' | translate }}</mat-label>
          <input matInput formControlName="mediaDescription" type="text" />
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container *ngIf="guiConfig.content.customOutro">
      <div class="row row-single" *ngIf="!isRichText(Field.TypeEnum.OUTRO)">
        <mat-form-field>
          <textarea matInput formControlName="outro" type="text"></textarea>
        </mat-form-field>
      </div>
      <div class="row row-single rich-text-section" *ngIf="isRichText(Field.TypeEnum.OUTRO)">
        <mat-label>{{ 'outro' | translate }}</mat-label>
        <textarea [froalaEditor]="editorOptions" formControlName="outro"></textarea>
      </div>
    </ng-container>
    <div class="row row-single" *ngIf="guiConfig.content.customDisclaimer">
      <mat-form-field class="txt-2">
        <mat-label>{{ 'disclaimer' | translate }}</mat-label>
        <textarea
          matInput
          formControlName="disclaimer"
          type="text"
        ></textarea>
      </mat-form-field>
    </div>
    <ng-container *ngIf="guiConfig.content.customAttachments">
      <div class="row row-double">
        <app-content-attachments
          [content]="content"
          [attachments]="attachments"
          [isEditable]="guiConfig.content.customEditAttachments">
        </app-content-attachments>
      </div>
    </ng-container>
  </ng-container>
</form>
