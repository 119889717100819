/**
 * aSpark core API
 * The REST API of aSpark\'s core service 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PortfolioMovement { 
    id: number;
    portfolioIdent: string;
    portfolioNumber: string;
    productIdent: string;
    productName: string;
    tradingType: PortfolioMovement.TradingTypeEnum;
    tradingVolume: number;
    launchedAt: string;
}
export namespace PortfolioMovement {
    export type TradingTypeEnum = 'BUY' | 'SELL';
    export const TradingTypeEnum = {
        BUY: 'BUY' as TradingTypeEnum,
        SELL: 'SELL' as TradingTypeEnum
    };
}


