/**
 * aSpark core API
 * The REST API of aSpark\'s core service 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CampaignInfoExtended { 
    id: number;
    ident: string;
    name: string;
    storyName?: string;
    createdAt?: string;
    validFrom?: string;
    validTo: string;
    status: CampaignInfoExtended.StatusEnum;
    processing?: boolean;
}
export namespace CampaignInfoExtended {
    export type StatusEnum = 'DRAFT' | 'LAUNCHED' | 'FROZEN' | 'CLOSED' | 'TERMINATED';
    export const StatusEnum = {
        DRAFT: 'DRAFT' as StatusEnum,
        LAUNCHED: 'LAUNCHED' as StatusEnum,
        FROZEN: 'FROZEN' as StatusEnum,
        CLOSED: 'CLOSED' as StatusEnum,
        TERMINATED: 'TERMINATED' as StatusEnum
    };
}


