import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {GridApi, GridReadyEvent} from 'ag-grid-community';
import {ColDef, GridOptions} from 'ag-grid-enterprise';
import {finalize, first, Observable} from 'rxjs';
import {
  CampaignActionService,
  CampaignIntermediaryActionService,
  SenderService,
  User,
  UserInfo,
} from 'src/app/api/core';
import {ModalSubComponent} from 'src/app/models/modal.model';
import {ModalComponent} from 'src/app/shared/modal/modal.component';
import {EModalType} from 'src/app/util/enum';
import {ActionType} from "../campaign-actions-list-utils";

/**
 * What this component expects as input.
 */
export interface SenderAction {
  type: ActionType;
  sender: UserInfo;
  id: number;
  portfolioIdent: string;
}

@Component({
  selector: 'app-campaign-action-sender-selection',
  templateUrl: './campaign-action-sender-selection.component.html',
})
export class CampaignActionSenderSelectionComponent
  implements OnInit, ModalSubComponent {
  showWarning = false;
  loading = true;

  action: SenderAction;
  rowData: User[];
  gridApi: GridApi;

  columns: ColDef[] = [
    {
      checkboxSelection: true,
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      lockVisible: true,
      lockPosition: true,
    },
    {
      field: 'username',
      headerName: this.translateService.instant('user'),
      suppressMenu: true,
      sortable: true,
    },
    {
      field: 'fullname',
      headerName: this.translateService.instant('fullName'),
      suppressMenu: true,
      sortable: true,
    },
    {
      field: 'department',
      headerName: this.translateService.instant('department'),
      suppressMenu: true,
      sortable: true,
    },
  ];

  gridOptions: GridOptions = {
    rowHeight: 36,
    floatingFiltersHeight: 0,
    suppressContextMenu: true,
    suppressCellFocus: true,
    rowSelection: 'single',
    paginationAutoPageSize: true,
    onGridReady: (event) => this.gridReady(event),
  };

  constructor(
    private senderService: SenderService,
    private campaignActionService: CampaignActionService,
    private campaignIntermediaryActionService: CampaignIntermediaryActionService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.action = data.data.action;
  }

  ngOnInit(): void {
    this.getSenders();
  }

  private getSenders() {
    let serviceCall: Observable<User[]>;
    if (this.action.type === ActionType.CampaignAction) {
      serviceCall = this.senderService
        .getAvailableSenders(this.action.portfolioIdent)
    } else {
      serviceCall = this.campaignIntermediaryActionService
        .availableSenders(this.action.id)
    }
    return serviceCall.pipe(
      first(),
      finalize(() => (this.loading = false))
    ).subscribe({
      next: (data) => (this.rowData = data),
    });
  }

  modalAction(modalType: EModalType): void {
    let serviceCall: Observable<any>;
    const selected: User = this.gridApi.getSelectedNodes()[0]?.data;
    if (this.action.type === ActionType.CampaignAction) {
      serviceCall = this.campaignActionService
        .updateCampaignActionSender(
          this.action.id,
          selected.username
        )
    } else {
      serviceCall = this.campaignIntermediaryActionService
        .updateAction(this.action.id, {id: this.action.id, sender: selected.username})
    }
    switch (modalType) {
      case EModalType.editCampaignSender:
        if (!selected) {
          this.showWarning = true;
        } else {
          this.showWarning = false;
          if (!this.isCurrentValue(selected)) {
            serviceCall.subscribe({
              next: () => this.dialogRef.close(true),
            });
          } else {
            this.dialogRef.close(true);
          }
        }
        this.dialogRef.componentInstance.resetToolbarActionButtons();
        break;
      default:
        break;
    }
  }

  private gridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    event.api.forEachNode((node) =>
      this.isCurrentValue(node.data) ? node.setSelected(true) : 0
    );
  }

  private isCurrentValue(node: User): boolean {
    return this.action.sender.username === node.username;
  }
}
