import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../../../services/global.service';
import {
  Gender,
  TemplateDefault,
  TemplateDefaultService,
} from '../../../../api/core';
import { GridOptions } from 'ag-grid-enterprise';
import { genTextColumn } from '../../../../util/grid/grid-renderer.util';
import { CodeTableService } from '../../../../services/code-table.service';
import { genIconButtonColumn } from '../../../../shared/grid/cell-renderers/icon-button.renderer';
import { DialogWidth, ModalService } from '../../../../services/modal.service';
import { NotificationService } from '../../../../services/notification.service';
import { ModalData } from '../../../../models/modal.model';
import { ECodeTables, EModalType } from '../../../../util/enum';
import { TemplateDefaultDetailsComponent } from './template-default-details/template-default-details.component';

@Component({
  selector: 'app-template-defaults',
  templateUrl: './template-defaults.component.html',
})
export class TemplateDefaultsComponent {
  data: TemplateDefault[] = [];
  genders: Gender[] = [];

  columns = [
    genTextColumn('name', this.translateService.instant('name')),
    genTextColumn('value', this.translateService.instant('value')),
    genIconButtonColumn({
      callback: (data: TemplateDefault) => this.onUpdate(data),
      icon: 'edit_m',
      suppressMenu: true,
      tooltip: this.translateService.instant('edit'),
    }),
  ];

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
  };

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly globalService: GlobalService,
    protected readonly templateDefaultService: TemplateDefaultService,
    protected readonly codeTableService: CodeTableService,
    protected readonly notificationService: NotificationService,
    protected readonly modalService: ModalService
  ) {
    this.codeTableService.getCodeTable(ECodeTables.gender).subscribe((d) => {
      this.genders = d;
      this.updateData();
    });
  }

  private updateData() {
    this.templateDefaultService.findAll().subscribe((data) => {
      this.data = data;
    });
  }

  onUpdate(templateDefault: TemplateDefault) {
    const modalData: ModalData = {
      type: EModalType.editTemplateDefault,
      title: EModalType.editTemplateDefault,
      submitBtn: {
        label: this.translateService.instant('update'),
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      data: { templateDefault, genders: this.genders },
      component: TemplateDefaultDetailsComponent,
    };
    const ref = this.modalService.openDefaultDialog(modalData);
    ref.afterClosed().subscribe(() => this.updateData());
  }
}
