import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  Salutation,
  SalutationPreview,
  SalutationService,
} from '../../../../../api/core';
import { ModalComponent } from '../../../../../shared/modal/modal.component';

@Component({
  selector: 'app-salutation-preview',
  templateUrl: './salutation-preview.component.html',
})
export class SalutationPreviewComponent {
  salutation: Salutation;
  previewStr: string;

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly salutationService: SalutationService,
    protected dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { data: { salutation: Salutation } }
  ) {
    this.salutation = data.data.salutation;
    const request = { value: this.salutation.value } as SalutationPreview;
    this.salutationService.generatePreview(request).subscribe((preview) => {
      this.previewStr = preview.preview;
    });
  }
}
