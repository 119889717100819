import { Component, HostBinding } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ColDef, ICellRendererParams } from 'ag-grid-enterprise';
import { GridActionLanguageInfo } from 'src/app/models/grid.model';
import {
  commonFilterParams,
  commonParams,
} from 'src/app/util/grid/grid-renderer.util';
import {CampaignAction, CampaignActionStatus} from 'src/app/api/core';

@Component({
  template: `
    <div class="grid-custom-cell">
      <app-config-icon
        iconIdentifier="edit_m"
        class="edit-cell-icon"
      ></app-config-icon>
      <span *ngIf="!(hidden | isRendererHidden: data)">{{ language }}</span>
      <app-config-icon
        *ngIf="showWarning && !(hidden | isRendererHidden: data)"
        iconIdentifier="warning"
        [tooltip]="tooltip | translate"
      ></app-config-icon>
    </div>
  `,
})
export class ActionLanguageRenderer implements AgRendererComponent {
  @HostBinding('class.grid-cell-action-language') theme = true;
  data: CampaignAction;
  language: string;
  showWarning: boolean;
  tooltip = 'noLanguageWarning';
  hidden: (data: any) => boolean;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.data = params.data;

    this.language = this.data.language.name;

    const info: GridActionLanguageInfo =
      params?.colDef?.cellRendererParams?.actionsParams?.actionLanguageInfo;
    if (info) {
      this.hidden = info?.hidden;
      if (this.data.status === CampaignActionStatus.PENDING) {
        const languageIndex = info.availableLanguages.findIndex(
          (language) => language.id === this.data.language.id
        );
        if (languageIndex === -1) {
          this.showWarning = true;
        } else {
          this.showWarning = false;
        }
      } else {
        this.showWarning = false;
      }
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}

export function genActionLanguageColumn(info: GridActionLanguageInfo): ColDef {
  return {
    ...commonParams(info.field, info?.headerName),
    cellRenderer: ActionLanguageRenderer,
    sortable: false,
    filter: info?.customFilter,
    filterParams: {
      ...commonFilterParams(info.filterParamsInfo),
      filterOptions: [
        'contains',
        'notContains',
        'equals',
        'notEqual',
        'startsWith',
        'endsWith',
      ],
    },
    cellRendererParams: {
      actionsParams: {
        actionLanguageInfo: info,
      },
    },
  };
}
