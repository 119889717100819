import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  Gender,
  TemplateDefault,
  TemplateDefaultService,
} from '../../../../../api/core';
import { ModalSubComponent } from '../../../../../models/modal.model';
import { GlobalService } from '../../../../../services/global.service';
import { NotificationService } from '../../../../../services/notification.service';
import { ModalComponent } from '../../../../../shared/modal/modal.component';
import { EModalType } from '../../../../../util/enum';

@Component({
  selector: 'app-template-default-details',
  templateUrl: './template-default-details.component.html',
})
export class TemplateDefaultDetailsComponent implements ModalSubComponent {
  isGenderEntry = false;
  templateDefault: TemplateDefault;
  genders: Gender[] = [];

  form = this.fb.group({
    name: new FormControl({ value: '', disabled: true }),
    value: [''],
    gender: [null as Gender],
  });

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly globalService: GlobalService,
    protected readonly templateDefaultService: TemplateDefaultService,
    protected readonly notificationService: NotificationService,
    protected readonly fb: FormBuilder,
    protected dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      data: { templateDefault: TemplateDefault; genders: Gender[] };
    }
  ) {
    this.genders = data.data.genders;
    this.handle(data.data.templateDefault);
  }

  modalAction(modalType: EModalType | undefined): void {
    let newValue = this.form.get('value').value || '';
    if (this.isGenderEntry) {
      newValue = this.form.get('gender').value?.ident || '';
    }
    const toSave = {
      ...this.templateDefault,
      value: newValue,
    } as TemplateDefault;
    switch (modalType) {
      case EModalType.editTemplateDefault:
        this.templateDefaultService
          .updateById(toSave.id, toSave)
          .subscribe(() => {
            this.notificationService.handleSuccess(
              this.translateService.instant('updateTemplateDefaultSuccess')
            );
            this.dialogRef.close();
          });
        break;
    }
  }

  private handle(templateDefault: TemplateDefault) {
    this.templateDefault = templateDefault;
    this.isGenderEntry = templateDefault.name.includes('gender');
    this.form.patchValue({
      name: templateDefault.name,
      value: templateDefault.value,
      gender: this.genders.find((g) => g.ident === templateDefault.value),
    });
  }
}
