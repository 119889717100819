import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

/**
 * Card Component.
 * Empty component that serves purely as a wrapper and structure element with specific styling
 */
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
})
export class CardComponent implements OnChanges {
  /**
   * Whether or not to show the spinner
   */
  @Input() showSpinner = false;
  /**
   * Used for update flash of card
   */
  @Input() updated = false;
  /**
   * Used for two-way-binding of updated variable
   */
  @Output() updatedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * Whether or not to show header
   */
  @Input() showHeader = true;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.updated) {
      setTimeout(() => {
        this.updated = false;
        this.updatedChange.emit(false);
      }, 1000);
    }
  }
}
