import {Component, OnDestroy, OnInit} from '@angular/core';
import {ColDef, GridOptions} from 'ag-grid-enterprise';
import {genBooleanColumn, genCodeTableColumn, genTextColumn} from '../../../util/grid/grid-renderer.util';
import {I18n} from '../../../services/i18n.service';
import {TranslateService} from '@ngx-translate/core';
import {GlobalService} from '../../../services/global.service';
import {LicenseService, User, UserService} from '../../../api/core';
import {combineLatest, Subscription} from 'rxjs';
import {AgPromise, GridApi, GridReadyEvent} from 'ag-grid-community';
import {GridResetEvent} from "../../../shared/grid/grid.component";
import {Tab} from "../../../models/tabs.model";
import {SETTINGS_TABS} from "../../../util/tab.constants";
import {CodeTableService} from "../../../services/code-table.service";

interface StatsEntry {
  label: string;
  value: string | number;
}

const DEFAULT_TAB = 0;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit, OnDestroy {


  tabs: Tab[] = SETTINGS_TABS;

  defaultTab: string = this.tabs[DEFAULT_TAB].text;
  activeTab: string = this.tabs[DEFAULT_TAB].text;

  usersColumnDefs: ColDef[] = [
    genTextColumn(
      'username',
      I18n.getColName('username'),
    ),
    genTextColumn(
      'fullname',
      I18n.getColName('fullName'),
    ),
    genTextColumn(
      'email',
      I18n.getColName('email'),
    ),
    genTextColumn(
      'company',
      I18n.getColName('company'),
    ),
    genTextColumn(
      'department',
      I18n.getColName('department'),
    ),
    {
      ...genTextColumn(
        'role',
        I18n.getColName('role'),
          (d: any) => (
              d.value? this.translateService.instant(`role_${d.value.toLowerCase()}`): ''),
      ),
      floatingFilter: true,
      sortable: true,
    },
    {
      ...genBooleanColumn(
          'closed',
          this.translateService.instant('closed'),
          this.translateService
      ), // needed because cellType inferred before transformation to text
      cellDataType: 'text',
    },
    {
      ...genBooleanColumn(
          'licensed',
          this.translateService.instant('licensed'),
          this.translateService
      ), // needed because cellType inferred before transformation to text
      cellDataType: 'text',
    },
    {
      ...genTextColumn(
        'title',
        I18n.getColName('title'),
      ),
      hide: true,
    },
    {
      ...genTextColumn(
        'phoneNumber',
        I18n.getColName('phoneNumber'),
      ),
      hide: true,
    },
    {
      ...genTextColumn(
        'workplace.description',
        I18n.getColName('workplace'),
      ),
      hide: true,
    },
    {
      ...genCodeTableColumn({
        field: 'hubs',
        headerName: this.translateService.instant('hub'),
      }),
      valueFormatter: (params) =>
        [...new Set(params.value?.map((d) => d.name))].join(', '),
      floatingFilter: false, // 1 to N client-data filtering is not working properly with floating filters, disabled for the moment
      hide: true,
    },
  ];

  usersGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent<User>) => {
      this.onGridReady(event);
    }
  };

  subscriptions: Subscription[] = [];
  statEntries: StatsEntry[] = [];
  users: User[] = [];

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly globalService: GlobalService,
    protected readonly userService: UserService,
    protected readonly licenseService: LicenseService,
    protected readonly codeTableService: CodeTableService,
  ) {
  }

  ngOnInit() {
    combineLatest([
      this.userService.getAllUsers(),
      this.userService.getUserRoleCount(),
      this.licenseService.getLicenseCount(),
    ]).subscribe({
      next: ([users, roleCount, licenseCount]) => {
        this.users = users;
        this.statEntries = Object.entries(roleCount).map(([key, val]) => ({
          label: this.translateService.instant(`role_${key.toLowerCase()}`),
          value: val,
        }));
        this.statEntries.push({
          label: this.translateService.instant('licensed'),
          value: licenseCount.used,
        });
        this.statEntries.push({
          label: this.translateService.instant('licensesAvailable'),
          value: licenseCount.available,
        });
        this.statEntries.push({
          label: this.translateService.instant('licenseExpiryDate'),
          value: this.globalService.dateToFrChLocale(licenseCount.expiryDate),
        });
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setActiveTab(tab: string) {
    if (this.activeTab === tab) {
      return;
    }
    this.activeTab = tab;
  }

  private onGridReady(event: GridReadyEvent<User>) {
    const {api} = event;
    this.subscriptions.push(I18n.getColumns(this.translateService, api));
    this.presetClosedFilter(api);
  }

  private presetClosedFilter(gridApi: GridApi) {
    const closedFilter = gridApi.getFilterInstance('closed');

    if (!closedFilter.getModel()) {
      (closedFilter.setModel({values: ['false']}) as AgPromise<void>).then(
        () => gridApi.onFilterChanged()
      );
    }
  }

  gridFilterReset(event: GridResetEvent) {
    this.presetClosedFilter(event.api);
  }
}
