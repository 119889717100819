/**
 * aSpark core API
 * The REST API of aSpark\'s core service 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Field { 
    id?: number;
    type: Field.TypeEnum;
    isRichText: boolean;
    value: string;
}
export namespace Field {
    export type TypeEnum = 'INTRO' | 'OUTRO' | 'ABSTRACT' | 'DESCRIPTION' | 'INVESTMENT_CASE' | 'URL' | 'MEDIA' | 'MEDIA_DESCRIPTION' | 'URL_DESCRIPTION' | 'TITLE' | 'DISCLAIMER';
    export const TypeEnum = {
        INTRO: 'INTRO' as TypeEnum,
        OUTRO: 'OUTRO' as TypeEnum,
        ABSTRACT: 'ABSTRACT' as TypeEnum,
        DESCRIPTION: 'DESCRIPTION' as TypeEnum,
        INVESTMENTCASE: 'INVESTMENT_CASE' as TypeEnum,
        URL: 'URL' as TypeEnum,
        MEDIA: 'MEDIA' as TypeEnum,
        MEDIADESCRIPTION: 'MEDIA_DESCRIPTION' as TypeEnum,
        URLDESCRIPTION: 'URL_DESCRIPTION' as TypeEnum,
        TITLE: 'TITLE' as TypeEnum,
        DISCLAIMER: 'DISCLAIMER' as TypeEnum
    };
}


