import {Component, EventEmitter, Input, OnDestroy, OnInit, Output,} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ColDef, GridOptions} from 'ag-grid-community';
import {filter, Observable, Subscription} from 'rxjs';
import {Campaign, CampaignProductType, ProductListItem, Story} from 'src/app/api/core';
import {DataService} from 'src/app/services/data.service';
import {StoryProductType} from 'src/app/util/enum';
import {genIconButtonColumn} from '../grid/cell-renderers/icon-button.renderer';
import {FeatureFlags, FeatureFlagsService} from '../../services/feature-flags.service';
import {PermissionService} from '../../services/permission.service';
import {EProtectedActions} from '../../util/protected-actions';
import {genIconLabelColumn} from "../grid/cell-renderers/icon-label.renderer";

/**
 * Component for products card
 */
@Component({
  selector: 'app-products-card',
  templateUrl: './products-card.component.html',
})
export class ProductsCardComponent implements OnInit, OnDestroy {
  @Input() allowEditProducts: boolean;
  @Input() productType!: StoryProductType;
  @Output() editProducts: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteProduct: EventEmitter<number> = new EventEmitter<number>();
  @Output() toggleAutoSync: EventEmitter<void> = new EventEmitter<void>();
  @Output() restoreProducts: EventEmitter<void> = new EventEmitter<void>();

  autoSyncChecked: boolean;
  loading$: Observable<boolean>;

  cardHeader: string;
  restoreProductsTooltip: string;
  campaign: Campaign;
  story: Story;
  products: ProductListItem[];
  featureFlags: FeatureFlags;
  autoSyncFeature = false;
  autoSyncBuyProductsEnabled = false;
  autoSyncSellProductsEnabled = false;
  autoSyncBuyProducts = false;
  autoSyncSellProducts = false;
  autoSyncBuyProductsMsg: string;
  autoSyncSellProductsMsg: string;
  productsColumns: ColDef[] = [];
  productsGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    rowMultiSelectWithClick: true,
    paginationAutoPageSize: true,
  };

  private subscriptions: Subscription[] = [];

  constructor(
    protected readonly dataService: DataService,
    protected readonly translateService: TranslateService,
    protected readonly featureFlagsService: FeatureFlagsService,
    protected readonly permissionsService: PermissionService,
  ) {
    this.loading$ = this.dataService.loading$;
    this.featureFlagsService.getFeatureFlags().subscribe({
      next: (d: FeatureFlags) => {
        this.featureFlags = d;
      }
    });
  }

  ngOnInit(): void {
    if (this.productType === StoryProductType.BUY) {
      this.cardHeader = 'buyProducts';
      this.restoreProductsTooltip = 'restoreBuyProductsFromStory';
    } else {
      this.cardHeader = 'sellProducts';
      this.restoreProductsTooltip = 'restoreSellProductsFromStory';
    }
    this.subscriptions.push(
      this.dataService.campaign$
        .pipe(filter((campaign) => !!campaign))
        .subscribe((campaign) => {
          this.campaign = campaign;
          this.products =
            this.productType === StoryProductType.BUY
              ? this.campaign.buyProducts
              : this.campaign.sellProducts;
          this.autoSyncChecked = this.productType === StoryProductType.BUY
            ? this.campaign.autoSyncBuyProducts
            : this.campaign.autoSyncSellProducts;
          const campaignStory = this.campaign.story;
          this.autoSyncFeature = campaignStory !== null;
          const isCampaignEditable = this.campaign.status === Campaign.StatusEnum.DRAFT;
          const canAutoSync = this.permissionsService.hasAnyPermission(EProtectedActions.autoSyncCampaign);
          this.autoSyncBuyProductsEnabled = isCampaignEditable
            && !campaignStory?.autoSyncBuyProducts && canAutoSync;
          this.autoSyncSellProductsEnabled = isCampaignEditable
            && !campaignStory?.autoSyncSellProducts && canAutoSync;
          this.autoSyncBuyProducts = campaignStory?.autoSyncBuyProducts || this.campaign.autoSyncBuyProducts;
          this.autoSyncSellProducts = campaignStory?.autoSyncSellProducts || this.campaign.autoSyncSellProducts;
          if (this.autoSyncBuyProductsEnabled) {
            this.autoSyncBuyProductsMsg = this.translateService.instant(
              this.autoSyncBuyProducts ? 'autoSyncToStoryDisable' : 'autoSyncToStoryEnable'
            );
          }
          if (this.autoSyncSellProductsEnabled) {
            this.autoSyncSellProductsMsg = this.translateService.instant(
              this.autoSyncSellProducts ? 'autoSyncToStoryDisable' : 'autoSyncToStoryEnable'
            );
          }
          this.initColumns();
        })
    );
    this.subscriptions.push(
      this.dataService.story$
        .pipe(filter((story) => !!story))
        .subscribe((story) => {
          this.story = story;
          this.products =
            this.productType === StoryProductType.BUY
              ? this.story.buyProducts
              : this.story.sellProducts;
          this.autoSyncChecked = this.productType === StoryProductType.BUY
            ? this.story.autoSyncBuyProducts
            : this.story.autoSyncSellProducts;
          this.autoSyncFeature = true;
          const canAutoSync = this.permissionsService.hasAnyPermission(EProtectedActions.autoSyncStory);
          this.autoSyncBuyProductsEnabled = canAutoSync;
          this.autoSyncSellProductsEnabled = canAutoSync;
          this.autoSyncBuyProducts = this.story.autoSyncBuyProducts;
          this.autoSyncSellProducts = this.story.autoSyncSellProducts;
          this.autoSyncBuyProductsMsg = this.translateService.instant(
            this.autoSyncBuyProducts ? 'autoSyncToStoryDisable' : 'autoSyncToStoryEnable'
          );
          this.autoSyncSellProductsMsg = this.translateService.instant(
            this.autoSyncSellProducts ? 'autoSyncToCampaignsDisable' : 'autoSyncToCampaignsEnable'
          );
          this.initColumns();
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private initColumns(): void {
    const editCol = this.allowEditProducts && (this.story || !this.autoSyncChecked) ? {
      ...genIconButtonColumn({
        callback: (data: ProductListItem) => this.deleteProduct.emit(data.id),
        icon: 'delete',
        suppressMenu: true,
        tooltip: this.translateService.instant('removeProduct'),
      }),
      floatingFilter: false,
      colId: 'icon-button-delete',
    } : undefined;
    this.productsColumns = [
      {
        ...genIconLabelColumn({
          field: 'isin',
          icon: (data) => (data.closed && 'warning') || undefined,
          label: (data) => data.isin,
          tooltip: (data) => (data.closed && this.translateService.instant('closedProductWarning')) || undefined,
        }),
        floatingFilter: false,
        headerName: this.translateService.instant('isin'),
        suppressMenu: true,
        resizable: true,
        sortable: true,
        cellClass: 'danger'
      },
      editCol,
      {
        field: 'name',
        headerName: this.translateService.instant('productName'),
        suppressMenu: true,
        resizable: true,
        floatingFilter: false,
        sortable: true,
      },
      {
        field: 'country.iso2',
        headerName: this.translateService.instant('country'),
        suppressMenu: true,
        resizable: true,
        floatingFilter: false,
        sortable: true,
      },
      {
        field: 'currency.code',
        headerName: this.translateService.instant('referenceCurrency'),
        suppressMenu: true,
        resizable: true,
        floatingFilter: false,
        sortable: true,
      },
      {
        field: 'assetClass.name',
        headerName: this.translateService.instant('assetClass'),
        suppressMenu: true,
        resizable: true,
        floatingFilter: false,
        sortable: true,
      },
      {
        field: 'type.name',
        headerName: this.translateService.instant('assetType'),
        suppressMenu: true,
        resizable: true,
        floatingFilter: false,
        sortable: true,
      },
    ].filter((col) => col);
  }

  get campaignStatus() {
    return Campaign.StatusEnum;
  }

  get campaignProductType() {
    return CampaignProductType;
  }

  handleEditProducts(): void {
    this.editProducts.emit();
  }

  restoreProductsFromStory(): void {
    this.restoreProducts.emit();
  }

  handleToggleAutoSyncProducts(event: any, productType: CampaignProductType): void {
    const target = this.campaign || this.story;
    if (productType === CampaignProductType.BUY) {
      event.source.checked = target.autoSyncBuyProducts;
    } else {
      event.source.checked = target.autoSyncSellProducts;
    }
    this.toggleAutoSync.emit();
  }
}
