import {ContactChannel, Content, Language} from 'src/app/api/core';
import {ActionType} from "../campaign/views/campaign-overview/campaign-actions-list-utils";

export class ContentData {
    language: Language;
    parentId: number;
    content: Content;
    editable: boolean;
    type: ActionType;
    campaignId: number;
    channel: ContactChannel;

    constructor(
        type: ActionType,
        language: Language,
        parentId: number,
        content: Content,
        editable: boolean = true,
        campaignId: number = null,
        channel: ContactChannel = null
    ) {
        this.type = type;
        this.language = language;
        this.parentId = parentId;
        this.content = content;
        this.editable = editable;
        this.campaignId = campaignId;
        this.channel = channel;
    }
}
