<div class="filter-search">
  <app-filter
    #autoCompleteFilter
    class="filter-autocomplete"
    [metaData]="searchMetadata"
    [(value)]="search"
    (valueChange)="handleAdd()"
    *ngIf="!readOnly"
  ></app-filter>
  <app-filter-details-operator-button
    *ngIf="!readOnly || filterForm.children.length > 0"
    [operator]="filterForm.operator"
    (operatorChange)="changeOperator($event)"
    [readOnly]="readOnly"
  ></app-filter-details-operator-button>
</div>
<div class="filter-list">
  <app-filter-asset
    *ngFor="let filter of filterForm.children"
    [filter]="filter"
    (delete)="deleteFilter(filter.key)"
    [readOnly]="readOnly"
    [isTriggerStory]="isTriggerStory"
  ></app-filter-asset>
</div>
