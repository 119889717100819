import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild,} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {GridApi} from 'ag-grid-community';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {
  Campaign,
  CampaignAction,
  CampaignActionStatus,
  Collection,
  CollectionService,
  Portfolio,
} from 'src/app/api/core';
import {ModalData} from 'src/app/models/modal.model';
import {ModalService,} from 'src/app/services/modal.service';
import {NotificationService} from 'src/app/services/notification.service';
import {EModalType, EPortfolioActionStatus} from 'src/app/util/enum';
import {GridSelectionUtils} from 'src/app/util/grid/grid-selection.util';
import {GridSelectedItemsProvider, GridSelectedItemsProviderByCollection,} from '../../data-source';
import {DatasourceFilter} from '../../datasource-filter';
import {
  CampaignActionProcessingComponent
} from '../../../../campaign/views/campaign-overview/campaign-action-processing/campaign-action-processing.component';
import {PermissionService} from '../../../../services/permission.service';
import {EProtectedActions} from '../../../../util/protected-actions';
import {ButtonAction, DropdownSelectionBarComponent,} from '../dropdown-selection-bar/dropdown-selection-bar.component';
import {GridResetEvent} from '../../grid.component';
import {MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckbox, MatCheckboxDefaultOptions,} from '@angular/material/checkbox';
import {ColumnState} from "ag-grid-community/dist/lib/columns/columnModel";
import {GridFilterModel} from "../../../../models/grid.model";
import {GlobalService} from "../../../../services/global.service";
import {FeatureFlagsService} from "../../../../services/feature-flags.service";
import {ActionType} from "../../../../campaign/views/campaign-overview/campaign-actions-list-utils";

interface PortfolioActionRow {
  action: CampaignAction;
  portfolio: Portfolio;
}

@Component({
  selector: 'app-table-selection-bar',
  templateUrl: './table-selection-bar.component.html',
  providers: [
    // We set the checkbox click action to no operation so that we can handle it manually
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions,
    },
  ],
})
export class TableSelectionBarComponent implements OnInit, OnDestroy {
  @ViewChild('selectAllCheckbox', { static: true })
  selectAllCheckbox: MatCheckbox;

  @ViewChild('selectionDropdown', { static: true })
  selectionDropdown: DropdownSelectionBarComponent;

  @Input() isHierarchyList = false;
  @Input() gridApi: GridApi;
  @Input() gridSelectionUtils: GridSelectionUtils;
  @Input() level = 0;
  @Input() itemLabelRef?: string;
  @Input() labelRefs: {
    singular: string;
    plural: string;
  };
  @Input() selectAllProvider: GridSelectedItemsProvider;
  @Input() selectAllPreselectionProvider: GridSelectedItemsProvider;
  @Input()
  selectAllByCollectionPreselectionProvider: GridSelectedItemsProviderByCollection;
  @Input() deleteVisible: boolean;
  @Input() actionsVisible: boolean;
  @Input() campaign: Campaign;
  @Input() filterModelSubject: BehaviorSubject<GridFilterModel>;
  @Input() actionType = ActionType.CampaignAction;
  @Output() refreshTable = new EventEmitter();
  @Output() expandAll = new EventEmitter();
  @Output() collapseAll = new EventEmitter();
  @Output() selectionCleared = new EventEmitter<GridResetEvent>();
  // emits TRUE when selection has started, then FALSE when selection has ended processing
  @Output() selectionProcessing = new EventEmitter<boolean>();

  selectedValues: PortfolioActionRow[] = [];

  actions: ButtonAction[] = [
    {
      icon: 'delete',
      text: 'delete',
      className: 'icon-text-btn error-btn',
      click: () => this.delete(),
      tooltip: () => this.translateService.instant('deleteSelectionFromCampaign'),
      show: () =>
        this.deleteVisible &&
        this.hasDeletePermissions(),
    },
    {
      icon: 'send',
      text: 'send',
      className: 'icon-text-btn accent-btn',
      click: () =>
        this.actionIsExecutableForSomeSelectedElems() && this.executeActions(),
      tooltip: () =>
        this.actionIsExecutableForSomeSelectedElems()
          ? this.translateService.instant('send')
          : this.translateService.instant('sendNotPossible'),
      disabled: () => !this.actionIsExecutableForSomeSelectedElems(),
      show: () =>
        this.actionsVisible &&
        this.permissionService.hasAnyPermission(
          EProtectedActions.executeActionCampaign
        ),
    },
    {
      icon: 'bulk_edit',
      text: 'bulkEdit',
      className: 'icon-text-btn',
      click: () => this.showBulkEditButton() && this.showBulkEdit(),
      disabled: () => this.disableBulkEditButton(),
      show: () =>
        this.actionsVisible && this.showBulkEditButton() && this.bulkEdit,
    },
  ];

  hiddenActions: ButtonAction[] = [];

  selectionHiddenActions: ButtonAction[] = [
    {
      icon: 'rule',
      text: 'preselectionRules',
      className: 'icon-text-btn',
      click: () => this.selectAllPagesPreselection(),
      tooltip: () => this.translateService.instant('selectAllPagesPreselection'),
      show: () => true,
    },
  ];

  bulkEdit = false;
  featureDialogFilters: boolean;
  private selectionChangeSubscription: Subscription;

  constructor(
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private modalService: ModalService,
    private permissionService: PermissionService,
    private collectionService: CollectionService,
    private globalService: GlobalService,
    private featureFlagsService: FeatureFlagsService,
  ) {
    this.bulkEdit = this.permissionService.hasAnyPermission(
      EProtectedActions.editBulk
    );
    combineLatest([
      this.collectionService.getCollections(),
      this.featureFlagsService.getFeatureFlags()
    ]).subscribe(([collections, featureFlags]) => {
      this.updateSelectionHiddenActions(collections);
      this.featureDialogFilters = true;
    });
  }

  ngOnInit() {
    this.selectedValues = this.gridSelectionUtils.getSelectedValues();
    this.selectionChangeSubscription = this.gridSelectionUtils
      .getSelectionChangeObservable()
      .subscribe(() => {
        this.selectedValues = this.gridSelectionUtils.getSelectedValues();
        this.hiddenActions = this.generateHiddenActions();
      });
  }

  ngOnDestroy() {
    if (this.selectionChangeSubscription) {
      this.selectionChangeSubscription.unsubscribe();
    }
  }

  private generateHiddenActions(): ButtonAction[] {
    const isCampaignAction = this.actionType === ActionType.CampaignAction;
    return [
      {
        icon: 'cancel_schedule_send',
        text: 'noAction',
        className: 'icon-text-btn',
        click: () =>
          this.actionIsExecutableForSomeSelectedElems() && this.noActions(),
        tooltip: () =>
          this.actionIsExecutableForSomeSelectedElems()
            ? this.translateService.instant('setNoAction')
            : this.translateService.instant('setNoActionNotPossible'),
        disabled: () => !this.actionIsExecutableForSomeSelectedElems(),
        show: () =>
          this.actionsVisible &&
          this.permissionService.hasAnyPermission(
            EProtectedActions.setNoActionCampaign
          ),
      },
      {
        icon: 'restart_alt',
        text: 'revertAction',
        className: 'icon-text-btn',
        click: () =>
          this.actionIsReversibleForSomeSelectedElems() && this.revertActions(),
        tooltip: () =>
          this.actionIsReversibleForSomeSelectedElems()
            ? this.translateService.instant('revertAction')
            : this.translateService.instant('revertActionNotPossible'),
        disabled: () => !this.actionIsReversibleForSomeSelectedElems(),
        show: () =>
          this.actionsVisible &&
          this.permissionService.hasAnyPermission(
            EProtectedActions.revertActionToPendingCampaign
          ),
      },
      isCampaignAction ? {
        icon: 'person_add',
        text: 'assignToAdvisor',
        className: 'icon-text-btn',
        click: () =>
          this.isAssignableForSomeSelectedElems('advisor') &&
          this.assignToAdvisor(),
        tooltip: () => this.translateService.instant('assignToAdvisor'),
        disabled: () => !this.isAssignableForSomeSelectedElems('advisor'),
        show: () =>
          this.actionsVisible &&
          this.permissionService.hasAnyPermission(
            EProtectedActions.assignToAdvisorCampaign
          ),
      } : undefined,
      isCampaignAction ? {
        icon: 'supervised_user_circle',
        text: 'assignToRelationshipManager',
        className: 'icon-text-btn',
        click: () =>
          this.isAssignableForSomeSelectedElems('relationshipManager') &&
          this.assignToRelationshipManager(),
        tooltip: () => this.translateService.instant('assignToRelationshipManager'),
        disabled: () =>
          !this.isAssignableForSomeSelectedElems('relationshipManager'),
        show: () =>
          this.actionsVisible &&
          this.permissionService.hasAnyPermission(
            EProtectedActions.assignToRmCampaign
          ),
      } : undefined,
      isCampaignAction ? {
        icon: 'sync',
        text: 'suitability',
        className: 'icon-text-btn',
        click: () => this.refreshSuitability(),
        tooltip: () => this.translateService.instant('updateSuitability'),
        show: () =>
          this.permissionService.hasAnyPermission(
            EProtectedActions.refreshSuitabilityCampaign
          ),
      } : undefined,
    ].filter(a => a);
  }

  getDetailRowsOfMasterRows(masterRows: any[]): any[] {
    const subRows = [];
    masterRows.forEach((n) => {
      if (n.actions) {
        n.actions.forEach((a) => subRows.push(a));
      }
    });
    return subRows;
  }

  togglePageSelection(_: MouseEvent) {
    this.selectionProcessing.emit(true);
    this.selectAllCheckbox.checked = !this.selectAllCheckbox.checked;
    this.clearSelection();
    this.selectAllPages(this.selectAllCheckbox.checked);
  }

  private selectAllPages(selected: boolean = true) {
    const filter = new DatasourceFilter(this.gridApi, this.globalService);
    this.selectAllProvider(filter.toString()).subscribe((items) => {
      this.gridSelectionUtils.setMultipleSelection(items, selected);
      this.selectionProcessing.emit(false);
    });
  }

  selectAllPagesPreselection() {
    const filter = new DatasourceFilter(this.gridApi, this.globalService);
    this.selectionProcessing.emit(true);
    this.gridSelectionUtils.clearSelection();
    this.selectAllPreselectionProvider(filter.toString()).subscribe((items) => {
      this.gridSelectionUtils.setMultipleSelection(items, true);
      this.sortBySelected();
      this.gridApi.refreshServerSide({ purge: true }); // force server side refresh, to apply sorting
      this.selectionProcessing.emit(false);
    });
  }

  getTooltip() {
    if (this.allSelected()) {
      return this.translateService.instant('deselectAll');
    } else {
      return this.translateService.instant('selectAllPages');
    }
  }

  clearSelection() {
    this.gridSelectionUtils.clearSelection();
    this.selectionDropdown?.clear();
    this.selectionCleared.emit({
      api: this.gridApi,
    });
  }

  allSelected(): boolean {
    const rowsOnPage = this.getRowsOnPage();
    const nodesOnPage = this.getDetailRowsOfMasterRows(rowsOnPage).filter((k) =>
      this.gridSelectionUtils.isSelectable(k)
    );

    if (nodesOnPage.length == 0 && rowsOnPage.length > 0) {
      return rowsOnPage.every((k) =>
        this.gridSelectionUtils.isSelected(
          k[this.gridSelectionUtils.indexField()]
        )
      );
    } else {
      return (
        nodesOnPage.length > 0 &&
        nodesOnPage.every((k) =>
          this.gridSelectionUtils.isSelected(
            k[this.gridSelectionUtils.indexField()]
          )
        )
      );
    }
  }

  someSelected(): boolean {
    return (
      this.gridSelectionUtils.getSelectedValues().length > 0 &&
      !this.allSelected()
    );
  }

  refresh(): void {
    this.clearSelection();
    this.refreshTable.emit(true);
  }

  private openModal(modalData: ModalData) {
    const selectedCampaignActions = this.gridSelectionUtils.getSelectedValues();
    const modalType = modalData.type;
    const data = {
      ...modalData,
      submitBtn: { label: '' },
      cancelBtn: { label: this.translateService.instant('cancel') },
    };
    const dialogRef = this.modalService.openDefaultDialog(
      data,
      undefined,
      undefined,
      true
    );
    dialogRef.afterClosed().subscribe((clearSelection) => {
      // clear selection if modal was closed with submit, keeping the selection afterward if it's a bulk-edit
      if (clearSelection) {
        this.refresh();
        const keepSelectionTypes = [
          EModalType.bulkEdit
        ];

        if (keepSelectionTypes.includes(modalType)) {
          // a timeout is required since the refresh operation is asynchronous
          setTimeout(
            () => this.gridSelectionUtils.setMultipleSelection(selectedCampaignActions, true),
            250
          );
        }
      }
    });
  }

  refreshSuitability(): void {
    const selectedRows = this.getSelectedActionIdsRows();
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    const modalData: ModalData = {
      title: null,
      type: EModalType.refreshSuitabilitiesDialog,
      data: {
        modalType: EModalType.refreshSuitabilitiesDialog,
        campaign: this.campaign,
        selectedRows,
      },
      component: CampaignActionProcessingComponent,
    };
    this.openModal(modalData);
  }

  delete(): void {
    const selectedRows = this.getSelectedActionIdsRows();
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    const modalData: ModalData = {
      title: null,
      type: EModalType.removeActionsDialog,
      data: {
        modalType: EModalType.removeActionsDialog,
        campaign: this.campaign,
        selectedRows,
      },
      component: CampaignActionProcessingComponent,
    };
    this.openModal(modalData);
  }

  executeActions(): void {
    const selectedRows = this.getSelectedActionIdsRows(
      (row) => row.action.status === EPortfolioActionStatus.pending
    );
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    const modalData: ModalData = {
      title: null,
      type: EModalType.executeActionsDialog,
      data: {
        modalType: EModalType.executeActionsDialog,
        campaign: this.campaign,
        selectedRows,
      },
      component: CampaignActionProcessingComponent,
    };
    this.openModal(modalData);
  }

  noActions(): void {
    const selectedRows = this.getSelectedActionIdsRows(
      (row) => row.action.status === EPortfolioActionStatus.pending
    );
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    const modalData: ModalData = {
      title: null,
      type: EModalType.setNoActionsDialog,
      data: {
        modalType: EModalType.setNoActionsDialog,
        campaign: this.campaign,
        selectedRows,
      },
      component: CampaignActionProcessingComponent,
    };
    this.openModal(modalData);
  }

  revertActions(): void {
    const selectedRows = this.getSelectedActionIdsRows(
      (row) => row.action.status !== EPortfolioActionStatus.pending
    );
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    const modalData: ModalData = {
      title: null,
      type: EModalType.revertActionsDialog,
      data: {
        modalType: EModalType.revertActionsDialog,
        campaign: this.campaign,
        selectedRows,
      },
      component: CampaignActionProcessingComponent,
    };
    this.openModal(modalData);
  }

  assignToAdvisor(): void {
    const selectedRows = this.getSelectedActionIdsRows(
      (row) =>
        row.action.status === EPortfolioActionStatus.pending &&
        row.portfolio.advisor &&
        row.portfolio.advisor.id !== row.action?.assignee?.id
    );
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    const modalData: ModalData = {
      title: null,
      type: EModalType.assignActionsToAdvisorDialog,
      data: {
        modalType: EModalType.assignActionsToAdvisorDialog,
        campaign: this.campaign,
        selectedRows,
      },
      component: CampaignActionProcessingComponent,
    };
    this.openModal(modalData);
  }

  assignToRelationshipManager(): void {
    const selectedRows = this.getSelectedActionIdsRows(
      (row) =>
        row.action.status === EPortfolioActionStatus.pending &&
        row.portfolio.relationshipManager &&
        row.portfolio.relationshipManager?.id !== row.action.assignee?.id
    );
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    const modalData: ModalData = {
      title: null,
      type: EModalType.assignActionsToRelationshipManagerDialog,
      data: {
        modalType: EModalType.assignActionsToRelationshipManagerDialog,
        campaign: this.campaign,
        selectedRows,
      },
      component: CampaignActionProcessingComponent,
    };
    this.openModal(modalData);
  }

  actionIsExecutableForSomeSelectedElems(): boolean {
    const selValues = this.gridSelectionUtils.getSelectedValues();
    return (
      this.permissionService.hasAnyPermission(
        EProtectedActions.executeActionCampaign
      ) &&
      !selValues.every(
        (action) => action.status !== EPortfolioActionStatus.pending // Hide if no action is pending
      )
    );
  }

  actionIsReversibleForSomeSelectedElems(): boolean {
    const selValues = this.gridSelectionUtils.getSelectedValues();

    return (
      this.permissionService.hasAnyPermission(
        EProtectedActions.executeActionCampaign
      ) &&
      !selValues.every(
        (action) => action.status === EPortfolioActionStatus.pending // Hide if all actions are pending
      )
    );
  }

  isAssignableForSomeSelectedElems(type: string): boolean {
    const assignableValues = this.getAssignableActions(type);
    return assignableValues.length > 0;
  }

  getAssignableActions(type: string): number[] {
    const filter =
      type === 'advisor'
        ? (row) => {
          return row.action.status === EPortfolioActionStatus.pending &&
            (!row.portfolio ||
              (row.portfolio.advisor && row.portfolio.advisor.id !== row.action.assignee?.id)
            )
        }
        : (row) => {
            return row.action.status === EPortfolioActionStatus.pending &&
              (!row.portfolio ||
                (row.portfolio.relationshipManager &&
                row.portfolio.relationshipManager.id !== row.action.assignee?.id)
              );
        }
    return this.getSelectedActionIdsRows(filter).map((row) => row.action.id);
  }

  private getRowsOnPage(): Array<any> {
    const nodes = new Array<any>();
    if (this.gridApi) {
      this.gridApi.forEachNode((rowNode, index) => {
        if (
          rowNode.data &&
          this.gridSelectionUtils.isRowInCurrentPage(
            rowNode,
            this.gridApi,
            index
          )
        ) {
          nodes.push(rowNode.data);
        }
      });
    }
    return nodes;
  }

  showBulkEditButton(): boolean {
    const selValues = this.gridSelectionUtils.getSelectedValues();
    return (
      this.permissionService.hasAllPermissions(
        EProtectedActions.editActionLanguage,
        EProtectedActions.editCampaignChannel,
        EProtectedActions.editCampaignSender
      ) && selValues.length > 0
    );
  }

  disableBulkEditButton(): boolean {
    const selValues = this.gridSelectionUtils.getSelectedValues();
    return !selValues.some(
      (action) => action.status === EPortfolioActionStatus.pending
    );
  }

  showBulkEdit() {
    const selectedRows = this.getSelectedActionIdsRows(
      (row) => row.action.status === CampaignActionStatus.PENDING
    );
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    const modalData: ModalData = {
      title: null,
      type: EModalType.bulkEdit,
      data: {
        modalType: EModalType.bulkEdit,
        campaign: this.campaign,
        selectedRows,
        bulkEdit: true,
      },
      component: CampaignActionProcessingComponent,
    };
    this.openModal(modalData);
  }

  private getSelectedActionIdsRows(
    predicate: (row: PortfolioActionRow) => boolean = () => true
  ): PortfolioActionRow[] {
    const selectedActions = this.gridSelectionUtils.getSelectedValues();
    return selectedActions.filter(predicate);
  }

  private updateSelectionHiddenActions(collections: Collection[]) {
    const newActions = collections.map(
      (c) =>
        ({
          icon: 'bulk_edit',
          text: c.name,
          className: 'icon-text-btn',
          click: () => this.selectCollection(c.id),
          disabled: () => false,
          show: () => true,
        } as ButtonAction)
    );
    this.selectionHiddenActions.push(...newActions);
  }

  private selectCollection(collectionId: number) {
    const filter = new DatasourceFilter(this.gridApi, this.globalService);
    this.selectionProcessing.emit(true);
    this.gridSelectionUtils.clearSelection();
    this.selectAllByCollectionPreselectionProvider(collectionId, filter.toString())
      .subscribe((items) => {
        this.gridSelectionUtils.setMultipleSelection(items, true);
        this.sortBySelected();
        this.gridApi.refreshServerSide({ purge: true }); // force server side refresh, to apply sorting
        this.selectionProcessing.emit(false);
      });
  }

  private sortBySelected() {
    const colStates: ColumnState[] = this.gridApi.getColumnState()
      .map(colState => ({
        ...colState,
        sort: colState.colId === 'id' ? 'desc' : null
      }));
    this.gridApi.applyColumnState({
      state: colStates
    });
  }

  protected readonly ActionType = ActionType;

  private hasDeletePermissions() {
    if (this.campaign.decentralized) {
      return this.permissionService.hasAnyPermission(
        EProtectedActions.decentralizedCampaignEdit
      )
    } else {
      return this.permissionService.hasAnyPermission(
        EProtectedActions.deletePortfolioClientCampaign
      )
    }
  }
}
