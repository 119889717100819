<div class="container" [class.disabled]="loading">
  <div class="hub-header">
    <div
      class="colored-menu-trigger"
      [matMenuTriggerFor]="hubMenu"
      *ngIf="hubs.length > 1"
    >
      <app-config-icon
        iconIdentifier="storage"
        [matTooltip]="'hub' | translate"
      ></app-config-icon>
      <div>{{ selectedHub?.name || '' }}</div>
      <app-config-icon iconIdentifier="expand_more"></app-config-icon>
      <mat-menu #hubMenu="matMenu" class="hub-menu">
        <ng-container *ngIf="hubs.length > 0">
          <button
            mat-menu-item
            [disabled]="loading"
            *ngFor="let hub of hubs"
            class="mat-menu-item-flex"
            (click)="onSelectHub(hub)"
          >
            <span>{{ hub.name }}</span>
          </button>
        </ng-container>
      </mat-menu>
    </div>
  </div>
  <div class="filter-configurations">
    <div *ngFor="let item of filterConfigs;">
      <mat-slide-toggle
        color="primary"
        [checked]="item.enabled"
        [disabled]="loading"
        (change)="onToggleChanged($event, item)"
      >
        {{ item.ident | translate }}
      </mat-slide-toggle>
      <div *ngFor="let child of item.children;">
        <mat-slide-toggle
          color="primary"
          class="child-item"
          [checked]="child.enabled"
          [disabled]="loading || item.enabled === false"
          (change)="onToggleChanged($event, child)"
        >
          {{ child.ident | translate }}
        </mat-slide-toggle>
      </div>
    </div>
  </div>
</div>
