<div class="h4" *ngIf="!useDropdownSelect && isVisible">{{ title }}:</div>
<div
  *ngFor="let action of visibleActions"
  class="{{ action.className }}"
  [class.disabled]="action.disabled && action.disabled()"
  (click)="action.click()"
  [matTooltip]="action.tooltip ? action.tooltip() : ('' | translate)"
>
  <app-config-icon
    *ngIf="action.icon"
    iconIdentifier="{{ action.icon }}"
  ></app-config-icon>
  <span>{{ action.text | translate }}</span>
</div>
<ng-container
  *ngIf="useDropdownSelect && isVisible && visibleHiddenActions.length"
>
  <mat-select [placeholder]="title" [(value)]="selectedAction">
    <mat-option class="dropdown-selection-bar">{{ 'none' | translate }}</mat-option>
    <mat-option
      *ngFor="let action of visibleHiddenActions"
      class="dropdown-selection-bar"
      [value]="action"
      [disabled]="action.disabled && action.disabled()"
    >
      {{ action.text | translate }}
    </mat-option>
  </mat-select>
</ng-container>
<ng-container
  *ngIf="!useDropdownSelect && isVisible && visibleHiddenActions.length"
>
  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions Menu">
    <app-config-icon iconIdentifier="more_vert"></app-config-icon>
  </button>
  <mat-menu #menu="matMenu" class="dropdown-selection-wrapper">
    <button
      mat-menu-item
      *ngFor="let action of visibleHiddenActions"
      class="{{ action.className }} dropdown-selection-bar"
      [disabled]="action.disabled && action.disabled()"
      (click)="action.click()"
    >
      <app-config-icon
        *ngIf="action.icon"
        iconIdentifier="{{ action.icon }}"
      ></app-config-icon>
      <span>{{ action.text | translate }}</span>
    </button>
  </mat-menu>
</ng-container>
