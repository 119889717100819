import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ColDef, RowDoubleClickedEvent,} from 'ag-grid-community';
import {GridOptions} from 'ag-grid-enterprise';
import {
  Campaign,
  CampaignIntermediaryActionExtended,
  CampaignIntermediaryActionService,
  Employee,
} from 'src/app/api/core';
import {CodeTableService} from 'src/app/services/code-table.service';
import {GlobalService} from 'src/app/services/global.service';
import {GridDataProvider} from 'src/app/shared/grid/data-source';
import {ECodeTables, EViewRoutes} from 'src/app/util/enum';
import {
  genCodeTableColumn,
  genEnumColumn,
  genTextColumn,
  usernamesValueLabels,
} from 'src/app/util/grid/grid-renderer.util';
import {genLinkColumn} from "../../../../shared/grid/cell-renderers/link.renderer";
import {genActionStatus} from "../../../../shared/grid/cell-renderers/action-status.renderer";
import {ActionType, ContentAction} from "../../../../campaign/views/campaign-overview/campaign-actions-list-utils";
import {PermissionService} from "../../../../services/permission.service";
import {EProtectedActions} from "../../../../util/protected-actions";
import {genIconButtonColumn} from "../../../../shared/grid/cell-renderers/icon-button.renderer";
import {CustomPreviewService} from "../../../../services/custom-preview.service";

@Component({
  selector: 'app-employee-overview-actions',
  templateUrl: './employee-overview-actions.component.html',
})
export class EmployeeOverviewActionsComponent implements OnInit {
  @Input() employee: Employee;

  dataActions: GridDataProvider;

  colDefs: ColDef[] = [];

  actionsGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.globalService.navigate(
        `${EViewRoutes.campaignOverview}${event.data.campaign.id}`
      ),
  };

  constructor(
    private translateService: TranslateService,
    private codeTableService: CodeTableService,
    private globalService: GlobalService,
    private campaignIntermediaryActionsService: CampaignIntermediaryActionService,
    private permissionService: PermissionService,
    private customPreviewService: CustomPreviewService
  ) {}

  ngOnInit(): void {
    this.dataActions =
      this.campaignIntermediaryActionsService.getEmployeeCampaignActions.bind(
        this.campaignIntermediaryActionsService,
        this.employee.id
      );
    const isCidFilterAllowed = this.permissionService.hasAnyPermission(EProtectedActions.sortAndFilterCid);
    this.colDefs = this.getColDefs(isCidFilterAllowed);
  }

  private getColDefs(isCidFilterAllowed: boolean): ColDef[] {
    return [
      genLinkColumn({
        field: 'campaign.name',
        headerName: this.translateService.instant('campaign'),
        link: (data: any) => `${EViewRoutes.campaignOverview}${data.campaign.id}`,
        filterParamsInfo: {
          customPath: 'campaignIntermediary.campaign.name',
        }
      }),
      genEnumColumn({
        field: 'campaign.status',
        values: [
          Campaign.StatusEnum.LAUNCHED,
          Campaign.StatusEnum.FROZEN,
          Campaign.StatusEnum.CLOSED,
          Campaign.StatusEnum.DRAFT,
          Campaign.StatusEnum.TERMINATED,
        ],
        headerName: this.translateService.instant('campaignStatus'),
        filterParamsInfo: {customPath: 'campaignIntermediary.campaign.status'}
      }),
      {
        ...genTextColumn(
          'intermediary.name',
          this.translateService.instant('intermediary'),
          null,
          { customPath: 'campaignIntermediary.intermediary.name' }
        ),
        sortable: isCidFilterAllowed,
        floatingFilter: isCidFilterAllowed,
      },
      genActionStatus(
        this.translateService,
        'status',
        this.translateService.instant('actionStatus'),
        true,
      ),
      genCodeTableColumn({
        field: 'language',
        headerName: this.translateService.instant('language'),
        observable: this.codeTableService.getCodeTable(ECodeTables.language),
      }),
      genCodeTableColumn({
        field: 'channel.type',
        headerName: this.translateService.instant('channel'),
        observable: this.codeTableService.getCodeTable(ECodeTables.channelType),
      }),
      {
        ...genIconButtonColumn({
          hidden: (data: CampaignIntermediaryActionExtended) =>
            !data.content ||
            !this.permissionService.hasAnyPermission(EProtectedActions.editCampaignCustomContent),
          callback: (data: CampaignIntermediaryActionExtended) => {
            this.showPreview(data)
          },
          tooltip: this.translateService.instant('viewContent'),
          icon: 'preview',
        }),
        sortable: false,
        floatingFilter: false,
      },
      {
        ...genTextColumn(
          'assignees',
          this.translateService.instant('assignees'),
          (data) =>
            usernamesValueLabels(data.data.assignees)
        ),
        sortable: false,
        floatingFilter: false,
      },
    ];
  }

  private showPreview(data: CampaignIntermediaryActionExtended) {
    const contentAction: ContentAction = {
      id: data.id,
      type: ActionType.CampaignAction,
      campaignId: data.campaign.id,
      language: data.language,
      channel: data.channel,
      content: data.content,
    }
    this.customPreviewService.previewCustomContent(
      contentAction,
      true,
      true);
  }
}
