import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, ActivationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {combineLatest, first, Subscription} from 'rxjs';
import {
  ClientService,
  Currency, CurrencyService,
  DynamicUser,
  FilterService,
  Gui,
  GuiService,
  Info,
  InfoService,
  UserRoleData,
  UserService,
} from 'src/app/api/core';
import {ModalData} from 'src/app/models/modal.model';
import {NavigationElement} from 'src/app/models/sidenav_config.model';
import {GlobalService} from 'src/app/services/global.service';
import {ModalService} from 'src/app/services/modal.service';
import {PermissionService} from 'src/app/services/permission.service';
import {EModalType, EViewRoutes, EViewTitles} from 'src/app/util/enum';
import {SIDENAV_CONFIG} from 'src/assets/config/sidenav_config';
import {EProtectedActions} from '../../util/protected-actions';
import {HelpComponent} from '../help/help.component';
import {ModalComponent} from '../modal/modal.component';
import {NotificationService} from '../../services/notification.service';
import {MatSelectChange} from '@angular/material/select';
import {DataService} from "../../services/data.service";
import {UiFilterConfigService} from "../../services/ui-filter-config.service";

/**
 * Page Component.
 * Component for common page structure
 */
@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
})
export class PageComponent implements OnInit, OnDestroy {
  /**
   * Page title
   */
  title: string;
  /**
   * Side navigation elements
   */
  sidenavConfig: NavigationElement[];

  hasAnyRole: boolean;
  /**
   * Current route
   */
  currentViewRoute: EViewRoutes;
  /**
   * Parent route
   */
  activeParentRoute: EViewRoutes;
  /**
   * Initial language at page load
   */
  initialLang: string;
  /**
   * Currently selected language
   */
  currentLang: string;
  /**
   * The current instance info
   */
  info: Info;
  /**
   * Subscriptipons
   */
  subscriptions: Subscription[] = [];
  /**
   * Username of current user
   */
  currentUser: UserRoleData;

  dynamicUsers: DynamicUser[] = [];
  /**
   * Favorite currencies of current user
   */
  favoriteCurrencies: Currency[] = [];
  /*
   * Show Admin Menu
   */
  adminMenu = false;

  guiConfig: Gui = {};

  get viewRoutes() {
    return EViewRoutes;
  }

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private cookieService: CookieService,
    public translateService: TranslateService,
    private modalService: ModalService,
    private infoService: InfoService,
    private notificationService: NotificationService,
    private userService: UserService,
    private permissionService: PermissionService,
    private guiService: GuiService,
    private dataService: DataService,
    private clientService: ClientService,
    private filterService: FilterService,
    private filterConfigService: UiFilterConfigService,
    private currencyService: CurrencyService,
  ) {
    this.subscriptions.push(
      this.router.events.subscribe((event) => {
        // on last navigation get route title to set to page
        if (event instanceof ActivationEnd) {
          let tmpRoute = this.route.snapshot;
          while (tmpRoute.firstChild) {
            tmpRoute = tmpRoute.firstChild;
          }
          this.title = tmpRoute.data.title;
          this.currentViewRoute = tmpRoute.data.viewRoute;
          this.titleService.setTitle('aSpark®');
          this.initialLang = this.translateService.currentLang;
          this.currentLang = this.translateService.currentLang;
          this.activeParentRoute = SIDENAV_CONFIG.find((s: NavigationElement) =>
            s.activeForRoutes.find(
              (r: EViewRoutes) => this.currentViewRoute === r
            )
          )?.route;
        }
      })
    );
    this.info = JSON.parse(sessionStorage.getItem('appInfo'));
    if (!this.info) {
      // this is called once per session
      const calls = [
        this.infoService.getInfo(),
        this.permissionService.hasAnyPermission(EProtectedActions.distributor)
          ? this.clientService.updateDistributorClientPortfolios()
          : undefined,
      ].filter(d => d);
      combineLatest(calls)
        .subscribe(([info]) => {
          this.info = info as Info;
          sessionStorage.setItem('appInfo', JSON.stringify(info));
        });
    }
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(
        (event) => (this.currentLang = event.lang)
      ),
      this.permissionService.user$.subscribe((user) => {
        this.currentUser = user;
        this.dynamicUsers = user.dynamicUsers.map((u) => ({ ...u }))
          .sort((a, b) => {
            return a.fullname.localeCompare(b.fullname)
          });
      }),
    );
    this.permissionService
      .updateAndGetPermissions()
      .pipe(first())
      .subscribe((roles) => {
        this.hasAnyRole = roles && roles.length > 0;
        this.sidenavConfig = SIDENAV_CONFIG.map((t) => {
          if (t.title !== EViewTitles.home) {
            t.active = this.hasAnyRole;
          }
          return t;
        });
        if (this.hasAnyRole) {
          this.loadAuthorizedData();
        }
      });
  }

  private loadAuthorizedData() {
    combineLatest([
      this.guiService.getConfig(),
      this.filterService.getUserFilterConfigs(),
    ]).subscribe(([gui, userFilterConfigs]) => {
      // initialize components that behave differently with some feature flags enabled
      this.adminMenu = this.permissionService.hasAnyPermission(
        EProtectedActions.isAdmin
      );
      // gui configs
      this.guiConfig = gui;
      // filter configs
      this.filterConfigService.updateConfigFlags(userFilterConfigs);
      this.loadCurrencies();
    });
  }

  /**
   * Loads favorite currencies of current user
   * @private
   */
  private loadCurrencies() {
    this.dataService.favoriteCurrencies$.subscribe((currencies) => {
      if (currencies.length === 0) {
        this.userService.getFavoriteCurrencies().subscribe((currencies) => {
          if (currencies.length === 0) {
            this.userService.updateFavoriteCurrencies(currencies).subscribe((currencies) => {
              this.dataService.updateFavoriteCurrencies(currencies);
            });
          } else {
            this.dataService.updateFavoriteCurrencies(currencies);
          }
        });
      }
      this.favoriteCurrencies = currencies.filter(c => c.id !== this.currentUser.selectedCurrency.id);
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Navigates to provided path
   * @param route Path to navigate to
   */
  openPath(route: EViewRoutes): void {
    if (route) {
      this.globalService.navigate(route);
    }
  }

  /**
   * Signs out currently signed-in user
   */
  signOut(): void {
    this.globalService.logout();
  }

  /**
   * Triggered by lang switcher in HTML.
   * Sets selected language as current lang and saves it as a cookie
   * @param event MatSelectChange
   */
  setLanguage(event: MatSelectChange): void {
    const modalData: ModalData = {
      title: this.translateService.instant('switchLanguage?'),
      type: EModalType.confirmationDialog,
      data: {
        message: this.translateService.instant('switchLanguageMessage'),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('switchLanguage'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          modalRef.close(true);
          this.cookieService.set('selectedLang', event.value, null, '/');
          window.location.reload();
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.currentLang = this.initialLang;
          modalRef.close(false);
        },
      },
    };

    this.modalService.openConfirmationDialog(modalData);
  }

  onHelpClick(): void {
    const modalData = new ModalData(
      EModalType.help,
      { route: this.currentViewRoute, title: this.title },
      'help',
      HelpComponent
    );
    this.modalService.openDefaultDialog(modalData, 'help-dialog');
  }

  onErrorClick(): void {
    this.globalService.navigate(EViewRoutes.errors);
  }

  onSettingsClick(): void {
    this.openPath(EViewRoutes.settings);
  }

  onHomeClick(): void {
    this.openPath(EViewRoutes.home);
  }

  /**
   * Updates the dynamic users only when the user menu is closed and some changes have been detected.
   */
  updateDynamicUsers() {
    const isChanged =
      this.dynamicUsers.filter((u) => u.enabled).length !==
      this.currentUser.dynamicUsers.filter((u) => u.enabled).length;
    if (isChanged) {
      this.userService
        .updateDynamicUsers(this.dynamicUsers)
        .subscribe((updatedUsers) => {
          this.notificationService.handleSuccess(
            this.translateService.instant('bulkEditSuccess')
          );
          const user = {
            ...this.currentUser,
            dynamicUsers: updatedUsers,
          };
          this.permissionService.updateUser(user);
        });
    }
  }

  updateSelectedCurrency(c: Currency) {
    const modalData: ModalData = {
      title: this.translateService.instant('switchCurrency?'),
      type: EModalType.confirmationDialog,
      data: {
        message: this.translateService.instant('switchCurrencyMessage'),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('switchCurrency'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          modalRef.close(true);
          this.userService.updateSelectedCurrency(c).subscribe(() => {
            window.location.reload();
          });
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          modalRef.close(false);
        },
      },
    };

    this.modalService.openConfirmationDialog(modalData);
  }
}
