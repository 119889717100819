import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {
  GridFilterConfig,
  GridFilterItemComponent,
  GridFilterItemComponentFilter,
  GridFilterModelItem,
} from 'src/app/models/grid.model';
import {KeyValue} from 'src/app/models/key-value.model';
import {TranslateService} from "@ngx-translate/core";
import {of, Subject, switchMap, takeUntil} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {GridFilterOptionsParams} from "../../../../../api/core";

@Component({
  selector: 'app-grid-filter-item-text',
  templateUrl: './grid-filter-item-text.component.html',
})
export class GridFilterItemTextComponent implements OnInit, GridFilterItemComponent, OnDestroy {
  @Input() filterConfig: GridFilterConfig;
  @Input() filterModel: GridFilterModelItem;
  @Output() gridFilterItemChanged = new EventEmitter<GridFilterModelItem>();

  private ngUnsubscribe = new Subject<void>();
  autoOptions: string[];

  typeOptions: KeyValue[] = [
    {
      key: this.translateService.instant('contains'),
      value: 'contains',
    },
    // These comparators are being commented because by default we use only contains, all others are still supported
    /*{
      key: this.translateService.instant('notContains'),
      value: 'notContains',
    },
    {
      key: this.translateService.instant('equals'),
      value: 'equals',
    },
    {
      key: this.translateService.instant('notEqual'),
      value: 'notEqual',
    },
    {
      key: this.translateService.instant('startsWith'),
      value: 'startsWith',
    },
    {
      key: this.translateService.instant('endsWith'),
      value: 'endsWith',
    },*/
  ];
  textFilterForm = this.fb.group({
    type: [this.typeOptions[0].value],
    filter: [''],
  });

  constructor(
    private translateService: TranslateService,
    private fb: FormBuilder) {
  }

  ngOnInit(): void {
    if (this.filterModel) {
      this.textFilterForm.patchValue({
        type: this.filterModel.type,
        filter: this.filterModel.filter,
      });
    }
    if (this.filterConfig.autoCompleteParams) {
      this.setupAutoComplete();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private setupAutoComplete(): void {
    if ("apiMethod" in this.filterConfig.autoCompleteParams) { // server side table
      let autoCompleteParams = this.filterConfig.autoCompleteParams
      let optionGetter = (search: string) => {
        let apiParams: GridFilterOptionsParams = {
          search,
          field: autoCompleteParams.autoCompleteField,
          contextId: autoCompleteParams.autoCompleteContextId,
        };
        return autoCompleteParams.apiMethod(apiParams);
      }
      this.textFilterForm.controls.filter.valueChanges
        .pipe(
          debounceTime(300), // wait a bit if user is still typing
          switchMap(
            (value) =>
              value.length > 0 ? optionGetter(value.toLowerCase())
                : of<string[]>([])
          ),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe((options: string[]) =>
          this.autoOptions = options
        );
    } else { // client side table
      // sven: https://github.com/confinale/aspark/issues/8436 - make this configurable
      let maxOptions = 5;
      // If array: Assumed to be sorted, without *** and duplicate entries in colDef or genColumnMethod
      let sorted = Array.isArray(this.filterConfig.autoCompleteParams) ?
        this.filterConfig.autoCompleteParams :
        [...new Set(this.filterConfig.autoCompleteParams())].filter(s => s !== '***').sort()
      let optionGetter = (search: string) => {
        return of<string[]>(
          sorted
            .filter(s => s.toLowerCase().includes(search))
            .slice(0, maxOptions)
        )
      }
      this.textFilterForm.controls.filter.valueChanges
        .pipe(
          debounceTime(300), // wait a bit if user is still typing
          switchMap(
            (value) =>
              value.length > 0 ? optionGetter(value.toLowerCase())
                : of<string[]>([])
          ),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe((options: string[]) =>
          this.autoOptions = options
        );
    }
  }

  getModel(): GridFilterItemComponentFilter | undefined {
    let value = this.textFilterForm.value.filter;
    if (value && value.trim().length > 0) {
      const model = {
        ...this.filterModel,
        type: this.textFilterForm.value.type,
        filter: value,
      };
      return {config: this.filterConfig, model, form: this.textFilterForm}
    } else {
      return {config: this.filterConfig, model: undefined, form: this.textFilterForm}
    }
  }

}
