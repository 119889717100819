<ng-container *ngIf="filterForm">
  <mat-tab-group (selectedTabChange)="onTabChange($event)" [(selectedIndex)]="activeTab">
    <mat-tab
      label="{{ 'client' | translate }}"
      *ngIf="(filterCategories.client | isFilterCategoryActive) || (filterCategories.intermediaries | isFilterCategoryActive)"
    >
      <app-filter-details-client
        *ngIf="filterCategories.client | isFilterCategoryActive"
        [clientForm]="filterForm.client"
        [readOnly]="readOnly"
        [isTriggerStory]="isTriggerStoryPath()"
      ></app-filter-details-client>
      <app-filter-details-intermediary
        *ngIf="filterCategories.intermediaries | isFilterCategoryActive"
        [intermediariesForm]="filterForm.intermediaries"
        [readOnly]="readOnly"
      ></app-filter-details-intermediary>
    </mat-tab>
    <mat-tab *ngIf="filterCategories.portfolio | isFilterCategoryActive">
      <ng-template mat-tab-label>
        <span
          [class.color-error]="
            filterForm.portfolio?.formHasErrors
          "
          >{{ 'portfolio' | translate }}</span
        >
        <ng-container *ngIf="filterForm.portfolio?.formHasErrors">
          <app-config-icon
            iconIdentifier="error"
            class="color-error"
          ></app-config-icon>
        </ng-container>
      </ng-template>
      <app-filter-details-portfolio
        [portfolioForm]="filterForm.portfolio"
        [storyId]="modalData?.data?.currentStoryId"
        [readOnly]="readOnly"
      ></app-filter-details-portfolio>
    </mat-tab>
    <mat-tab *ngIf="filterCategories.position | isFilterCategoryActive">
      <ng-template mat-tab-label>
        <span
          [class.color-error]="
            filterForm.position?.formHasErrors
          "
          >{{ 'position' | translate }}</span
        >
        <ng-container *ngIf="filterForm.position?.formHasErrors">
          <app-config-icon
            iconIdentifier="error"
            class="color-error"
          ></app-config-icon>
        </ng-container>
      </ng-template>
      <app-filter-details-position
        [positionForm]="filterForm.position"
        [readOnly]="readOnly"
      ></app-filter-details-position>
    </mat-tab>
    <mat-tab *ngIf="filterCategories.assets | isFilterCategoryActive">
      <ng-template mat-tab-label>
        <span
          [class.color-error]="
            assetsFormHasErrors
          "
          >{{ 'asset' | translate }}</span
        >
        <ng-container *ngIf="assetsFormHasErrors">
          <app-config-icon
            iconIdentifier="error"
            class="color-error"
          ></app-config-icon>
        </ng-container>
      </ng-template>
      <app-filter-details-asset
        [filterForm]="filterForm.assets"
        [readOnly]="readOnly"
        [isTriggerStory]="isTriggerStoryPath()"
      ></app-filter-details-asset>
    </mat-tab>
    <mat-tab
      label="{{ 'assetClass' | translate }}"
      *ngIf="filterCategories.assetClass | isFilterCategoryActive"
    >
      <app-filter-details-asset-class
        [filterForm]="filterForm.assetClass"
        [readOnly]="readOnly"
      ></app-filter-details-asset-class>
    </mat-tab>
    <mat-tab
      label="{{ 'currency' | translate }}"
      *ngIf="filterCategories.currency | isFilterCategoryActive"
    >
      <app-filter-details-currency
        [filterForm]="filterForm.currency"
        [readOnly]="readOnly"
      ></app-filter-details-currency>
    </mat-tab>
    <mat-tab
      label="{{ 'region' | translate }}"
      *ngIf="filterCategories.region | isFilterCategoryActive"
    >
      <app-filter-details-region
        [filterForm]="filterForm.region"
        [readOnly]="readOnly"
      ></app-filter-details-region>
    </mat-tab>
    <mat-tab
      label="{{ 'sector' | translate }}"
      *ngIf="filterCategories.sector | isFilterCategoryActive"
    >
      <app-filter-details-sector
        [filterForm]="filterForm.gics"
        [readOnly]="readOnly"
      ></app-filter-details-sector>
    </mat-tab>
    <mat-tab label="{{'orgPosition' | translate }}" *ngIf="filterCategories.orgPosition | isFilterCategoryActive">
      <app-filter-details-org-positions
        [positionsForm]="filterForm.orgPosition"
        [readOnly]="readOnly"
      ></app-filter-details-org-positions>
    </mat-tab>
    <!-- DEMO: Used only for trigger stories -->
    <mat-tab
      label="{{ 'products' | translate }}"
      *ngIf="isTriggerStoryPath()"
      [aria-label]="'products'"
    >
      <app-demo-filter-details-product>
      </app-demo-filter-details-product>
    </mat-tab>
    <mat-tab
      label="{{ 'campaignLinks' | translate }}"
      *ngIf="isTriggerStoryPath()"
      aria-label="{{ 'campaignLinks' | translate }}"
    >
      <app-demo-filter-details-campaign-link>
      </app-demo-filter-details-campaign-link>
    </mat-tab>
    <mat-tab
      label="{{ 'marketingLists' | translate }}"
      *ngIf="isTriggerStoryPath()"
      aria-label="{{ 'marketingLists' | translate }}"
    >
      <app-demo-filter-details-marketing-link>
      </app-demo-filter-details-marketing-link>
    </mat-tab>
    <!-- DEMO-END -->
    <mat-tab
      label="{{ 'advanced' | translate }}"
      *ngIf="!isTriggerStoryPath() && filterCategories.advanced | isFilterCategoryActive"
      [aria-label]="filterCategories.advanced"
    >
      <app-filter-details-advanced
        #advFilter
        [filterForm]="filterForm"
        [readOnly]="readOnly"
      ></app-filter-details-advanced>
    </mat-tab>
  </mat-tab-group>
</ng-container>
