import {Component} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {AgRendererComponent} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-enterprise";

@Component({
  template: `
    <ng-container *ngIf="!this.closed">
      <div class="filter-item" style="min-width: 0">
        <span style="text-overflow: ellipsis; overflow: hidden">
          {{ valueFormatted }}
        </span>
      </div>
    </ng-container>
    <ng-container *ngIf="this.closed">
      <div class="filter-item" matTooltip="{{ 'closedCodeTableWarning' | translate }}">
        <span class="color-warning" style="text-overflow: ellipsis; overflow: hidden">
          {{ valueFormatted }}
        </span>
        <app-config-icon
          iconIdentifier="report"
          class="color-warning"
        ></app-config-icon>
      </div>
    </ng-container>
  `,
})
export class CodeTableFilterCellRenderer implements AgRendererComponent {
  item: any;
  closed: boolean;
  valueFormatted: string;

  constructor(
    private translateService: TranslateService
  ) {}

  agInit(params: ICellRendererParams): void {
    this.item = params.value;

    this.closed = this.item?.closed === true;
    if (this.item === null) {
      this.valueFormatted = '('+this.translateService.instant('blank')+')';
    } else {
      this.valueFormatted = params.valueFormatted;
    }
  }

  refresh(params: any): boolean {
    return false;
  }
}
