import {Component, OnDestroy, ViewChild} from "@angular/core";
import {ColDef, GridOptions, ITooltipParams} from "ag-grid-enterprise";
import {genCodeTableColumn, genTextColumn} from "../../../../../util/grid/grid-renderer.util";
import {I18n} from "../../../../../services/i18n.service";
import {CodeTableEntry, MonitoringBucket, MonitoringService} from "../../../../../api/core";
import {genIconButtonColumn} from "../../../../../shared/grid/cell-renderers/icon-button.renderer";
import {CodeTableService} from "../../../../../services/code-table.service";
import {ECodeTables, EModalType} from "../../../../../util/enum";
import {TranslateService} from "@ngx-translate/core";
import {GridDataProvider} from "../../../../../shared/grid/data-source";
import {GridReadyEvent} from "ag-grid-community";
import {combineLatest, Subscription} from "rxjs";
import {ModalData} from "../../../../../models/modal.model";
import {BucketDialogComponent} from "../bucket-dialog/bucket-dialog.component";
import {DialogWidth, ModalService} from "../../../../../services/modal.service";
import {GridComponent} from "../../../../../shared/grid/grid.component";
import {MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../../../shared/modal/modal.component";

@Component({
  selector: 'app-bucket-details',
  templateUrl: './bucket-details.component.html'
})
export class BucketDetailsComponent implements OnDestroy {
  @ViewChild('grid') grid: GridComponent;
  hubs: CodeTableEntry[] = [];
  publicationTypes: CodeTableEntry[] = [];

  columnDefs: ColDef[] = [
    genIconButtonColumn({
      callback: (data: MonitoringBucket) => this.onEdit(data),
      icon: 'edit_m',
      suppressMenu: true,
      tooltip: this.translateService.instant('edit'),
    }),
    genTextColumn('name', I18n.getColName('name')),
    {
      ...genCodeTableColumn({
        field: 'hub',
        headerName: I18n.getColName('hub'),
        observable: this.codeTableService.getCodeTable(ECodeTables.hub),
      }),
    },
    {
      ...genCodeTableColumn({
        field: 'publicationTypes',
        headerName: I18n.getColName('publicationType'),
        observable: this.codeTableService.getCodeTable(ECodeTables.publicationType),
      }),
      valueFormatter: (params) =>
        (params.value?.map((pt: CodeTableEntry) => pt.name).join(', ')),
      sortable: false, // Not specified how to sort this column -> disabled for now
      cellStyle:  { 'text-overflow':'ellipsis','white-space':'nowrap', 'overflow': 'hidden' },
      tooltipValueGetter: (params: ITooltipParams<any>) =>
        params.data.publicationTypes
          .map((pt) => pt.name)
          .join('\r, '),
    },
    genIconButtonColumn({
      callback: (data: MonitoringBucket) => this.onDelete(data),
      icon: 'delete',
      suppressMenu: true,
      tooltip: this.translateService.instant('delete'),
    }),
  ];

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent<MonitoringBucket>) => {
      this.subscriptions.push(
        I18n.getColumns(this.translateService, event.api)
      );
    }
  };

  dataProvider: GridDataProvider;
  subscriptions: Subscription[] = [];

  constructor(
    protected readonly codeTableService: CodeTableService,
    protected readonly translateService: TranslateService,
    protected readonly monitoringService: MonitoringService,
    protected readonly modalService: ModalService,
  ) {
    combineLatest([
      codeTableService.getCodeTable(ECodeTables.publicationType),
      codeTableService.getCodeTable(ECodeTables.hub)
    ]).subscribe(([publicationTypes, hubs]) => {
      this.publicationTypes = publicationTypes;
      this.hubs = hubs;
    });
    this.dataProvider = monitoringService.getBuckets.bind(monitoringService);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onAdd() {
    const modalData: ModalData = {
      type: EModalType.bucketAdd,
      title: EModalType.bucketAdd,
      submitBtn: {
        label: this.translateService.instant('create'),
      },
      data: {
        bucket: {
          name: '',
          hub: null,
          publicationTypes: []
        },
        hubs: this.hubs,
        publicationTypes: this.publicationTypes,
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: BucketDialogComponent,
    };
    const ref = this.modalService.openDefaultDialog(
      modalData,
      undefined,
      true,
      false,
      DialogWidth.AUTO
    );
    ref.afterClosed().subscribe((result: MonitoringBucket | null) => {
      if (!result) return;
      this.monitoringService.createBucket(result).subscribe(_ => {
        this.grid.reload();
      });
    });
  }

  onEdit(data: MonitoringBucket) {
    const modalData: ModalData = {
      type: EModalType.bucketEdit,
      title: EModalType.bucketEdit,
      submitBtn: {
        label: this.translateService.instant('save'),
      },
      data: {
        bucket: data,
        hubs: this.hubs,
        publicationTypes: this.publicationTypes,
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: BucketDialogComponent,
    };
    const ref = this.modalService.openDefaultDialog(
      modalData,
      undefined,
      true,
      false,
      DialogWidth.AUTO
    );
    ref.afterClosed().subscribe((result: MonitoringBucket | null) => {
      if (!result) return;
      this.monitoringService.editBucketById(result.id, result).subscribe(_ => {
        this.grid.reload();
      });
    });
  }

  onDelete(data: MonitoringBucket) {
    const modalData: ModalData = {
      type: EModalType.confirmationDialog,
      title: EModalType.bucketDelete,
      component: null,
      data: {
        message: this.translateService.instant('bucketDeleteMessage'),
      },
      submitBtn: {
        label: this.translateService.instant('delete'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.monitoringService.deleteBucketById(data.id)
            .subscribe(() => {
              this.grid.reload();
              modalRef.close();
            });
        }
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    }
    this.modalService.openConfirmationDialog(modalData);
  }
}
