import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {Content, ContentAttachment, Field, Gui, Language,} from 'src/app/api/core';
import {EFormStatus, EFormValidators,} from 'src/app/util/enum';
import {PermissionService} from 'src/app/services/permission.service';
import {Subscription} from 'rxjs';
import {EProtectedActions} from '../../util/protected-actions';
import {FROALA_LICENSE} from "../../../main";

const MAPPED_TYPES = {
  'title': Field.TypeEnum.TITLE,
  'intro': Field.TypeEnum.INTRO,
  'outro': Field.TypeEnum.OUTRO,
  'contentAbstract': Field.TypeEnum.ABSTRACT,
  'description': Field.TypeEnum.DESCRIPTION,
  'media': Field.TypeEnum.MEDIA,
  'mediaDescription': Field.TypeEnum.MEDIADESCRIPTION,
  'url': Field.TypeEnum.URL,
  'urlDescription': Field.TypeEnum.URLDESCRIPTION,
  'investmentCase': Field.TypeEnum.INVESTMENTCASE,
  'disclaimer': Field.TypeEnum.DISCLAIMER,
}
/**
 * Component to content form
 */
@Component({
  selector: 'app-content-form-fields',
  templateUrl: './content-form-fields.component.html',
})
export class ContentFormFieldsComponent implements OnInit, OnDestroy {
  @Input()
  parentId: number;
  @Input()
  language: Language;
  @Input()
  isCustomContent: boolean;
  @Input()
  isEditable: boolean;
  @Input()
  content: Content;
  @Input()
  attachments: ContentAttachment[];
  @Input()
  guiConfig: Gui;
  contentForm: FormGroup;
  @Output()
  formValidityUpdate = new EventEmitter<boolean>();
  formStatusSubscription: Subscription;
  showWarning = false;
  canEdit: boolean;
  warningMessage: string;
  disabled: boolean;

  editorOptions = {
    key: FROALA_LICENSE,
    attribution: false,
    heightMin: 400,
    heightMax: 401, // 400 adds a scrollbar
    toolbarSticky: false,
    quickInsertButtons: ['image',/* 'video', 'embedly',*/ 'table', 'ul', 'ol', 'hr'],
    toolbarButtons: [
      'fullscreen',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'subscript',
      'superscript',
      '|',
      'fontFamily',
      'fontSize',
      'textColor',
      'inlineStyle',
      'paragraphStyle',
      '|',
      'paragraphFormat',
      'align',
      'formatOL',
      'formatUL',
      'outdent',
      'indent',
      'quote',
      '-',
      'insertLink',
      'insertImage',
      // 'insertVideo',
      // 'insertFile',
      'insertTable',
      '|',
      // 'emoticons',
      'specialCharacters',
      'insertHR',
      'selectAll',
      'clearFormatting',
      // '|',
      // 'print',
      // 'help',
      // 'html',
      '|',
      'undo',
      'redo',
      // 'trackChanges',
      // 'markdown'
    ],
    events: {
      // Handle images as base 64 and avoid uploading it
      "image.beforeUpload": function(files) {
        const editor = this;
        if (files.length) {
          // Create a File Reader.
          const reader = new FileReader();
          // Set the reader to insert images when they are loaded.
          reader.onload = function(e) {
            var result = e.target.result;
            editor.image.insert(result, null, null, editor.image.get());
          };
          // Read image as base64.
          reader.readAsDataURL(files[0]);
        }
        editor.popups.hideAll();
        // Stop default upload chain.
        return false;
      }
    }
  }

  constructor(
    private fb: FormBuilder,
    private permissionService: PermissionService,
  ) {
  }

  ngOnInit(): void {
    this.canEdit = this.permissionService.hasAnyPermission(
      EProtectedActions.editCampaignContents,
      EProtectedActions.editStoryContents,
      EProtectedActions.editCampaignCustomContent,
    );

    this.buildForm();

    this.disabled = !this.isEditable;
    if (this.disabled) {
      setTimeout(() => {
        this.contentForm.disable();
      });
    }

    if (this.content) {
      this.contentForm.patchValue({
        ...this.withValuesFromContent(this.content),
      });
      this.warningMessage = 'warningWrongLanguage';
    } else {
      this.warningMessage = 'warningNoContent';
    }

    this.showWarning =
      this.isCustomContent &&
      this.language.id !== this.content?.language.id;
    this.formStatusSubscription = this.contentForm.statusChanges.subscribe(
      (status) => {
        this.formValidityUpdate.emit(status === EFormStatus.VALID);
      }
    );
  }

  ngOnDestroy(): void {
    this.formStatusSubscription.unsubscribe();
  }

  get formValidators() {
    return EFormValidators;
  }

  private buildForm(): void {
    const canEditIntroOutro = this.permissionService.hasAnyPermission(
      EProtectedActions.editCampaignIntroOutro,
      EProtectedActions.editStoryIntroOutro,
    );
    this.contentForm = this.fb.group({
      id: [null],
      language: [null],
      contentAbstract: new FormControl({
        value: null,
        disabled: this.isContentFieldDisabled('customEditAbstract'),
      }),
      description: new FormControl({
        value: null,
        disabled: this.isContentFieldDisabled('customEditDescription'),
      }),
      investmentCase: new FormControl({
        value: null,
        disabled: this.isContentFieldDisabled('customEditInvestmentCase'),
      }),
      title: new FormControl({
          value: '', disabled: this.isContentFieldDisabled('customEditTitle')
        },
        [
          Validators.required,
          Validators.pattern(EFormValidators.textFieldNotBlankPattern),
          Validators.maxLength(1024),
        ]
      ),
      url: new FormControl(
        {
          value: '',
          disabled: this.isContentFieldDisabled('customEditUrl'),
        },
        Validators.maxLength(EFormValidators.textFieldMaxLength)
      ),
      urlDescription: new FormControl({
        value: null,
        disabled: this.isContentFieldDisabled('customEditUrl'),
      }, [
        Validators.maxLength(1024)
      ]),
      media: new FormControl({
          value: '', disabled: this.isContentFieldDisabled('customEditMedia')
        },
        Validators.maxLength(EFormValidators.textFieldMaxLength)
      ),
      mediaDescription: new FormControl({
        value: null,
        disabled: this.isContentFieldDisabled('customEditMedia'),
      }, [
        Validators.maxLength(1024)
      ]),
      intro: new FormControl({
        value: null,
        disabled: !canEditIntroOutro && this.isContentFieldDisabled('customEditIntro'),
      }),
      outro: new FormControl({
        value: null,
        disabled: !canEditIntroOutro && this.isContentFieldDisabled('customEditOutro'),
      }),
      disclaimer: new FormControl({
        value: null,
        disabled: this.isContentFieldDisabled('customEditDisclaimer'),
      }),
    });
  }

  private isContentFieldDisabled(field: string) {
    return !this.canEdit || (this.isCustomContent && this.guiConfig.content[field] === false);
  }

  /**
   * Creates a new object with the values from the content, then this will be
   * used to update the values from the form.
   * @param content
   */
  withValuesFromContent(content: Content): any {
    const result = {id: content?.id, language: content?.language} as any;
    for (const field of content.fields) {
      let strProp = Object.keys(MAPPED_TYPES).find((key) => MAPPED_TYPES[key] === field.type);
      result[strProp] = field.value;
    }
    return result;
  }

  /**
   * Creates a new content object with the updated values from the input.
   * @param content
   * @param input
   */
  withUpdatedContentValues(content: Content, input: any): Content {
    const result = content ?
      JSON.parse(JSON.stringify(content)) as Content
      : {fields: []} as Content;
    for (const [key, value] of Object.entries(input)) {
      let type: Field.TypeEnum = MAPPED_TYPES[key];

      if (type) {
        const field = result.fields.find((f) => f.type === type);
        const valStr = (value as string) || '';
        if (field) {
          field.value = valStr;
        } else {
          result.fields.push({id: null, isRichText: this.isRichText(type), type, value: valStr});
        }
      }
    }
    return result;
  }

  isRichText(fieldType: Field.TypeEnum) {
    // TODO(timoteo) move this to a configurable part on the future, when we switch to dynamic fields
    if ([Field.TypeEnum.INTRO, Field.TypeEnum.OUTRO].includes(fieldType)) {
      return true;
    }
    return this.content?.fields.find((f) => f.type === fieldType)?.isRichText;
  }

  protected readonly Field = Field;
}
