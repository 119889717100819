import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {filter, first} from 'rxjs/operators';
import {GlobalService} from './services/global.service';
import {ConfigService} from './services/config.service';
import {CookieService} from 'ngx-cookie-service';
// util
import {environment} from '../environments/environment';
import {Params} from '@angular/router';
import {EViewRoutes} from './util/enum';
import {PermissionService} from './services/permission.service';

/**
 * App Component.
 * Main component for the app
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private oauthService: OAuthService,
    private globalService: GlobalService,
    translate: TranslateService,
    private configService: ConfigService,
    private cookieService: CookieService,
    private permissionService: PermissionService,
  ) {
    // SETTING LANGUAGE
    translate.addLangs(environment.AVAIL_LANGS);
    translate.setDefaultLang(environment.DEFAULT_LANG);

    const browserLang = translate.getBrowserLang();
    const selectedLangCookie = this.cookieService.get('selectedLang');
    if (selectedLangCookie) {
      translate.use(selectedLangCookie);
    } else {
      translate.use(
        browserLang.match(/en|de/) ? browserLang : environment.DEFAULT_LANG
      );
    }

    // Automatically load user profile
    this.oauthService.events
      .pipe(
        filter((e) => e.type === 'token_received'),
        first()
      )
      .subscribe(() => {
        //   this.oauthService.loadUserProfile().then((r) => {
        //     this.globalService.loadedProfile();
        //   });

        const scopes = this.oauthService.getGrantedScopes();
        const forwardUrl = sessionStorage.getItem('forwardUrl');
        this.permissionService
          .updateAndGetPermissions()
          .pipe(first())
          .subscribe((roles) => {
            if (
              roles.length > 0 &&
              forwardUrl &&
              forwardUrl !== EViewRoutes.login
            ) {
              this.navigateToForwardUrl(forwardUrl);
            } else {
              this.globalService.toHome();
            }
          });
        sessionStorage.removeItem('forwardUrl');
      });
  }

  ngOnInit() {
    this.configureCodeFlow();
  }

  ngOnDestroy(): void {}

  // REMOVE AGAIN WHEN AUTH CONFIG IS ADJUSTED BY FLO
  private configureCodeFlow() {
    this.oauthService.configure(this.configService.getAuthConfig());
    this.oauthService.loadDiscoveryDocumentAndTryLogin();

    // Optional
    this.oauthService.setupAutomaticSilentRefresh();
  }

  private navigateToForwardUrl(forwardUrl: string): void {
    const queryParamIndex = forwardUrl.indexOf('?');
    const fragmentIndex = forwardUrl.indexOf('#');
    let splitUrl: string[];
    if (queryParamIndex !== -1 && fragmentIndex !== -1) {
      let queryParams;
      let fragment;
      if (queryParamIndex < fragmentIndex) {
        splitUrl = forwardUrl.split('?');
        queryParams = splitUrl[1].split('#')[0];
        fragment = splitUrl[1].split('#')[1];
      } else {
        splitUrl = forwardUrl.split('#');
        queryParams = splitUrl[1].split('?')[1];
        fragment = splitUrl[1].split('?')[0];
      }
      this.globalService.navigate(
        splitUrl[0],
        this.parseQueryParams(queryParams),
        fragment
      );
    } else if (queryParamIndex !== -1) {
      splitUrl = forwardUrl.split('?');
      this.globalService.navigate(
        splitUrl[0],
        this.parseQueryParams(splitUrl[1])
      );
    } else if (fragmentIndex !== -1) {
      splitUrl = forwardUrl.split('#');
      this.globalService.navigate(splitUrl[0], undefined, splitUrl[1]);
    } else {
      this.globalService.navigate(forwardUrl);
    }
  }

  private parseQueryParams(queryParamsString: string): Params {
    const newParams = {};
    queryParamsString.split('&').forEach((paramPair) => {
      const pairSplit = paramPair.split('=');
      newParams[pairSplit[0]] = pairSplit[1];
    });
    return newParams;
  }
}
