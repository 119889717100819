import {TranslateService} from '@ngx-translate/core';
import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ColDef, GridOptions} from 'ag-grid-enterprise';
import {GridApi, GridReadyEvent} from 'ag-grid-community';
import {Subscription} from 'rxjs';
import {
  Campaign,
  CampaignAction,
  CodeTableEntry,
  Product,
  SuitabilityState,
  SuitabilityStatus
} from "../../../../api/core";
import {genIconButtonColumn} from "../../../../shared/grid/cell-renderers/icon-button.renderer";
import {CodeTableService} from "../../../../services/code-table.service";
import {genSuitabilityColumn, getIconAndColor} from "../../../../shared/grid/cell-renderers/suitability.renderer";
import {genCodeTableColumn, genPercentageNumberColumn, genTextColumn} from "../../../../util/grid/grid-renderer.util";
import {ECodeTables} from "../../../../util/enum";
import {FormBuilder} from "@angular/forms";
import {DemoService} from "../../../../demo/demo.service";
import {GlobalService} from "../../../../services/global.service";

interface ProductWithSuitability extends Product {
    suitability: SuitabilityStatus;
}

@Component({
  selector: 'app-campaign-product-matching',
  templateUrl: './campaign-product-matching.component.html',
})
export class CampaignProductMatchingComponent implements OnDestroy {

  selected: ProductWithSuitability[] = [];
  selectedApi: GridApi<ProductWithSuitability>;
  availableGridApi: GridApi<ProductWithSuitability>;

  productsColDefs: ColDef[] = [
    {
      checkboxSelection: true,
      floatingFilter: false,
      sortable: false,
      lockPosition: 'left',
      suppressMovable: true,
      suppressColumnsToolPanel: true,
      suppressMenu: true,
      resizable: true,
      width: 20,
    },
    genTextColumn('name', this.translateService.instant('name')),
    genTextColumn('isin', this.translateService.instant('isin')),
    genCodeTableColumn({
      field: 'type',
      headerName: this.translateService.instant('type'),
      observable: this.codeTableService.getCodeTable(ECodeTables.assetType),
    }),
    // genTranslatedEnumColumn({
    //   field: 'type',
    //   values: this.fetchAssetTypes.bind(this),
    //   headerName: this.translateService.instant('type'),
    //   data: () => this.assetTypes,
    //   needsValueGetter: true,
    // }),
    genTextColumn('currency.code', this.translateService.instant('currency')),
    {
      ...genSuitabilityColumn({
        translateService: this.translateService,
        field: 'suitability.state',
        stateInfo: (data: ProductWithSuitability) => ({
          state: data.suitability.state,
        }),
        headerName: this.translateService.instant('state'),
        callback: () => {},
      }),
      suppressMenu: true,
    },
    genPercentageNumberColumn('' +
      'score',
      this.translateService.instant('demoAttractivenessScore'),
      this.globalService,
      true,
      false,
      {},
      data => {
        return this.demoService.getAttractivenessScore(
          this.campaignAction, data.data
        );
      }
    ),
  ];

  selectedColDefs: ColDef[] = [
    genTextColumn('name', this.translateService.instant('name')),
    genTextColumn('isin', this.translateService.instant('isin')),
    genCodeTableColumn({
      field: 'type',
      headerName: this.translateService.instant('type'),
      observable: this.codeTableService.getCodeTable(ECodeTables.assetType),
    }),
    // genTranslatedEnumColumn({
    //   field: 'type',
    //   values: this.fetchAssetTypes.bind(this),
    //   headerName: this.translateService.instant('type'),
    //   data: () => this.assetTypes,
    //   needsValueGetter: true,
    // }),
    genTextColumn('currency.code', this.translateService.instant('currency')),
    {
      ...genSuitabilityColumn({
        translateService: this.translateService,
        field: 'suitability.state',
        stateInfo: (data: ProductWithSuitability) => ({
          state: data.suitability.state,
        }),
        headerName: this.translateService.instant('state'),
        callback: () => {},
      }),
      suppressMenu: true,
    },
    {
      ...genIconButtonColumn({
        callback: (data: Product) => this.deleteEntry(data),
        icon: 'delete',
        suppressMenu: true,
        tooltip: this.translateService.instant('removeProduct'),
      }),
      floatingFilter: false,
      colId: 'icon-button-delete',
    },
  ];

  selectedGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent) => {
      this.gridReadySelected(event);
    },
  };

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    onGridReady: (event: GridReadyEvent) => {
      this.gridReady(event);
    },
    getRowId: (params) => params.data.id,
  };

  suitabilities = [
    SuitabilityState.OK, SuitabilityState.CONFIRM, SuitabilityState.PENDING, SuitabilityState.ERROR,
    SuitabilityState.UNKNOWN, SuitabilityState.NOTOK, SuitabilityState.RESTRICTED]
      .map((d) => {
        const iconAndColor = getIconAndColor(d);
        return {value: d.toLowerCase(), icon: iconAndColor.icon, color: iconAndColor.colorClass};
      });
  suitability = this.suitabilities[0];
  fundRef = false;
  scoreMatching = false;

  formFields = this.fb.group({
    fundRef: this.fundRef,
    suitability: this.suitability,
    scoreMatching: this.scoreMatching,
  })

  buyProducts: ProductWithSuitability[];
  campaignAction: CampaignAction;
  subscriptions: Subscription[] = [];

  assetTypes: CodeTableEntry[];

  constructor(
    readonly fb: FormBuilder,
    readonly translateService: TranslateService,
    readonly codeTableService: CodeTableService,
    readonly demoService: DemoService,
    readonly globalService: GlobalService,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: { campaignAction: CampaignAction, campaign: Campaign }}
  ) {
    this.campaignAction = data.data.campaignAction;
    this.buyProducts = data.data.campaign.buyProducts.map((p) => {
      return {...p, suitability: {state: SuitabilityState.OK, client: data.data.campaignAction.client}};
    });
    this.formFields.valueChanges.subscribe((data) => {
      this.fundRef = data.fundRef;
      this.suitability = this.suitabilities.find((s) => s.value === data.suitability.value);
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  gridReady(event: GridReadyEvent) {
    this.availableGridApi = event.api;
  }

  gridReadySelected(event: GridReadyEvent) {
    this.selectedApi = event.api;
  }

  canAdd(): boolean {
    return !!this.availableGridApi?.getSelectedRows()?.length;
  }

  addSelection() {
    if (!this.availableGridApi) {
      return;
    }
    const selected = this.availableGridApi.getSelectedRows();
    const toAdd = selected
      .filter(p => !this.selected.find(sp => sp.ident === p.ident));
    this.selected = [...this.selected, ...toAdd];
    this.availableGridApi.forEachNode(node => node.setSelected(false));
  }

  private deleteEntry(entry: Product) {
    this.selected = this.selected
        .filter(p => !(p.ident === entry.ident));
  }

  private fetchAssetTypes(params: any): void {
    this.codeTableService
      .getCodeTable(ECodeTables.assetType)
      .subscribe((data) => {
        this.assetTypes = data;
        params.success(data.map((x) => x.id));
      });
  }

  applyFundMatching() {
    if (this.fundRef) {
      this.availableGridApi.forEachNode(node => node.setSelected(false));
      this.selected = this.buyProducts
          .filter((p) => {
            if (p.type.ident === 'fund') {
              return p.currency.code === this.campaignAction.portfolio.referenceCurrency.code
            }
            return true;
          });
    }
  }
}
