import {TranslateService} from '@ngx-translate/core';
import {ColDef, GridApi} from 'ag-grid-community';
import {Subscription} from 'rxjs';

const LANG_PREFIX = '.';

export class I18n {
  static getColName(name: string): string {
    return LANG_PREFIX + name;
  }

  static hasColName(name: string): boolean {
    return !!name && name.slice(0, 1) === LANG_PREFIX;
  }

  static getColumns(translateService: TranslateService, api: GridApi): Subscription {
    const currentLang = translateService.currentLang;
    const translations = translateService.store.translations;
    if (translations && translations[currentLang]) {
      I18n.translateColumns(translateService, api);
    }
    const subscriptions = [
      translateService.onLangChange.subscribe(ev => {
        I18n.translateColumns(translateService, api);
      }),
      translateService.onDefaultLangChange.subscribe(ev => {
        I18n.translateColumns(translateService, api);
      }),
      translateService.onTranslationChange.subscribe(ev => {
        I18n.translateColumns(translateService, api);
      }),
    ];
    return {
      unsubscribe: () => {
        subscriptions.forEach(s => s.unsubscribe());
      }
    } as Subscription;
  }

  private static translateColumns(translateService: TranslateService, api: GridApi) {
    const colDefs = api.getColumnDefs();
    let changed = false;
    colDefs.forEach(col => {
      const aCol = col as (ColDef & { _headerName: string });
      const headerName = aCol._headerName || col.headerName;
      if (headerName && I18n.hasColName(headerName)) {
        aCol.headerName = translateService.instant(headerName.slice(1));
        aCol._headerName = headerName;
        changed = true;
      }
    });
    if (changed) {
      api.setGridOption("columnDefs", [...colDefs]);
    }
  }
}
