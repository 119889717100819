import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'src/app/shared/shared.module';
import {HomeComponent} from './views/home/home.component';
import {LoginComponent} from './views/login/login.component';
import {LicenseComponent} from './views/license/license.component';
import {CallbackComponent} from './views/callback/callback.component';
import {ErrorCatalogueComponent} from './views/error-catalogue/error-catalogue.component';
import {ErrorOverviewComponent} from './views/error-overview/error-overview.component';
import {SettingsComponent} from './views/settings/settings.component';
import {RouterLink} from '@angular/router';
import {SalutationsComponent} from './views/settings/salutations/salutations.component';
import {SalutationDetailsComponent} from './views/settings/salutations/salutation-details/salutation-details.component';
import {TemplateDefaultsComponent} from './views/settings/template-defaults/template-defaults.component';
import {
  TemplateDefaultDetailsComponent
} from './views/settings/template-defaults/template-default-details/template-default-details.component';
import {SalutationPreviewComponent} from './views/settings/salutations/salutation-preview/salutation-preview.component';
import {IconsComponent} from './views/icons/icons.component';
import {ClientModule} from "../client/client.module";
import {PortfolioModule} from "../portfolio/portfolio.module";
import {ProductModule} from "../product/product.module";
import {BucketDetailsComponent} from "./views/settings/buckets/bucket-details/bucket-details.component";
import {BucketDialogComponent} from "./views/settings/buckets/bucket-dialog/bucket-dialog.component";
import {HomeBestActionsComponent} from "./views/home/home-best-actions/home-best-actions.component";
import {HomeChartComponent} from "./views/home/home-chart/home-chart.component";
import {DistributorModule} from "../distributor/distributor.module";
import {FilterConfigsComponent} from "./views/settings/filter-configs/filter-configs.component";
import {CcFrameworkComponent} from "./views/settings/cc-framework/cc-framework.component";
import {EmailDialogComponent} from "./views/settings/email-dialog/email-dialog.component";

/**
 * Core Module.
 * Contains core components Home, Login and Notifier.
 */
@NgModule({
  declarations: [
    ErrorCatalogueComponent,
    ErrorOverviewComponent,
    HomeComponent,
    LoginComponent,
    LicenseComponent,
    SettingsComponent,
    SalutationsComponent,
    SalutationDetailsComponent,
    SalutationPreviewComponent,
    TemplateDefaultsComponent,
    TemplateDefaultDetailsComponent,
    FilterConfigsComponent,
    CallbackComponent,
    IconsComponent,
    BucketDetailsComponent,
    BucketDialogComponent,
    HomeBestActionsComponent,
    HomeChartComponent,
    CcFrameworkComponent,
    EmailDialogComponent,
  ],
  imports: [CommonModule, SharedModule, RouterLink, ClientModule, PortfolioModule, ProductModule, DistributorModule],
  exports: [],
})
export class CoreModule {
}
