<div class="table-selection-bar">
  <mat-label for="selectAllCheckbox" class="checkbox-label" *ngIf="itemLabelRef"
    >{{ itemLabelRef | translate }}:&nbsp;
  </mat-label>
  <mat-checkbox
    class="select-all-checkbox"
    #selectAllCheckbox
    color="primary"
    [checked]="allSelected()"
    [indeterminate]="someSelected()"
    (click)="togglePageSelection($event)"
    [matTooltip]="getTooltip()"
  ></mat-checkbox>
  <div class="count-label">
    <span
      *ngIf="selectedValues.length === 1 && labelRefs.singular"
      class="label"
    >
      {{ selectedValues.length }} {{ labelRefs.singular | translate }}
    </span>
    <span *ngIf="selectedValues.length > 1 && labelRefs.plural">
      {{ selectedValues.length }} {{ labelRefs.plural | translate }}
    </span>
  </div>
  <app-dropdown-selection-bar
    *ngIf="actionType === ActionType.CampaignAction"
    #selectionDropdown
    useDropdownSelect="true"
    class="icon-text-btn"
    title="{{ 'selection' | translate }}"
    [actions]="[]"
    [hiddenActions]="selectionHiddenActions"
    (clearSelection)="clearSelection()"
  ></app-dropdown-selection-bar>
  <ng-container *ngIf="isHierarchyList">
    <div
      class="icon-text-btn"
      (click)="expandAll.emit(true)"
      [matTooltip]="'expandAll' | translate"
    >
      <app-config-icon iconIdentifier="expand"></app-config-icon>
      <span>{{ 'expand' | translate }}</span>
    </div>
    <div
      class="icon-text-btn"
      (click)="collapseAll.emit(true)"
      [matTooltip]="'collapseAll' | translate"
    >
      <app-config-icon iconIdentifier="compress"></app-config-icon>
      <span>{{ 'collapse' | translate }}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="selectedValues.length > 0">
    <div
      class="icon-text-btn error-btn"
      (click)="clearSelection()"
      [matTooltip]="'clearSelection' | translate"
    >
      <app-config-icon iconIdentifier="clear"></app-config-icon>
      <span class="body-2">{{ 'clear' | translate }}</span>
    </div>
    <app-dropdown-selection-bar
      title="{{ 'actions' | translate }}"
      [actions]="actions"
      [hiddenActions]="hiddenActions"
    ></app-dropdown-selection-bar>
  </ng-container>
  <app-grid-filter
    *ngIf="featureDialogFilters && gridApi"
    [gridApi]="gridApi"
    [dialogTitle]="'filters' | translate"
    [filterModelSubject]="filterModelSubject"
  ></app-grid-filter>
</div>
